import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';

import { collaborationItemSchema } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { IForm } from 'components/forms/form.interface';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './collaboration-editor-form.module.less';

const collaborationCreateSchema = z
  .object({
    editor: collaborationItemSchema,
  })
  .strict();

export type CollaborationItemCreateDataType = z.infer<typeof collaborationCreateSchema>;

interface ICollaborationEditorFormProps extends IForm<CollaborationItemCreateDataType> {
  visible: boolean;
  postId: string;
  placeholder?: string;
  mediaType: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  isCollaborationImageLoading?: boolean;
  isCollaborationVideoLoading?: boolean;
}

export const CollaborationEditorForm = (props: ICollaborationEditorFormProps) => {
  const {
    visible,
    initialValues,
    placeholder = 'Add comment',
    mediaType,
    processing = false,
    collaborationMediaItem,
    onSubmit,
    isCollaborationImageLoading,
    isCollaborationVideoLoading,
  } = props;
  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const { control, formState, handleSubmit } = useForm<CollaborationItemCreateDataType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(collaborationCreateSchema),
  });

  const { errors, isValid } = formState;

  const collaborationEditorFormClasses = useMemo<string>(
    () =>
      cn(styles.CollaborationEditorForm, {
        [styles['CollaborationEditorForm--processing']]: processing,
        [styles['CollaborationEditorForm--keyboard']]: isKeyboardOpen,
      }),
    [processing, isKeyboardOpen],
  );

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  return (
    <>
      <Form className={collaborationEditorFormClasses} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="editor"
          control={control}
          render={({ field }) => (
            <Editor
              postId={props.postId}
              placeholder={placeholder}
              id="editor"
              name="editor"
              editorType={EditorType.Collaboration}
              isActive={visible}
              disabled={
                !isValid || processing || isCollaborationVideoLoading || isCollaborationImageLoading
              }
              value={field.value}
              error={errors.editor?.content?.length?.message}
              onChange={handleEditorChange(field.onChange)}
              mediaType={mediaType}
              collaborationMediaItem={collaborationMediaItem}
              convertImageItemToAttachment={props.convertImageItemToAttachment}
              convertVideoItemToAttachment={props.convertVideoItemToAttachment}
              setCollaborationMediaItem={props.setCollaborationMediaItem}
              isCollaborationImageLoading={isCollaborationImageLoading}
              isCollaborationVideoLoading={isCollaborationVideoLoading}
            />
          )}
        />
      </Form>
      <Loader isShow={processing} />
    </>
  );
};
