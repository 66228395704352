import { IUserAchievement } from 'query-hooks/reputation/interfaces/user-achievements.interface';

import { IUserAchievementResponse } from 'services/reputation-points/interfaces/user-achievements-response.interface';

import { bucketAdapter } from 'stores/buckets/adapters/bucket-adapter.util';

export const userAchievementAdapter = (
  userAchievement: IUserAchievementResponse,
): IUserAchievement => {
  const {
    achievement,
    achievement_level: achievementLevel,
    bucket,
    badge_src: badgeSrc,
    total_points: totalPoints,
    status,
    created_at: createdAt,
    updated_at: updatedAt,
    id,
  } = userAchievement;

  return {
    id,
    achievement,
    bucket: bucket ? bucketAdapter(bucket) : null,
    achievementLevel,
    badgeSrc,
    status,
    totalPoints,
    createdAt,
    updatedAt,
  };
};
