import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MY_MESSAGES_MENU_GROUPS } from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';

import { useResponsive } from 'hooks/use-responsive';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { ICommentData } from 'components/comment/comment.component';
import { IPostCardUserInfo } from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTheme,
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from './collaboration-comment.module.less';

interface IGameChatCommentProps {
  commentId: number;
  isAllowedCommentDelete: boolean;
  isAthlete: boolean;
  isLiked: boolean;
  author: IPostCardUserInfo;
  commentData: ICommentData;
  likes: number;
  onLikes: (commentId: number) => void;
  onDeleteComment: (commentId: number) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  favoriteTeamId: Maybe<number>;
  favoritePlayerId: Maybe<number>;
  getPlayerById: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  getTeamById: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
}

export const CollaborationComment = (props: IGameChatCommentProps) => {
  const {
    isLiked,
    isAthlete,
    isAllowedCommentDelete,
    commentId,
    author,
    commentData,
    likes,
    onLikes,
    onTeamClick,
    onDeleteComment,
    onPlayerClick,
    favoritePlayerId,
    favoriteTeamId,
    getPlayerById,
    getTeamById,
  } = props;

  const favoriteTeam = getTeamById(favoriteTeamId);
  const favoritePlayer = getPlayerById(favoritePlayerId);

  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(true);
  const [isLikeEnabled, setIsLikeEnabled] = useState(true);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const overridePlayer: Maybe<IPlayerStats> = useMemo(() => {
    if (favoritePlayer) {
      return {
        ...favoritePlayer,
        fontColor: PlayerFontColor.Light,
      };
    }

    return null;
  }, [favoritePlayer]);

  const handleTeamClick = useCallback(
    (teamId: number) => {
      if (onTeamClick) {
        onTeamClick(teamId);
      }
    },
    [onTeamClick],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      if (onPlayerClick) {
        onPlayerClick(slug);
      }
    },
    [onPlayerClick],
  );

  const handleContextMenuAnchorClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click) {
        if (barAction.payload === 'delete') {
          onDeleteComment(commentId);
        }
      }

      setIsNeedCloseTooltip(true);
    },
    [onDeleteComment, commentId],
  );

  const contextMenuItems = useMemo(() => {
    if (isAllowedCommentDelete) {
      return MY_MESSAGES_MENU_GROUPS;
    }

    return [];
  }, [isAllowedCommentDelete]);

  const handleLikes = useCallback(async () => {
    setIsLikeEnabled(false);
    await onLikes(commentId);
    setIsLikeEnabled(true);
  }, [onLikes, commentId]);

  const userNameClasses = useMemo(
    () =>
      cn(styles.TopSection__UserNick, {
        [styles['TopSection__UserNick--athlete']]: author.athlete,
      }),
    [author.athlete],
  );

  return (
    <div className={styles.Comment}>
      <div className={styles.Comment__UserAvatar}>
        <Avatar size={AvatarSize.M} username={author.username} src={author.smallAvatarUrl} />
      </div>
      <div className={styles.Comment__Main}>
        <div className={styles.Comment__Body}>
          <div className={styles.Comment__UserInfo}>
            <div className={styles.LeftSide}>
              <div className={styles.TopSection}>
                <div className={userNameClasses}>
                  {!author.athlete ? `@${author.username}` : author.athlete.fullName}
                </div>
                {!!author.name && !author.athlete && isDesktopPlus && (
                  <div className={styles.TopSection__UserName}>{author.name}</div>
                )}
                {isAllowedCommentDelete && (
                  <div className={styles.TopSection__More}>
                    <ContextMenuTooltip
                      groups={contextMenuItems}
                      onItemClick={handleContextMenuClick}
                      toggleOnClick
                      setIsNeedCloseTooltip={setIsNeedCloseTooltip}
                      tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
                      isNeedCloseTooltip={isNeedCloseTooltip}
                    >
                      <IconButton
                        iconName={IconFontName.More2}
                        theme={IconButtonTheme.Secondary}
                        iconSize={IconFontSize.Small}
                        onClick={handleContextMenuAnchorClick}
                      />
                    </ContextMenuTooltip>
                  </div>
                )}
              </div>
              {!isAthlete && (
                <TeamPlayerLabel
                  isNeedLowOpacity
                  team={favoriteTeam}
                  player={overridePlayer}
                  onTeamClick={handleTeamClick}
                  onPlayerClick={handlePlayerClick}
                />
              )}
            </div>
          </div>
          <div className={styles.Comment__Content}>
            {commentData.comment && (
              <RichTextPreview
                id={commentId.toString()}
                theme={RichTextContentTheme.LightGrey}
                editorState={commentData.comment}
                editorType={RichTextContentTypes.WithReadMore}
              />
            )}
          </div>
        </div>
        <div className={styles.Comment__Footer}>
          <AuthTooltip>
            <IconButton
              iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
              theme={IconButtonTheme.Transparent}
              iconSize={IconFontSize.Small}
              onClick={handleLikes}
              active={isLiked}
              disabled={!isLikeEnabled}
            />
          </AuthTooltip>
          {!!likes && (
            <span className={styles.PostCardActionsBar__Number}>{formatBigNumbers(likes)}</span>
          )}
          <div className={styles.Comment__Time}>{commentData.createAt}</div>
        </div>
      </div>
    </div>
  );
};
