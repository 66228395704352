import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './confirmation-modal.module.less';

interface IConfirmationModalProps extends IModal {
  onSuccessCallback: () => void;
  onRejectCallback?: () => void;
  title: string;
  content: string;
  secondaryButtonText: Maybe<string>;
  primaryButtonText: Maybe<string>;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = (props: IConfirmationModalProps) => {
  const {
    visible,
    title,
    content,
    secondaryButtonText,
    primaryButtonText,
    onClose,
    onRejectCallback,
  } = props;

  const footerClasses = useMemo(
    () =>
      cn(styles.ModalFooter, {
        [styles['ModalFooter--single']]: !secondaryButtonText || !primaryButtonText,
      }),
    [secondaryButtonText, primaryButtonText],
  );

  const handleConfirmationModalReject = useCallback(() => {
    onRejectCallback?.();
    onClose?.();
  }, [onRejectCallback, onClose]);

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title={title}
      onClose={props.onClose}
    >
      <div className={styles.ModalContent}>{content}</div>
      <div className={footerClasses}>
        {secondaryButtonText && (
          <Button
            onClick={handleConfirmationModalReject}
            size={ButtonSize.Small}
            theme={ButtonTheme.Secondary}
            fluid
          >
            {secondaryButtonText}
          </Button>
        )}
        {primaryButtonText && (
          <Button
            onClick={props.onSuccessCallback}
            size={ButtonSize.Small}
            theme={ButtonTheme.Primary}
            fluid
          >
            {primaryButtonText}
          </Button>
        )}
      </div>
    </BaseModalComponent>
  );
};
