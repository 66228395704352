import { IDaysStreak } from 'query-hooks/reputation/interfaces/days-streak.interface';

import { IDayStreakResponse } from 'services/reputation-points/interfaces/day-streak-response.interface';

export function dayStreakAdapter(dayStreak: IDayStreakResponse): IDaysStreak {
  const { id, max, current, created_at: createdAt, updated_at: updatedAt } = dayStreak;

  return {
    id,
    max,
    current,
    createdAt,
    updatedAt,
  };
}
