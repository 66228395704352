import { useCallback } from 'react';
import { useGetAllTeamsQuery } from 'query-hooks/teams/queries/use-get-all-teams.query';

import { findElementById } from 'helpers/find-element-by-id.util';

export const useGetTeamById = () => {
  const { data: teams = [] } = useGetAllTeamsQuery();

  return useCallback(
    (teamId: Maybe<number>) => {
      return findElementById(teams, teamId);
    },
    [teams],
  );
};
