import { IPlayerStatsMiniResponse } from 'services/player/interfaces/player-stats.interface';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { PlayerFontColor } from '../enums/player-font-color.enum';
import { IPlayerStatsMini } from '../interfaces/players-stats.interface';
import { TopPerformerByType } from '../types/top-performer-by-type.type';

export function teamsStatsRoster(
  playerStatsMiniResponse: IPlayerStatsMiniResponse,
  topPerformerBy: TopPerformerByType,
): IPlayerStatsMini {
  const {
    age,
    assists,
    blocks,
    color,
    firstname,
    font_theme: fontTheme,
    games_played: gamesPlayed,
    height,
    jersey,
    lastname,
    medium_logo_url: mediumLogoUrl,
    points,
    small_logo_url: smallLogoUrl,
    steals,
    tot_reb: totalRebounds,
    id,
    weight,
    position,
    slug,
    height_ft: heightFt,
    weight_lbs: weightLbs,
  } = playerStatsMiniResponse;

  return {
    fullName: `${firstname} ${lastname}`,
    slug,
    color: convertColorToRGB(color),
    colorWithOpacity: convertColorToRGBA(color, 0.3),
    firstname,
    blocks: roundToSingleDecimal(blocks),
    lastname,
    id,
    mediumLogoUrl,
    smallLogoUrl,
    fontColor: fontTheme === 'dark' ? PlayerFontColor.Dark : PlayerFontColor.Light,
    age,
    assists: roundToSingleDecimal(assists),
    gamesPlayed,
    jersey,
    points: roundToSingleDecimal(points),
    steals: roundToSingleDecimal(steals),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    heightMeters: height,
    weightKg: weight,
    heightFt,
    weightLbs,
    position,
    topPerformerBy,
  };
}
