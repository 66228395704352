import { memo, useMemo } from 'react';
import cn from 'classnames';
import {
  AchievementLevel,
  AchievementStatus,
  AchievementType,
} from 'query-hooks/reputation/interfaces/user-achievements.interface';

import { AchievementBadgeComponent } from 'components/achievements/achievement-badge/achievement-badge.component';
import { AchievementProgressBar } from 'components/achievements/achievement-progress-bar/achievement-progress-bar.component';

import styles from './achievement-card.module.less';

export interface IAchievementCardProps {
  type: AchievementType;
  level?: Maybe<AchievementLevel>;
  badgeUrl: Maybe<string>;
  title?: string;
  description: string;
  totalPoints: number;
  earnedPoints: number;
  status: AchievementStatus;
  label: string;
}

export const AchievementCard = memo((props: IAchievementCardProps) => {
  const { type, level, badgeUrl, title, description, earnedPoints, totalPoints, status, label } =
    props;

  const isCompleted = useMemo(() => {
    return status === AchievementStatus.Completed;
  }, [status]);

  const isMaxAchieved = useMemo(() => {
    if (!earnedPoints || !totalPoints) {
      return false;
    }

    return earnedPoints >= totalPoints;
  }, [earnedPoints, totalPoints]);

  const classNames = useMemo(() => {
    return cn(styles.AchievementCard, {
      [styles.AchievementCard__platinum]: level === AchievementLevel.Platinum,
      [styles.AchievementCard__gold]: level === AchievementLevel.Gold,
      [styles.AchievementCard__silver]: level === AchievementLevel.Silver,
      [styles.AchievementCard__bronze]: level === AchievementLevel.Bronze,
      [styles.AchievementCard__completed]: isCompleted || isMaxAchieved,
    });
  }, [level, isCompleted, isMaxAchieved]);

  const progress = useMemo(() => {
    if (!earnedPoints || !totalPoints) {
      return null;
    }

    return Math.floor((earnedPoints / totalPoints) * 100);
  }, [earnedPoints, totalPoints]);

  const achievementTitle = useMemo(() => {
    if (title) {
      return title;
    }

    if (!level) {
      return '';
    }

    const levelMapper: Record<number, string> = {
      [AchievementLevel.Bronze]: 'Bronze',
      [AchievementLevel.Silver]: 'Silver',
      [AchievementLevel.Gold]: 'Gold',
      [AchievementLevel.Platinum]: 'Platinum',
    };

    return `${levelMapper[level]} ${
      isMaxAchieved ? ' (max)' : !isCompleted ? ' (in progress)' : ''
    }`;
  }, [title, level, isCompleted, isMaxAchieved]);

  if (!badgeUrl) {
    return null;
  }

  return (
    <div className={classNames}>
      <div className={styles.AchievementBackground} />
      <div className={styles.AchievementCard__BadgeWrapper}>
        <AchievementBadgeComponent
          type={type}
          badgeUrl={badgeUrl}
          level={level}
          status={status}
          label={label}
          showBadgeCheckmark
        />
      </div>
      <div className={styles.AchievementContent}>
        <div className={styles.AchievementContent__Info}>
          <p className={styles.AchievementContent__Title}>{achievementTitle}</p>
          {description && <p className={styles.AchievementContent__Description}>{description}</p>}
          {totalPoints && earnedPoints && (
            <span className={styles.AchievementContent__Points}>
              {isMaxAchieved ? totalPoints : earnedPoints} / {totalPoints}
            </span>
          )}
        </div>
        <div className={styles.AchievementContent__Progress}>
          <AchievementProgressBar
            theme={level}
            fill={progress}
            isCompleted={isCompleted || isMaxAchieved}
          />
        </div>
      </div>
    </div>
  );
});
