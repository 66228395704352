import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Browser } from '@capacitor/browser';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { SettingsScreenType } from 'stores/settings/enums/settings-screen-type.enum';
import { SettingsStore } from 'stores/settings/settings.store';

import { TYPES } from 'configs/di-types.config';
import { FEEDBACK_REFERENCE } from 'configs/references.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';
import { HOME_FEED } from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { AccountSettings } from 'components/settings/account-settings/account-settings.component';
import { MainSettings } from 'components/settings/main-settings/main-settings.component';
import { PolicySettings } from 'components/settings/policy-settings/policy-settings.component';
import { PushNotificationsSettings } from 'components/settings/push-notifications-settings/push-notifications-settings.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { PolicyNavigationTrigger } from '../privacy-policy/enums/policy-navigation-trigger.enum';

import styles from './settings.module.less';

export const SettingsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const settingsStore = useInjection<SettingsStore>(TYPES.SettingsStore);

  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenAccountDeleteResultModal, setIsOpenAccountDeleteResultModal] = useState(false);

  const navigation = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const handleBackClick = useCallback(() => {
    if (settingsStore.screenType === SettingsScreenType.Settings) {
      navigation(paths.HOME_FEED);
    }

    if (
      settingsStore.screenType === SettingsScreenType.PushNotifications ||
      settingsStore.screenType === SettingsScreenType.Privacy ||
      settingsStore.screenType === SettingsScreenType.Account
    ) {
      settingsStore.setScreenType(SettingsScreenType.Settings);
    }
  }, [settingsStore, navigation]);

  const handleMainSettingsClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Link) {
        navigation(barAction.path);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'account') {
        settingsStore.setScreenType(SettingsScreenType.Account);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'feedback') {
        await Browser.open({ url: FEEDBACK_REFERENCE });
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'notifications') {
        settingsStore.setScreenType(SettingsScreenType.PushNotifications);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'privacy') {
        settingsStore.setScreenType(SettingsScreenType.Privacy);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'logout') {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.AccountProfileSettingsLogout,
          },
        });

        await authStore.logout();
        navigation(paths.HOME_FEED);
      }
    },
    [authStore, navigation, settingsStore],
  );

  const handlePolicyClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'privacy') {
        navigation(paths.PRIVACY_POLICY, {
          state: {
            trigger: PolicyNavigationTrigger.Settings,
          },
        });
      }
      if (barAction.type === BarAction.Click && barAction.payload === 'terms') {
        navigation(paths.TERMS_OF_USE, {
          state: {
            trigger: PolicyNavigationTrigger.Settings,
          },
        });
      }
      if (barAction.type === BarAction.Click && barAction.payload === 'agreement') {
        navigation(paths.USER_AGREEMENT, {
          state: {
            trigger: PolicyNavigationTrigger.Settings,
          },
        });
      }
    },
    [navigation],
  );

  const handleAccountClick = useCallback((barAction: BarActionType) => {
    if (barAction.type === BarAction.Click && barAction.payload === 'account') {
      setIsOpenConfirmation(true);
    }
  }, []);

  useEffect(() => {
    applicationStore.setAllowedPushNotification(applicationStore.hasPushNotificationPermissions);
  }, [applicationStore]);

  const handleAccessNotificationClick = useCallback(async () => {
    if (!applicationStore.hasPushNotificationPermissions) {
      await applicationStore.requestPushNotificationPermission();
    }
  }, [applicationStore]);

  const handleSettingItemChange = useCallback(
    (value: boolean, key?: string) => {
      if (key) {
        settingsStore.sendPushNotificationSettings(key, value);
      }
    },
    [settingsStore],
  );

  const handleSocialIconClick = useCallback(
    (url: string) => {
      if (isNativeApp) {
        Browser.open({ url });

        return;
      }

      window.open(url, '_blank');
    },
    [isNativeApp],
  );

  const settingsTitle = useMemo(() => {
    if (settingsStore.screenType === SettingsScreenType.Account) {
      return 'Account';
    }
    if (settingsStore.screenType === SettingsScreenType.Settings) {
      return 'Settings';
    }
    if (settingsStore.screenType === SettingsScreenType.PushNotifications) {
      return 'Notifications';
    }
    if (settingsStore.screenType === SettingsScreenType.Privacy) {
      return 'Privacy & Safety';
    }

    return '';
  }, [settingsStore.screenType]);

  const handleConfirmDeleteAccount = useCallback(async () => {
    const isSuccess = await authStore.deleteAccount();
    setIsOpenConfirmation(false);

    if (isSuccess) {
      await authStore.logout();
      settingsStore.setScreenType(SettingsScreenType.Settings);
      setIsOpenAccountDeleteResultModal(true);
    }
  }, [authStore, settingsStore]);

  const handleFinishAccountDeletion = useCallback(() => {
    setIsOpenAccountDeleteResultModal(false);
    navigation(HOME_FEED);
  }, [navigation]);

  const handleCloseConfirmation = useCallback(() => {
    setIsOpenConfirmation(false);
  }, []);

  useEffect(() => {
    if (isDesktopPlus) {
      const screenType = isNativeApp
        ? SettingsScreenType.PushNotifications
        : SettingsScreenType.Privacy;
      settingsStore.setScreenType(screenType);
    }
  }, [isDesktopPlus, isNativeApp, settingsStore]);

  return (
    <div className={styles.Settings}>
      <div className={styles.SettingsContainer}>
        <div className={styles.SettingsHeader}>
          {!isDesktopPlus && (
            <IconButton
              theme={IconButtonTheme.Transparent}
              iconName={IconFontName.ChevronLeft}
              onClick={handleBackClick}
            />
          )}
          <h2 className={styles.SettingsHeader__Title}>{settingsTitle}</h2>
        </div>
        <div className={styles.SettingsContent}>
          {(settingsStore.screenType === SettingsScreenType.Settings || isDesktopPlus) && (
            <MainSettings
              currentScreenType={settingsStore.screenType}
              onItemClick={handleMainSettingsClick}
              onSocialIconClick={handleSocialIconClick}
              isNativeApp={isNativeApp}
              isAuthorisedAnonymously={authStore.isAuthorisedAnonymously}
            />
          )}
          {settingsStore.screenType === SettingsScreenType.Account && (
            <AccountSettings onItemClick={handleAccountClick} />
          )}
          {settingsStore.screenType === SettingsScreenType.Privacy && (
            <PolicySettings onItemClick={handlePolicyClick} />
          )}
          {settingsStore.screenType === SettingsScreenType.PushNotifications && (
            <PushNotificationsSettings
              isAllowedPushNotification={applicationStore.isAllowedPushNotification}
              settings={settingsStore.pushNotificationSettings}
              onAccessNotificationClick={handleAccessNotificationClick}
              onSettingItemChange={handleSettingItemChange}
            />
          )}
        </div>
        {(settingsStore.screenType === SettingsScreenType.Settings || isDesktopPlus) && (
          <div className={styles.SettingsFooter}>
            <span className={styles.SettingsFooter__Text}>Digits Version: {VERSION}</span>
          </div>
        )}
      </div>
      <ConfirmationModal
        content="This will permanently delete all the associated data"
        title="Are you sure you want to delete your account?"
        visible={isOpenConfirmation}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onSuccessCallback={handleConfirmDeleteAccount}
        onClose={handleCloseConfirmation}
      />
      <ConfirmationModal
        content="The deletion process for your account has begun and will be completed within 60 business days"
        title="We're sorry to see you go"
        visible={isOpenAccountDeleteResultModal}
        primaryButtonText="Ok"
        onSuccessCallback={handleFinishAccountDeletion}
        onClose={handleFinishAccountDeletion}
        secondaryButtonText={null}
      />
    </div>
  );
});
