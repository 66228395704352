import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { IDaysStreak } from 'query-hooks/reputation/interfaces/days-streak.interface';
import { IUserAchievement } from 'query-hooks/reputation/interfaces/user-achievements.interface';
import { getAchievementFrame } from 'query-hooks/reputation/utils/get-achievement-frame.util';

import {
  IReputationPopupData,
  ReputationPointsCategories,
} from 'stores/reputations-points/interfaces/reputation-points.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { AchievementBadgeComponent } from 'components/achievements/achievement-badge/achievement-badge.component';
import { DaysStreakAchievementBadge } from 'components/achievements/days-streak-achievement-badge/days-streak-achievement-badge.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';
import { ReputationBoxItem } from 'components/user-details/reputation-box-item/reputation-box-item.component';
import { prepareReputationPointsItems } from 'components/user-details/utils/prepare-reputation-points-items';

import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './user-details.module.less';

interface IUserDetailsProps {
  username: string;
  activeBucket: string;
  realName?: Maybe<string>;
  team: Maybe<ITeamsStats>;
  player: Maybe<IPlayerStats>;
  avatarSrc: Maybe<string>;
  thumbnailURL: Maybe<string>;
  reputationPoints: Maybe<IReputationPoints>;
  topAchievement: Maybe<IUserAchievement>;
  userAchievements: IUserAchievement[];
  openReputationPopUp?: (data: Maybe<IReputationPopupData>) => void;
  onClose: () => void;
  daysStreak: Maybe<IDaysStreak>;
  currentPlayerFeedAvatarSrc?: string;
}

export const defaultReputationPoints: IReputationPoints = {
  totalPoints: 0,
  totalPointsRank: 0,
  playerPoints: 0,
  playerPointsRank: 0,
  participationPoints: 0,
  participationPointsRank: 0,
  predictionPoints: 0,
  predictionPointsRank: 0,
};

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

export const UserDetails: FC<IUserDetailsProps> = memo((props: IUserDetailsProps) => {
  const {
    daysStreak,
    username,
    activeBucket,
    realName,
    team,
    player,
    thumbnailURL,
    avatarSrc,
    reputationPoints,
    topAchievement,
    userAchievements,
    currentPlayerFeedAvatarSrc,
    onClose,
    openReputationPopUp,
  } = props;

  const navigate = useNavigate();

  const handleMockCallback = useCallback(() => {}, []);

  const handleLeaderboardClick = useCallback(() => {
    onClose();
    navigate({
      pathname: path.LEADERBOARD,
      search: `${createSearchParams({
        bucketId: activeBucket,
      })}`,
    });
  }, [onClose, navigate, activeBucket]);

  const handleSeeAllAchievementsClick = useCallback(() => {
    onClose();
    navigate(
      getPath(path.USER_PUBLIC_ACHIEVEMENTS, {
        [path.USERNAME_PARAM]: username,
      }),
    );
  }, [onClose, navigate, username]);

  const reputationPointsBoxItems = useMemo<IReputationBoxItem[]>(() => {
    if (!reputationPoints) {
      return prepareReputationPointsItems(defaultReputationPoints);
    }

    return prepareReputationPointsItems(reputationPoints);
  }, [reputationPoints]);

  const handleOpenUserProfile = useCallback(() => {
    navigate(
      getPath(path.USER_PUBLIC_PROFILE, {
        [path.USERNAME_PARAM]: username,
      }),
    );
    onClose();
  }, [username, navigate, onClose]);

  const handlePointSectionClick = useCallback(
    (type: ReputationPointsCategories) => {
      openReputationPopUp?.({
        type,
        subTypeId: null,
        username,
      });
    },
    [openReputationPopUp, username],
  );

  const achievementFrame = useMemo(() => {
    if (!topAchievement?.achievementLevel?.level) {
      return '';
    }

    return getAchievementFrame(topAchievement.achievementLevel.level, topAchievement.status);
  }, [topAchievement]);

  return (
    <div className={styles.AuthorCard}>
      <div className={styles.AuthorCard__Header}>
        <div className={styles.AuthorCard__HeaderThumbnail}>
          <img
            src={thumbnailURL || DEFAULT_THUMBNAIL_URL}
            alt="thumbnail background"
            className={styles.AuthorCard__Background}
          />
        </div>
        <div className={styles.AuthorCard__Avatar}>
          {achievementFrame && (
            <>
              <img
                className={styles.AuthorCard__Avatar__Frame}
                src={achievementFrame}
                alt="avatar frame"
              />
              {currentPlayerFeedAvatarSrc && (
                <div className={styles.AuthorCard__Avatar__Badge}>
                  <img src={currentPlayerFeedAvatarSrc} alt="achievement badge" />
                </div>
              )}
            </>
          )}
          <Avatar username={username} size={AvatarSize.MEGA} src={avatarSrc} />
        </div>
      </div>
      <div className={styles.AuthorCard__Details}>
        <div className={styles.AuthorCard__Names}>
          <h4>{realName}</h4>
          <div role="presentation" onClick={handleOpenUserProfile}>
            @{username}
          </div>
        </div>
        <TeamPlayerLabel
          team={team}
          player={player}
          onTeamClick={handleMockCallback}
          onPlayerClick={handleMockCallback}
        />
      </div>
      <div className={styles.ReputationPoints}>
        <div className={styles.ReputationPoints__ReputationHeader}>
          <div className={styles.ReputationPoints__Title}>Reputation points</div>
          <button className={styles.ReputationPoints__Action} onClick={handleLeaderboardClick}>
            Leaderboard
          </button>
        </div>
        <div className={styles.ReputationPoints__Table}>
          {reputationPointsBoxItems.map((item) => (
            <ReputationBoxItem
              type={item.type}
              key={item.title}
              title={item.title}
              content={item.content}
              rank={item.rank}
              onClick={() => handlePointSectionClick(item.type)}
            />
          ))}
        </div>
        <div className={styles.ReputationPoints__ReputationHeader}>
          <div className={styles.ReputationPoints__Title}>Achievements</div>
          <button
            className={styles.ReputationPoints__Action}
            onClick={handleSeeAllAchievementsClick}
          >
            See All
          </button>
        </div>
        <div className={styles.ReputationPoints__Achievements}>
          {daysStreak && (
            <div className={styles.ReputationPoints__Achievements__Streak}>
              <DaysStreakAchievementBadge value={daysStreak.current} />
            </div>
          )}
          {topAchievement?.badgeSrc && (
            <AchievementBadgeComponent
              type={topAchievement.achievement.type}
              badgeUrl={topAchievement.badgeSrc}
              level={topAchievement?.achievementLevel?.level}
              label={topAchievement.achievement.title}
              status={topAchievement.status}
            />
          )}
          {userAchievements.map((achievement) => (
            <AchievementBadgeComponent
              key={achievement.id}
              type={achievement.achievement.type}
              badgeUrl={achievement.badgeSrc}
              level={achievement?.achievementLevel?.level}
              label={achievement.achievement.title}
              status={achievement.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
