import { useCallback } from 'react';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';

import { findElementById } from 'helpers/find-element-by-id.util';

export const useGetPlayerById = () => {
  const { data: players = [] } = useGetAllPlayersQuery();

  return useCallback(
    (playerId: Maybe<number>) => {
      return findElementById(players, playerId);
    },
    [players],
  );
};
