import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';
import { HOME_FEED } from 'routes/paths.constants';
import * as paths from 'routes/paths.constants';

interface IPrivateRouteProps {
  children: JSX.Element;
}

export const PersonalPlayerRoute = observer((props: PropsWithChildren<IPrivateRouteProps>) => {
  const { children } = props;
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const params = useParams<{
    [paths.PLAYER_SLUG_PARAM]: string;
  }>();

  const { playerSlug } = params;

  if (playerSlug && authStore.userMe && playerSlug !== authStore.userMe.athlete?.playerSlug) {
    return <Navigate to={HOME_FEED} />;
  }

  return children;
});
