import { IPagination } from 'interfaces/pagination.interface';

export const ACHIEVEMENTS_AMOUNT_BEFORE_PRE_LOAD = 10;

export const DEFAULT_USER_ACHIEVEMENTS_PAGINATION: IPagination = {
  limit: 10,
  page: 1,
};

export const SHORT_ACHIEVEMENTS_LIST_LIMIT = 2;
