import { memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { LinkAction } from 'stores/auth/interfaces/user-public-link.interface';

import { EditProfileFormDataType } from 'components/edit-profile/edit-profile-form/edit-profile-form.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './social-links.module.less';

type SocialLinkKey = 'instagram' | 'twitter' | 'youtube' | 'tiktok' | 'snapchat' | 'discord';

export const SocialLinks = memo(() => {
  const [isClearAllButtonVisible, setIsClearAllButtonVisible] = useState(false);
  const { control, formState, watch, setValue, getValues } =
    useFormContext<EditProfileFormDataType>();

  const { errors } = formState;

  useEffect(() => {
    const values = watch('socialLinks');
    const isVisible = Object.values(values).some((link) => link.url);

    setIsClearAllButtonVisible(isVisible);
  }, [watch]);

  const handleClearAllClick = useCallback(() => {
    const values = getValues('socialLinks');

    Object.keys(values).forEach((key: string) => {
      const type = key as SocialLinkKey;

      if (values[type].url) {
        setValue(
          `socialLinks.${type}`,
          {
            url: '',
            action:
              values[type].action === LinkAction.Create ? LinkAction.Create : LinkAction.Delete,
            ...(values[type].id ? { id: values[type].id } : {}),
          },
          { shouldDirty: true },
        );
      }
    });
  }, [setValue, getValues]);

  const handleChange = useCallback(
    (
      value: string,
      key: SocialLinkKey,
      initialValue?: { url: string; action?: LinkAction; id?: number },
    ) => {
      let action;

      if (initialValue?.action === LinkAction.Create) {
        action = LinkAction.Create;
      } else {
        action = value === '' ? LinkAction.Delete : LinkAction.Edit;
      }

      setValue(
        `socialLinks.${key}`,
        {
          url: value,
          action,
          ...(initialValue?.id ? { id: initialValue?.id } : {}),
        },
        { shouldDirty: true, shouldValidate: true },
      );
    },
    [setValue],
  );

  return (
    <div className={styles.SocialLinks}>
      <div className={styles.SocialLinks__SubTitle}>
        <p>Edit Social Links</p>
        {isClearAllButtonVisible && (
          <Button
            theme={ButtonTheme.TextAlert}
            size={ButtonSize.Small}
            onClick={handleClearAllClick}
          >
            Clear All
          </Button>
        )}
      </div>
      <div className={styles.SocialLinks__Content}>
        <Controller
          name="socialLinks.instagram"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.instagram?.url?.message}
              type="text"
              id="socialLinks.instagram"
              placeholder="Instagram Link"
              leftIconName={IconFontName.Insta}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'instagram', field.value)}
            />
          )}
        />
        <Controller
          name="socialLinks.twitter"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.twitter?.url?.message}
              type="text"
              id="socialLinks.twitter"
              placeholder="X Link"
              leftIconName={IconFontName.Twitter}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'twitter', field.value)}
            />
          )}
        />
        <Controller
          name="socialLinks.youtube"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.youtube?.url?.message}
              type="text"
              id="socialLinks.youtube"
              placeholder="Youtube Link"
              leftIconName={IconFontName.Youtube}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'youtube', field.value)}
            />
          )}
        />
        <Controller
          name="socialLinks.tiktok"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.tiktok?.url?.message}
              type="text"
              id="socialLinks.tiktok"
              placeholder="Tiktok Link"
              leftIconName={IconFontName.TikTok}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'tiktok', field.value)}
            />
          )}
        />
        <Controller
          name="socialLinks.snapchat"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.snapchat?.url?.message}
              type="text"
              id="socialLinks.snapchat"
              placeholder="Snapchat Link"
              leftIconName={IconFontName.Snapchat}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'snapchat', field.value)}
            />
          )}
        />
        <Controller
          name="socialLinks.discord"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.socialLinks?.discord?.url?.message}
              type="text"
              id="socialLinks.discord"
              placeholder="Discord Link"
              leftIconName={IconFontName.Discord}
              value={field.value.url}
              onChange={(value) => handleChange(value, 'discord', field.value)}
            />
          )}
        />
      </div>
    </div>
  );
});
