import { z } from 'zod';

import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from 'validation/validation.constants';

export const realNameSchema = z
  .union([
    z
      .string()
      .trim()
      .max(MAX_NAME_LENGTH, `Max name length is ${MAX_NAME_LENGTH}`)
      .min(MIN_NAME_LENGTH, `Min name length is ${MIN_NAME_LENGTH}`),
    z.string().length(0, `Min name length is ${MIN_NAME_LENGTH}`),
  ])
  .optional();

const userNameRegex = /^\w+\.?\w+(?<!_)$/;

export const userNameSchema = z
  .string()
  .regex(userNameRegex, {
    message: 'Invalid username format. Only letters, numbers, and underscore are allowed.',
  })
  .min(MIN_NAME_LENGTH, `Min name length is ${MIN_NAME_LENGTH}`)
  .max(MAX_NAME_LENGTH, `Max name length is ${MAX_NAME_LENGTH}`);
