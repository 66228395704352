import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useDaysStreakQuery } from 'query-hooks/reputation/queries/use-days-streak.query';
import { useUserAchievementsInfinitiveQuery } from 'query-hooks/reputation/queries/use-user-achievements.query';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { Select } from 'components/ui/form-fields/select/select.component';
import { UserAchievements } from 'components/user-achievements/user-achievements.component';

import styles from './user-achievements-container.module.less';

export const UserPublicAchievementsContainer = observer(() => {
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const achievementsStore = useInjection<AchievementsStore>(TYPES.AchievementsStore);

  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;

  const { data: daysStreak = null } = useDaysStreakQuery(username, achievementsStore.seasonId);
  const {
    data: userAchievementsData,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useUserAchievementsInfinitiveQuery(username, achievementsStore.seasonId);

  const handleLoadMore = useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage && userAchievementsData) {
      fetchNextPage();
    }
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, userAchievementsData]);

  return (
    <div className={styles.UserPublicAchievements}>
      <div className={styles.UserPublicAchievements__Filter}>
        <Select
          id="achievement_season"
          name="AchievementSeason"
          options={seasonsStore.reputationSeasonsSelectOptions}
          value={achievementsStore.seasonId ?? ''}
          onChange={achievementsStore.setSeasonId}
        />
      </div>
      <UserAchievements
        daysStreak={daysStreak}
        userAchievements={userAchievementsData?.achievements ?? []}
        hasMore={hasNextPage}
        onLoadMore={handleLoadMore}
      />
    </div>
  );
});
