export const reputationQueryKeys = {
  prefix: ['reputation'] as const,
  userBalance: (username?: string) =>
    [...reputationQueryKeys.prefix, 'user-balance', { username }] as const,
  daysStreak: (username: Maybe<string>, seasonId: Maybe<string>) => {
    return [...reputationQueryKeys.prefix, 'days-streak', { username, seasonId }] as const;
  },
  userAchievements: (
    username: Maybe<string>,
    seasonId: Maybe<string>,
    limit: number,
    exclude: number[] = [],
  ) =>
    [
      ...reputationQueryKeys.prefix,
      'user-achievements',
      { username, seasonId, limit, exclude },
    ] as const,
  userTopSeasonAchievement: (
    username: Maybe<string>,
    playerId: Maybe<number>,
    seasonId: Maybe<string>,
  ) =>
    [
      ...reputationQueryKeys.prefix,
      'user-top-season-achievement',
      { username, playerId, seasonId },
    ] as const,
  playerBucket: (playerId: Maybe<number>) =>
    [...reputationQueryKeys.prefix, 'player-bucket', { playerId }] as const,
};
