import { useCallback } from 'react';
import { useGetFollowingPlayers } from 'query-hooks/followings/use-get-following-players';

export const useGetIsFollowPlayer = () => {
  const followingPlayers = useGetFollowingPlayers();

  return useCallback(
    (id: number) => {
      if (!id) return false;

      const playerId = `${id}player`;
      const player = followingPlayers.find((followItem) => followItem.id === playerId);

      return !!player?.isFollow;
    },
    [followingPlayers],
  );
};
