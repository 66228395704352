import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cn from 'classnames';
import { $getRoot, EditorState, TextNode } from 'lexical';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { arrayFilterUniqueByKey } from 'helpers/array-filter-unique-by-key.util';

import { useMainProvider } from 'hooks/use-main-provider';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { useResponsive } from 'hooks/use-responsive';

import { ContentEditableWithScroll } from 'components/editor/components/content-editable-with-scroll/content-editable-with-scroll.component';
import { isAttachmentsExist } from 'components/editor/components/helpers/is-attachments-exist';
import { Placeholder } from 'components/editor/components/placeholder/placeholder.component';
import AutoLinkPlugin from 'components/editor/components/plugins/AutoLinkPlugin';
import { BottomToolbarPlugin } from 'components/editor/plugins/toolbar/bottom-toolbar-plugin';
import { SingleMediaToolbarPlugin } from 'components/editor/plugins/toolbar/single-media-toolbar-plugin';
import { SubmitToolbarPlugin } from 'components/editor/plugins/toolbar/submit-toolbar-plugin';
import { TextStylingToolbarPlugin } from 'components/editor/plugins/toolbar/text-styling-toolbar-plugin';
import { VideoPostToolbarPlugin } from 'components/editor/plugins/toolbar/video-post-toolbar-plugin';
import { TreeViewPlugin } from 'components/editor/plugins/treeview/tree-view-plugin';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import {
  AttachmentsImageItem,
  AttachmentsImageTheme,
} from 'components/ui/attachments-image-item/attachment-image-item.component';
import { IPollCreateData } from 'components/ui/form-fields/poll-form/interfaces/poll.interface';
import { InputImageDataType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { isIUploadImageDataArray } from 'components/ui/form-fields/post-image-input/utils';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader, LoaderSizeEnum, LoaderTheme } from 'components/ui/loader/loader.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './base-editor.module.less';

const AUTOFOCUS_DELAY = 100; // ms

export enum EditorTheme {
  Grey,
  Light,
}

export enum EditorType {
  SimplePost,
  GroupPost,
  CommentMobile,
  CommentDesktop,
  Collaboration,
  VideoPost,
  Biography,
}

type EditorContentType = {
  value: string;
  length?: number;
};

export type EditorAttachmentsType = {
  images?: IUploadImageData[];
  gifs?: string[];
  videos?: string[];
  poll?: IPollCreateData;
};

export type EditorDataType = {
  content?: EditorContentType;
  attachments?: Maybe<EditorAttachmentsType>;
};

export type EditorLoadDataType = {
  clear: () => void;
};

interface IBaseEditorProps {
  theme: EditorTheme;
  disableAttachments: boolean;
  postId?: string;
  placeholder?: string;
  isHighlighted?: boolean;
  isActive?: boolean;
  isTreeViewEnabled?: boolean;
  disabled?: boolean;
  isVideoLoading: boolean;
  isSendHidden: boolean;
  loadingImages?: string[];
  editorData?: EditorDataType;
  postVideo: Maybe<IVideoResponse>;
  onLoad?: (editor: EditorLoadDataType) => void;
  setLoadingImages?: (value: string[]) => void;
  onEditorFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: EditorDataType) => void;
  onUploadVideo?: (video: File) => void;
  onRemoveVideo?: () => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  editorType: EditorType;
  mediaType?: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
  error?: string;
}

export const BaseEditor = memo((props: IBaseEditorProps) => {
  const {
    theme,
    editorType,
    disableAttachments,
    placeholder = 'Type message',
    isHighlighted = false,
    isActive = false,
    isSendHidden,
    isTreeViewEnabled = false,
    disabled = false,
    editorData,
    postVideo,
    loadingImages,
    isVideoLoading,
    onBlur,
    onLoad,
    onChange,
    onRemoveVideo,
    mediaType,
    collaborationMediaItem,
    error,
  } = props;

  const { uuid: postVideoId } = postVideo || {};

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isGifPickerOpen, setIsGifPickerOpen] = useState(false);

  const editorRef = useRef<HTMLDivElement>(null);

  const [editor] = useLexicalComposerContext();

  const { isNativeAndroidApp } = useMainProvider();

  const handleEditorBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  useOnClickOutside(editorRef, handleEditorBlur);

  const [isExpandStylingToolbar, setIsExpandStylingToolbar] = useState(false);

  editor.update(() => {
    if (isNativeAndroidApp && isActive) {
      const root = $getRoot();
      const nodes = root.getAllTextNodes();

      nodes.forEach((node) => {
        if (node instanceof TextNode) {
          const textContent = node.getTextContent() as unknown;
          const decoder = new TextDecoder('utf-8');

          node.setTextContent(decoder.decode(textContent as Uint8Array));
          root.selectEnd();
        }
      });
    }
  });

  const getFilteredAttachments = (
    attachmentsCollection: EditorAttachmentsType,
    keyToRemove: keyof EditorAttachmentsType,
  ): Maybe<EditorAttachmentsType> => {
    const attachmentFilteredKeys = Object.keys(attachmentsCollection).filter(
      (key) => key !== keyToRemove,
    );

    if (!attachmentFilteredKeys.length) {
      return null;
    }

    const reducerCallback = (
      acc: Partial<EditorAttachmentsType>,
      key: string,
    ): NonNullable<EditorAttachmentsType> => {
      if (Array.isArray(attachmentsCollection[key as keyof EditorAttachmentsType])) {
        return {
          ...acc,
          [key]: attachmentsCollection[key as keyof EditorAttachmentsType],
        };
      }

      return {
        ...acc,
        [key]: { ...attachmentsCollection[key as keyof EditorAttachmentsType] },
      };
    };

    return attachmentFilteredKeys.reduce(reducerCallback, {});
  };

  const handleContentChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        const contentLength = root.getTextContent().trim().length;

        onChange?.({
          ...editorData,
          content: {
            value: JSON.stringify(editorState),
            length: contentLength,
          },
        });
      });
    },
    [editorData, onChange],
  );

  const handleAddImageToAttachments = useCallback(
    (fileData: InputImageDataType) => {
      const images = editorData?.attachments?.images || [];

      if (onChange && isIUploadImageDataArray(fileData)) {
        const uniqueImages = arrayFilterUniqueByKey([...images, ...fileData], 'hash');

        onChange({
          ...editorData,
          attachments: {
            ...editorData?.attachments,
            images: uniqueImages,
          },
        });
      }
    },
    [editorData, onChange],
  );

  const handleGifSelect = useCallback(
    (id: string) => {
      const existedGifs = editorData?.attachments?.gifs || [];
      const isGifUniq = !existedGifs.includes(id);

      setIsGifPickerOpen(false);

      if (isGifUniq && onChange) {
        onChange({
          ...editorData,
          attachments: {
            ...editorData?.attachments,
            gifs: [...existedGifs, id],
          },
        });
      }
    },
    [editorData, onChange, setIsGifPickerOpen],
  );

  const handleImageDelete = useCallback(
    (imgUuid: string) => () => {
      if (editorData?.attachments?.images && onChange && !loadingImages?.length) {
        const deleteResult = editorData.attachments.images.filter((img) => img.uuid !== imgUuid);

        if (!deleteResult.length) {
          const filteredAttachments: Maybe<EditorAttachmentsType> = getFilteredAttachments(
            editorData.attachments,
            'images',
          );

          onChange({ ...editorData, attachments: filteredAttachments });
        } else {
          onChange({
            ...editorData,
            attachments: {
              ...editorData.attachments,
              images: deleteResult,
            },
          });
        }
      }
    },
    [editorData, onChange, loadingImages],
  );

  const handleVideoDelete = useCallback(() => {
    if (!onChange || !editorData?.attachments?.videos || !onRemoveVideo) {
      return;
    }

    const filteredAttachments: Maybe<EditorAttachmentsType> = {
      ...editorData.attachments,
    };

    delete filteredAttachments.videos;

    onRemoveVideo();
    onChange({ ...editorData, attachments: filteredAttachments });
  }, [editorData, onChange, onRemoveVideo]);

  const handleGifDelete = useCallback(
    (gif: string) => () => {
      if (editorData?.attachments?.gifs && onChange) {
        const deleteResult = editorData.attachments.gifs.filter((item) => item !== gif);

        if (!deleteResult.length) {
          const filteredAttachments: Maybe<EditorAttachmentsType> = getFilteredAttachments(
            editorData.attachments,
            'gifs',
          );

          onChange({ ...editorData, attachments: filteredAttachments });
        } else {
          onChange({
            ...editorData,
            attachments: {
              ...editorData.attachments,
              gifs: deleteResult,
            },
          });
        }
      }
    },
    [editorData, onChange],
  );

  const clearEditor = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();

      root.clear();
    });

    // Use setTimeout because commentEditor.update set focus on form that
    // can be removed in next render
    setTimeout(() => {
      editor.blur();
      editorRef.current?.blur();
    });
  }, [editor]);

  const editorClasses = useMemo(
    () =>
      cn(styles.BaseEditor, {
        [styles['BaseEditor--active']]:
          isHighlighted || (isActive && editorType === EditorType.CommentDesktop),
        [styles['BaseEditor--grey-theme']]: theme === EditorTheme.Grey,
        [styles['BaseEditor--light-theme']]: theme === EditorTheme.Light,
        [styles['BaseEditor--bottom-sheet-theme']]: !isDesktopPlus,
      }),
    [theme, isDesktopPlus, isHighlighted, editorType, isActive],
  );

  useEffect(() => {
    onLoad?.({
      clear: clearEditor,
    });
  }, [clearEditor, onLoad]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isActive) {
      timeout = setTimeout(() => {
        editor?.focus(() => {}, { defaultSelection: 'rootEnd' });
      }, AUTOFOCUS_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [editor, isActive, isNativeAndroidApp]);

  useEffect(() => {
    if (
      postVideoId &&
      (!editorData?.attachments?.videos || postVideoId !== editorData?.attachments?.videos?.[0])
    ) {
      const changedObject = {
        ...editorData,
        attachments: {
          ...editorData?.attachments,
          videos: [postVideoId],
        },
      };

      onChange?.(changedObject);
    }
  }, [postVideoId, editorData, onChange]);

  return (
    <>
      <div ref={editorRef} className={editorClasses}>
        <div onFocus={props.onEditorFocus}>
          <RichTextPlugin
            contentEditable={
              <ContentEditableWithScroll
                isActive={isActive}
                editorType={editorType}
                error={error}
              />
            }
            placeholder={<Placeholder text={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
        {error && <div className={styles.BaseEditor__Error}>{error}</div>}
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <OnChangePlugin onChange={handleContentChange} ignoreSelectionChange />
        <div className={styles.ToolbarRow}>
          {(editorType === EditorType.SimplePost ||
            editorType === EditorType.GroupPost ||
            editorType === EditorType.Biography) &&
            !isExpandStylingToolbar && (
              <BottomToolbarPlugin
                disableAttachments={disableAttachments}
                isGifPickerOpen={isGifPickerOpen}
                isVideoLoading={isVideoLoading}
                attachments={editorData?.attachments || null}
                isLoadingImages={!!loadingImages && loadingImages.length > 0}
                setIsGifPickerOpen={setIsGifPickerOpen}
                onImageLoad={handleAddImageToAttachments}
                onGifSelect={handleGifSelect}
                setLoadingImages={props.setLoadingImages}
                onUploadVideo={props.onUploadVideo}
                onVideoError={props.onVideoError}
                convertImageItemToAttachment={props.convertImageItemToAttachment}
              />
            )}
          {isDesktopPlus &&
            editorType !== EditorType.CommentDesktop &&
            editorType !== EditorType.Collaboration &&
            editorType !== EditorType.Biography && <div className={styles.Separator} />}
          <TextStylingToolbarPlugin
            isAlwaysExpand={
              isDesktopPlus ||
              editorType === EditorType.VideoPost ||
              editorType === EditorType.Collaboration ||
              editorType === EditorType.CommentMobile ||
              editorType === EditorType.Biography
            }
            isExpandStylingToolbar={isExpandStylingToolbar}
            setIsExpandStylingToolbar={setIsExpandStylingToolbar}
          />
          {editorType !== EditorType.Collaboration && (
            <SubmitToolbarPlugin isSendHidden={isSendHidden} isSendDisabled={disabled} />
          )}
        </div>
        {editorType !== EditorType.VideoPost &&
          (isAttachmentsExist(editorData?.attachments) ||
            !!loadingImages?.length ||
            isVideoLoading) && (
            <div className={styles.BaseEditor__AttachImagesWrapper}>
              {editorData?.attachments?.images &&
                editorData.attachments.images.map((file, index) => (
                  <div key={file.uuid} className={styles.BaseEditor__AttachImageItem}>
                    {!loadingImages?.length && (
                      <div className={styles.BaseEditor__AttachDelete}>
                        <TextTooltip
                          tooltipOffset={10}
                          text="Remove file"
                          eventType={TooltipEventType.hover}
                          placement="top"
                        >
                          <IconButton
                            iconName={IconFontName.Close}
                            iconSize={IconFontSize.Small}
                            theme={IconButtonTheme.AttachmentAction}
                            onClick={handleImageDelete(file.uuid)}
                          />
                        </TextTooltip>
                      </div>
                    )}
                    <AttachmentsImageItem
                      alt={`Attach ${index}`}
                      imageUrl={file.url}
                      mimeType={file.mimeType}
                      theme={AttachmentsImageTheme.PostCreation}
                    />
                  </div>
                ))}
              {editorData?.attachments?.gifs?.length &&
                editorData?.attachments?.gifs.map((gifId) => (
                  <div className={styles.BaseEditor__AttachImageItem}>
                    <div className={styles.BaseEditor__AttachDelete}>
                      <IconButton
                        iconName={IconFontName.Close}
                        iconSize={IconFontSize.Small}
                        theme={IconButtonTheme.AttachmentAction}
                        onClick={handleGifDelete(gifId)}
                      />
                    </div>
                    <SingleGif isNeedGifIcon size={GifSize.SIZE_80} id={gifId} />
                  </div>
                ))}
              {editorData?.attachments?.videos?.length && (
                <div className={styles.BaseEditor__AttachImageItem}>
                  <div className={styles.BaseEditor__AttachDelete}>
                    <IconButton
                      iconName={IconFontName.Close}
                      iconSize={IconFontSize.Small}
                      theme={IconButtonTheme.AttachmentAction}
                      onClick={handleVideoDelete}
                    />
                  </div>
                  <VideoPreview size={VideoPreviewSize.SIZE_80} url={postVideo?.url || ''} />
                </div>
              )}
              {loadingImages?.map((imageName) => (
                <div key={imageName} className={styles.ImagesLoadingWrapper}>
                  <Loader size={LoaderSizeEnum.S} theme={LoaderTheme.Blue} isShow />
                </div>
              ))}
              {isVideoLoading && (
                <div className={styles.ImagesLoadingWrapper}>
                  <Loader size={LoaderSizeEnum.S} theme={LoaderTheme.Blue} isShow />
                </div>
              )}
            </div>
          )}
        {editorType === EditorType.Collaboration && (
          <>
            <div className={styles.ToolbarRow}>
              <SingleMediaToolbarPlugin
                postId={props.postId}
                mediaType={mediaType}
                isLoading={false}
                isCollaborationVideoLoading={props.isCollaborationVideoLoading}
                isCollaborationImageLoading={props.isCollaborationImageLoading}
                collaborationMediaItem={collaborationMediaItem}
                setCollaborationMediaItem={props.setCollaborationMediaItem}
                convertImageItemToAttachment={props.convertImageItemToAttachment}
                convertVideoItemToAttachment={props.convertVideoItemToAttachment}
              />
            </div>
            <div className={styles.ToolbarRow}>
              <SubmitToolbarPlugin
                editorType={editorType}
                isSendHidden={isSendHidden}
                isSendDisabled={disabled}
              />
            </div>
          </>
        )}
        {editorType === EditorType.VideoPost && (
          <div className={styles.ToolbarRow}>
            <VideoPostToolbarPlugin
              postVideo={postVideo}
              isVideoLoading={isVideoLoading}
              onUploadVideo={props.onUploadVideo}
            />
            <SubmitToolbarPlugin isSendHidden={isSendHidden} isSendDisabled={disabled} />
          </div>
        )}
      </div>
      {isTreeViewEnabled && <TreeViewPlugin />}
    </>
  );
});
