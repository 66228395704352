import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { ZodError } from 'zod';

import { IPublicLink, LinkAction } from 'stores/auth/interfaces/user-public-link.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import {
  linkDescriptionSchema,
  linkTitleSchema,
  linkUrlSchema,
} from 'validation/schemas/link.schema';
import {
  MAX_LINK_DESCRIPTION_LENGTH,
  MAX_LINK_TITLE_LENGTH,
} from 'validation/validation.constants';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { TextArea } from 'components/ui/form-fields/text-area/text-area.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './custom-links-popup.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.7;

interface ICustomLinksPopupProps {
  visible: boolean;
  onClose: () => void;
  initialValues: IPublicLink;
  link: Maybe<IPublicLink>;
  onDeleteClick: (payload: IPublicLink) => void;
  onSubmitClick: (payload: IPublicLink) => void;
  onUpdateClick: (payload: IPublicLink) => void;
}

export const CustomLinksPopup: FC<ICustomLinksPopupProps> = (props) => {
  const { visible, link, onDeleteClick, onUpdateClick, onSubmitClick, initialValues } = props;

  const [values, setValues] = useState<IPublicLink>(initialValues);
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    url: '',
  });

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const handleDeleteClick = useCallback(() => {
    if (link) {
      onDeleteClick(link);
    }
  }, [onDeleteClick, link]);

  const handleSubmitClick = useCallback(() => {
    if (link) {
      onUpdateClick({
        ...values,
        action: !link.action || link.action === LinkAction.Edit ? LinkAction.Edit : link.action,
      });
    } else {
      onSubmitClick({ ...values, action: LinkAction.Create });
    }
  }, [link, values, onSubmitClick, onUpdateClick]);

  const handleChange = useCallback((name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleBlur = useCallback((event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement;

    const prepareErrors = (field: string, error?: ZodError) => {
      const errorMessage = error?.issues[0]?.message;

      if (errorMessage) {
        setErrors((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrors((prev) => ({ ...prev, [field]: '' }));
      }
    };

    switch (name) {
      case 'title':
        prepareErrors('title', linkTitleSchema.safeParse(value)?.error);
        break;
      case 'description':
        prepareErrors('description', linkDescriptionSchema.safeParse(value)?.error);
        break;
      case 'url':
        prepareErrors('url', linkUrlSchema.safeParse(value)?.error);
        break;
      default:
        break;
    }
  }, []);

  const wrapperClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--keyboard']]: isKeyboardOpen,
    });
  }, [isKeyboardOpen]);

  const linkHeaderTitleClasses = useMemo<string>(() => {
    return cn(styles.CustomLinksHeader__Title, {
      [styles['CustomLinksHeader__Title--shiftRight']]: link,
    });
  }, [link]);

  const isValid = useMemo(() => {
    return (
      !Object.values(errors).some((error) => !!error) &&
      Object.entries(values)
        .filter((item) => item[0] !== 'description')
        .every((item) => Boolean(item[1]))
    );
  }, [errors, values]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const renderEditForm = useCallback(() => {
    return (
      <div className={styles.Content}>
        <TextArea
          name="title"
          maxLength={MAX_LINK_TITLE_LENGTH}
          error={errors.title}
          id="title"
          label="Title"
          placeholder="Create Title"
          rows={1}
          disabledResize
          onChange={(value) => handleChange('title', value)}
          onBlur={handleBlur}
          value={values.title}
        />
        <TextArea
          name="description"
          maxLength={MAX_LINK_DESCRIPTION_LENGTH}
          error={errors.description}
          id="description"
          label="Description"
          placeholder="Add Description (optional)"
          rows={1}
          disabledResize
          onChange={(value) => handleChange('description', value)}
          onBlur={handleBlur}
          value={values.description ?? ''}
        />
        <TextInput
          name="url"
          error={errors.url}
          id="url"
          label="Link"
          placeholder="Insert Link"
          onChange={(value) => handleChange('url', value)}
          onBlur={handleBlur}
          value={values.url}
        />
      </div>
    );
  }, [values, handleChange, handleBlur, errors]);

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={visible}
        onClose={props.onClose}
        title={link ? 'Edit Link' : 'Add Link'}
        size={ModalWindowSize.M}
      >
        {renderEditForm()}
        <div className={styles.CustomLinksFooter}>
          {link ? (
            <button className={styles.CustomLinksDeleteButton} onClick={handleDeleteClick}>
              <IconFont name={IconFontName.Remove} size={IconFontSize.Small} />
              <span className={styles.CustomLinksDeleteButton__Title}>Delete</span>
            </button>
          ) : (
            <div />
          )}
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Big}
            className={styles.CustomLinksFooter__ActionButton}
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            {link ? 'Save' : 'Add'}
          </Button>
        </div>
      </BaseModalComponent>
    );
  }

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      onClose={props.onClose}
    >
      <div className={wrapperClasses}>
        <div className={styles.CustomLinksHeader}>
          <div className={linkHeaderTitleClasses}>{link ? 'Edit Link' : 'Add Link'}</div>
          {link && (
            <button className={styles.CustomLinksDeleteButton} onClick={handleDeleteClick}>
              <IconFont name={IconFontName.Remove} size={IconFontSize.Small} />
              <span className={styles.CustomLinksDeleteButton__Title}>Delete</span>
            </button>
          )}
        </div>
        {renderEditForm()}
        <div className={styles.CustomLinksFooter}>
          <div />
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Big}
            disabled={!isValid}
            onClick={handleSubmitClick}
          >
            {link ? 'Save' : 'Add'}
          </Button>
        </div>
      </div>
    </BaseIonBottomSheet>
  );
};
