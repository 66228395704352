import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { IFileData } from 'helpers/to-base64.util';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { PhotoCameraField } from 'components/forms/photo-camera-field/photo-camera-field.component';
import { FileInputWithCropping } from 'components/ui/form-fields/file-input-with-cropping/file-input-with-cropping.component';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './profile-thumbnail-input.module.less';

export interface IProfileThumbnailInputProps extends Omit<IHookFormInput, 'onChange'> {
  thumbnailURL: Maybe<string>;
  thumbnailNicknameUrl: Maybe<string>;
  thumbnailLogoUrl: Maybe<string>;
  onChange: (value: IFileData) => void;
  accept: string;
  isCropModalVisible: boolean;
  onSetCropModal?: (isOpen: boolean) => void;
  forEditModal: boolean;
  disableHover?: boolean;
}

export const ProfileThumbnailInput: FC<IProfileThumbnailInputProps> = memo(
  (props: IProfileThumbnailInputProps) => {
    const {
      isCropModalVisible,
      thumbnailURL,
      thumbnailLogoUrl,
      thumbnailNicknameUrl,
      onChange,
      id,
      name,
      accept,
      forEditModal,
      disableHover = false,
    } = props;

    const [localThumbnail, setLocalThumbnail] = useState<Maybe<string>>(null);

    const inputClickHandlerRef = useRef<Maybe<() => void>>();
    const ref = useRef<HTMLDivElement>(null);

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
    const { isNativeApp } = useMainProvider();

    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView();
      }
    }, []);

    const thumbnailBackground = useMemo(
      () => localThumbnail || thumbnailURL || '',
      [localThumbnail, thumbnailURL],
    );

    const handleConnectRef = useCallback((handler?: () => void) => {
      if (handler) {
        inputClickHandlerRef.current = handler;
      }
    }, []);

    const handleClickChangeThumbnail = useCallback(() => {
      if (inputClickHandlerRef.current) {
        inputClickHandlerRef.current();
      }
    }, []);

    const handleChangeThumbnail = useCallback(
      (fileData: IFileData[]) => {
        setLocalThumbnail(fileData[0].value);

        if (onChange && !isCropModalVisible) {
          onChange(fileData[0]);
        }
      },
      [onChange, isCropModalVisible],
    );

    const handleOnSaveImage = useCallback(
      (fileData: IFileData) => {
        if (onChange) {
          onChange(fileData);
        }
      },
      [onChange],
    );

    const profileThumbnailInputClasses = useMemo(
      () =>
        cn(styles.ProfileThumbnailInput, {
          [styles['ProfileThumbnailInput--is-edit-modal']]: forEditModal,
        }),
      [forEditModal],
    );

    return (
      <div ref={ref} className={profileThumbnailInputClasses}>
        <div className={styles.ProfileThumbnail}>
          {(localThumbnail || thumbnailURL) && (
            <img
              src={thumbnailBackground}
              alt="thumbnail background"
              className={styles.ProfileThumbnail__Background}
            />
          )}
          {thumbnailNicknameUrl && (
            <img
              src={thumbnailNicknameUrl}
              alt="thumbnail nickname"
              className={styles.ProfileThumbnail__Nickname}
            />
          )}
          {thumbnailLogoUrl && (
            <img
              src={thumbnailLogoUrl}
              alt="thumbnail logo"
              className={styles.ProfileThumbnail__Logo}
            />
          )}
        </div>
        {isDesktopPlus && !disableHover && (
          <div
            className={styles.ProfileThumbnailInput__Button}
            onClick={handleClickChangeThumbnail}
            onKeyDown={handleClickChangeThumbnail}
            role="button"
            tabIndex={0}
          >
            <IconFont name={IconFontName.Image} size={IconFontSize.Big} /> Change Background
          </div>
        )}
        {forEditModal && (
          <div className={styles.ProfileThumbnailInput__ButtonWrapper}>
            <IconButton
              theme={IconButtonTheme.Navigation}
              onClick={handleClickChangeThumbnail}
              iconName={IconFontName.Camera}
              iconSize={IconFontSize.Big}
            />
          </div>
        )}
        <PhotoCameraField enabledFunction={forEditModal && isNativeApp}>
          <FileInputWithCropping
            id={id}
            name={name}
            onBlur={props.onBlur}
            onChange={handleChangeThumbnail}
            onAddFilesSet={handleConnectRef}
            accept={accept}
            isCropCircle={false}
            onSetCropModal={props.onSetCropModal}
            onSaveImage={handleOnSaveImage}
            isCropModalVisible={props.isCropModalVisible}
          />
        </PhotoCameraField>
      </div>
    );
  },
);
