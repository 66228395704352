import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useGetPlayerById } from 'query-hooks/followings/use-get-player-by-id';
import { useGetPlayerBySlug } from 'query-hooks/followings/use-get-player-by-slug';
import { useGetTeamById } from 'query-hooks/followings/use-get-team-by-id';
import { SHORT_ACHIEVEMENTS_LIST_LIMIT } from 'query-hooks/reputation/contastants/entries.contstants';
import { useDaysStreakQuery } from 'query-hooks/reputation/queries/use-days-streak.query';
import { useUserAchievementsInfinitiveQuery } from 'query-hooks/reputation/queries/use-user-achievements.query';
import { useUserTopSeasonAchievementQuery } from 'query-hooks/reputation/queries/use-user-top-achievement.query';
import { usePlayerBucketQuery } from 'query-hooks/reputation/queries/user-player-bucket.query';

import { BucketsStore } from 'stores/buckets/buckets.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { UserDetails } from 'components/user-details/user-details.component';

import styles from './user-details-container.module.less';

export const UserDetailsContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);
  const bucketsStore = useInjection<BucketsStore>(TYPES.BucketsStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const params = useParams<{ [PLAYER_SLUG_PARAM]: string }>();

  const playerSlug = params[PLAYER_SLUG_PARAM];

  const getPlayerBySlug = useGetPlayerBySlug();
  const getTeamById = useGetTeamById();
  const getPlayerById = useGetPlayerById();

  const favoriteTeam = getTeamById(userPublicStore.userPopUpDetails?.favoriteTeamId || null);
  const favoritePlayer = getPlayerById(userPublicStore.userPopUpDetails?.favoritePlayerId || null);
  const currentPlayerFeed = getPlayerBySlug(playerSlug);

  const { data: daysStreak = null } = useDaysStreakQuery(
    userPublicStore.userPopUpDetails?.username,
  );

  const { data: topAchievement = null } = useUserTopSeasonAchievementQuery(
    userPublicStore.userPopUpDetails?.username,
    currentPlayerFeed?.id,
  );
  const { data: userAchievementsData } = useUserAchievementsInfinitiveQuery(
    userPublicStore.userPopUpDetails?.username,
    null,
    SHORT_ACHIEVEMENTS_LIST_LIMIT,
    topAchievement ? [topAchievement.id] : [],
  );
  const { data: playerBucket } = usePlayerBucketQuery(currentPlayerFeed?.id);

  const handleClosePopUp = useCallback(() => {
    layoutStore.setIsUserDetailsPopUpOpen(false);
    userPublicStore.setUserPopUpDetails(null);
  }, [layoutStore, userPublicStore]);

  const realName = useMemo(() => {
    if (!userPublicStore.userPopUpDetails) {
      return null;
    }

    return 'name' in userPublicStore.userPopUpDetails
      ? userPublicStore?.userPopUpDetails.name
      : userPublicStore?.userPopUpDetails.realName;
  }, [userPublicStore.userPopUpDetails]);

  if (!userPublicStore.userPopUpDetails) {
    return null;
  }

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={layoutStore.isUserDetailsPopUpOpen}
        size={ModalWindowSize.M}
        title={null}
        closeOnOverlayClick
        onClose={handleClosePopUp}
      >
        <UserDetails
          daysStreak={daysStreak}
          activeBucket={playerBucket?.id.toString() ?? bucketsStore.defaultBucket.value}
          reputationPoints={reputationsPointsStore.userPopUpReputationPoints}
          avatarSrc={userPublicStore.userPopUpDetails.avatarUrl}
          player={favoritePlayer}
          team={favoriteTeam}
          thumbnailURL={userPublicStore.userPopUpDetails.smallThumbnailUrl}
          username={userPublicStore.userPopUpDetails.username}
          realName={realName}
          onClose={handleClosePopUp}
          topAchievement={topAchievement}
          userAchievements={userAchievementsData?.achievements ?? []}
          currentPlayerFeedAvatarSrc={currentPlayerFeed?.smallLogoUrl}
        />
      </BaseModalComponent>
    );
  }

  return (
    <div className={styles.UserDetails}>
      <BaseIonBottomSheet
        isAutoHeight
        isOverflowVisible
        visible={layoutStore.isUserDetailsPopUpOpen}
        safeAreaBottom={0}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onClose={handleClosePopUp}
      >
        <UserDetails
          daysStreak={daysStreak}
          activeBucket={playerBucket?.id.toString() ?? bucketsStore.defaultBucket.value}
          reputationPoints={reputationsPointsStore.userPopUpReputationPoints}
          avatarSrc={userPublicStore.userPopUpDetails.avatarUrl}
          player={favoritePlayer}
          team={favoriteTeam}
          thumbnailURL={userPublicStore.userPopUpDetails.smallThumbnailUrl}
          username={userPublicStore.userPopUpDetails.username}
          realName={realName}
          onClose={handleClosePopUp}
          topAchievement={topAchievement}
          userAchievements={userAchievementsData?.achievements ?? []}
          currentPlayerFeedAvatarSrc={currentPlayerFeed?.smallLogoUrl}
        />
      </BaseIonBottomSheet>
    </div>
  );
});
