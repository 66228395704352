import { useCallback, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { PlayerStore } from 'stores/player/player.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

import { useTeamClick } from 'hooks/use-team-click';

import { PlayerProfileSplits } from 'components/player-profile/player-profile-splits/player-profile-splits.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../player-profile.module.less';

export const PlayerProfileSplitsContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);

  const handleTeamClick = useTeamClick();

  const handleSeasonChange = useCallback(
    (value: string) => {
      const option = seasonsStore.seasonsSelectOptions.find((season) => season.value === value);
      if (option) {
        playerStore.setSeasonOption(option);
      }
    },
    [playerStore, seasonsStore.seasonsSelectOptions],
  );

  useEffect(() => {
    if (playerStore.playerDetails) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileSplits,
          player: playerStore.playerDetails.fullName,
        },
      });
    }
  }, [playerStore.playerDetails]);

  if (!playerStore.seasonOption || playerStore.fetching) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <PlayerProfileSplits
      selectedSeason={playerStore.seasonOption}
      seasonOptions={seasonsStore.seasonsSelectOptions}
      playerSplits={playerStore.playerSplits}
      hasTeamColor={!!playerStore.playerDetails?.team?.color}
      onSeasonChange={handleSeasonChange}
      onTeamClick={handleTeamClick}
    />
  );
});
