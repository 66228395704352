import { useMemo } from 'react';
import { useGetFollowData } from 'query-hooks/followings/use-get-follow-data';

export const useGetFollowingPlayersAbbr = () => {
  const followData = useGetFollowData();

  return useMemo(() => {
    return followData.map((player) => {
      const firstNameCharacters = player.firstName.slice(0, 2);

      return `${firstNameCharacters}${player.lastName}`;
    });
  }, [followData]);
};
