import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import {
  MY_MESSAGES_MENU_GROUPS,
  OTHER_MESSAGES_MENU_GROUPS,
} from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';
import { userReportHandler } from 'helpers/user-report-handler.util';

import { useResponsive } from 'hooks/use-responsive';
import { useScrollTo } from 'hooks/use-scroll-to';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { ICommentData } from 'components/comment/comment.component';
import { IPostCardUserInfo } from 'components/posts/post-card/interfaces/post-info.interface';
import { ReportsPopup } from 'components/reports/reports-popup/reports-popup.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTheme,
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from './game-chat-message.module.less';

const HIGHLIGHT_TIMEOUT = 2000;

let timer: NodeJS.Timeout;

interface IGameChatCommentProps {
  isMyComment: boolean;
  isLiked: boolean;
  gameId: number;
  messageId: string;
  author: IPostCardUserInfo;
  commentData: ICommentData;
  likes: number;
  scrollCommentId: Maybe<string>;
  reportReasons: Maybe<IReportReason[]>;
  onLikes: (messageId: string) => void;
  onDeleteComment?: (messageId: string) => void;
  onResetScrollCommentId?: () => void;
  onTeamClick?: (teamId: number) => void;
  onPlayerClick?: (slug: string) => void;
  onCloseReportsPopup?: (gameChatItemId: string) => void;
  onSendGameChatItemReport?: (
    gameChatItemId: string,
    gameId: number,
    reasonId: number,
  ) => Promise<boolean>;
  requestReasons: () => void;
  favoriteTeamId: Maybe<number>;
  favoritePlayerId: Maybe<number>;
  getPlayerById: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  getTeamById: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
}

export const GameChatComment = (props: IGameChatCommentProps) => {
  const {
    isLiked,
    isMyComment,
    gameId,
    messageId,
    scrollCommentId,
    author,
    commentData,
    likes,
    reportReasons,
    onLikes,
    onTeamClick,
    onDeleteComment,
    onPlayerClick,
    onResetScrollCommentId,
    onCloseReportsPopup,
    onSendGameChatItemReport,
    favoritePlayerId,
    favoriteTeamId,
    getPlayerById,
    getTeamById,
  } = props;

  const commentIdWithSlug = `#comment-${messageId}`;

  const favoriteTeam = getTeamById(favoriteTeamId);
  const favoritePlayer = getPlayerById(favoritePlayerId);

  const [isReportsPopupOpen, setIsReportsPopupOpen] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(true);
  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>();
  const [isLikeEnabled, setIsLikeEnabled] = useState(true);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { hash } = useLocation();

  useEffect(() => {
    if (scrollCommentId !== messageId && hash !== commentIdWithSlug) {
      return;
    }

    setShouldScrollTo(true);
    setIsHighlighted(true);

    if (scrollCommentId === messageId && onResetScrollCommentId) {
      onResetScrollCommentId();
    }

    timer = setTimeout(() => {
      setIsHighlighted(false);
    }, HIGHLIGHT_TIMEOUT);
  }, [
    scrollCommentId,
    messageId,
    setShouldScrollTo,
    onResetScrollCommentId,
    hash,
    commentIdWithSlug,
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const commentBodyClassNames = useMemo(
    () =>
      cn(styles.Comment__Body, {
        [styles['Comment__Body--highlighted']]: isHighlighted,
      }),
    [isHighlighted],
  );

  const overridePlayer: Maybe<IPlayerStats> = useMemo(() => {
    if (favoritePlayer) {
      return {
        ...favoritePlayer,
        fontColor: PlayerFontColor.Light,
      };
    }

    return null;
  }, [favoritePlayer]);

  const richTextContentTheme = useMemo(() => {
    if (isHighlighted) {
      return RichTextContentTheme.Highlighted;
    }

    return RichTextContentTheme.LightGrey;
  }, [isHighlighted]);

  const handleTeamClick = useCallback(
    (teamId: number) => {
      if (onTeamClick) {
        onTeamClick(teamId);
      }
    },
    [onTeamClick],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      if (onPlayerClick) {
        onPlayerClick(slug);
      }
    },
    [onPlayerClick],
  );

  const handleContextMenuAnchorClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click) {
        if (barAction.payload === 'user-report') {
          userReportHandler();
        }

        if (barAction.payload === 'report') {
          setIsReportsPopupOpen(true);
        }

        if (barAction.payload === 'delete') {
          onDeleteComment?.(messageId);
        }
      }

      setIsNeedCloseTooltip(true);
    },
    [onDeleteComment, messageId],
  );

  const handleReportsPopupClose = useCallback(() => {
    setIsReportsPopupOpen(false);
    onCloseReportsPopup?.(messageId);
  }, [messageId, onCloseReportsPopup]);

  const handleGameChatItemReportSubmit = useCallback(
    async (reportReasonId: number) => {
      const isSuccess = await onSendGameChatItemReport?.(messageId, gameId, reportReasonId);

      return !!isSuccess;
    },
    [messageId, gameId, onSendGameChatItemReport],
  );

  const contextMenuItems = useMemo(() => {
    if (isMyComment) {
      return MY_MESSAGES_MENU_GROUPS;
    }

    return OTHER_MESSAGES_MENU_GROUPS;
  }, [isMyComment]);

  const handleLikes = useCallback(async () => {
    setIsLikeEnabled(false);
    await onLikes(messageId);
    setIsLikeEnabled(true);
  }, [onLikes, messageId]);

  return (
    <div ref={scrollToRef} className={styles.Comment}>
      <div className={styles.Comment__UserAvatar}>
        <Avatar size={AvatarSize.M} username={author.username} src={author.smallAvatarUrl} />
      </div>
      <div className={styles.Comment__Main}>
        <div className={commentBodyClassNames}>
          <div className={styles.Comment__UserInfo}>
            <div className={styles.LeftSide}>
              <div className={styles.TopSection}>
                <div className={styles.TopSection__UserNick}>
                  {!author.athlete ? `@${author.username}` : author.athlete.fullName}
                </div>
                {!!author.name && !author.athlete && isDesktopPlus && (
                  <div className={styles.TopSection__UserName}>{author.name}</div>
                )}
                <div className={styles.TopSection__More}>
                  <ContextMenuTooltip
                    groups={contextMenuItems}
                    onItemClick={handleContextMenuClick}
                    toggleOnClick
                    setIsNeedCloseTooltip={setIsNeedCloseTooltip}
                    tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
                    isNeedCloseTooltip={isNeedCloseTooltip}
                  >
                    <IconButton
                      iconName={IconFontName.More2}
                      theme={IconButtonTheme.Secondary}
                      iconSize={IconFontSize.Small}
                      onClick={handleContextMenuAnchorClick}
                    />
                  </ContextMenuTooltip>
                </div>
              </div>
              <TeamPlayerLabel
                isNeedLowOpacity
                team={favoriteTeam}
                player={overridePlayer}
                onTeamClick={handleTeamClick}
                onPlayerClick={handlePlayerClick}
              />
            </div>
          </div>
          <div className={styles.Comment__Content}>
            {commentData.comment && (
              <RichTextPreview
                id={messageId}
                theme={richTextContentTheme}
                editorState={commentData.comment}
                editorType={RichTextContentTypes.WithReadMore}
              />
            )}
          </div>
        </div>
        <div className={styles.Comment__Footer}>
          <AuthTooltip>
            <IconButton
              iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
              theme={IconButtonTheme.Transparent}
              iconSize={IconFontSize.Small}
              onClick={handleLikes}
              active={isLiked}
              disabled={!isLikeEnabled}
            />
          </AuthTooltip>
          {!!likes && (
            <span className={styles.PostCardActionsBar__Number}>{formatBigNumbers(likes)}</span>
          )}
          <div className={styles.Comment__Time}>{commentData.createAt}</div>
        </div>
        {!isMyComment && isReportsPopupOpen && (
          <ReportsPopup
            requestReasons={props.requestReasons}
            reasons={reportReasons}
            visible={isReportsPopupOpen}
            onClose={handleReportsPopupClose}
            onSendReport={handleGameChatItemReportSubmit}
          />
        )}
      </div>
    </div>
  );
};
