import { memo, useCallback, useMemo, useState } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import {
  AuthorCardDetails,
  IAuthorCardDetailsProps,
} from './author-card-details/author-card-details.component';
import {
  AuthorCardHeader,
  IAuthorCardHeaderProps,
} from './author-card-header/author-card-header.component';
import {
  AuthorCardMore,
  IAuthorCardMoreProps,
} from './author-card-more/author-card-more.component';

import styles from './author-card.module.less';

export interface IAuthorCardProps
  extends IAuthorCardHeaderProps,
    IAuthorCardDetailsProps,
    IAuthorCardMoreProps {}

export const AuthorCard = memo((props: IAuthorCardProps) => {
  const {
    username,
    avatarSrc,
    thumbnailURL,
    thumbnailLogoURL,
    thumbnailNicknameURL,
    realname,
    team,
    player,
    userStatus,
    userAbout,
    userMutualFriends,
    userTeams,
    userSocials,
    onSocialClick,
    athleteName,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const hasFooter = useMemo(
    () => !!userAbout || !!userTeams || !!userMutualFriends,
    [userAbout, userTeams, userMutualFriends],
  );

  const handleExpand = useCallback(() => setExpanded((prev) => !prev), []);

  const handleSocialClick = useCallback(
    (path: string) => {
      if (onSocialClick) {
        onSocialClick(path);
      }
    },
    [onSocialClick],
  );

  return (
    <div className={styles.AuthorCard}>
      <div className={styles.AuthorCard__Title}>Author&apos;s Profile</div>
      <AuthorCardHeader
        username={username}
        avatarSrc={avatarSrc}
        thumbnailURL={thumbnailURL}
        thumbnailLogoURL={thumbnailLogoURL}
        thumbnailNicknameURL={thumbnailNicknameURL}
      />
      <AuthorCardDetails
        athleteName={athleteName}
        username={username}
        player={player}
        realname={realname}
        team={team}
        userStatus={userStatus}
        userSocials={userSocials}
        onSocialClick={handleSocialClick}
        onTeamClick={props.onTeamClick}
        onPlayerClick={props.onPlayerClick}
      />
      {hasFooter && (
        <>
          <hr />
          {expanded && (
            <>
              <AuthorCardMore
                userAbout={userAbout}
                userTeams={userTeams}
                userMutualFriends={userMutualFriends}
              />
              <hr />
            </>
          )}
          <Button
            theme={ButtonTheme.Text}
            size={ButtonSize.Small}
            onClick={handleExpand}
            fluid
            iconName={expanded ? IconFontName.ChevronUp : IconFontName.ChevronDown}
          >
            {expanded ? 'See less' : 'See more'}
          </Button>
        </>
      )}
    </div>
  );
});
