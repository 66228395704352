import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';

import { FollowService } from 'services/follow/follow.service';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 30 * 1000; // 30 seconds
const POLL_INTERVAL = 30 * 1000; // 30 seconds

const retrieveGetAllFollowQuery = async () => {
  const followService = container.get<FollowService>(TYPES.FollowService);

  const response = await followService.loadFollowings();

  if (response.success) {
    return response.data ? response.data : [];
  }

  return [];
};

export const useGetAllFollowQuery = () => {
  const queryResult = useQuery({
    queryKey: followQueryKeys.prefix,
    queryFn: retrieveGetAllFollowQuery,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
    refetchInterval: POLL_INTERVAL,
    refetchIntervalInBackground: true,
  });

  const memoizedData = useMemo(() => {
    return queryResult.data || [];
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: memoizedData,
  };
};
