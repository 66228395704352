import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { AuthStore } from 'src/stores/auth/auth.store';

import { userLinksPayloadBuilder } from 'stores/auth/adapters/user-links-payload-builder.util';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import { trimEditorContent } from 'helpers/trim-editor-content.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import {
  AthleteProfileFormDataType,
  EditProfileForm,
  EditProfileFormDataType,
} from 'components/edit-profile/edit-profile-form/edit-profile-form.component';

import styles from './edit-profile.module.less';

export const EditProfileContainer: FC = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const params = useParams<{
    [paths.PLAYER_SLUG_PARAM]: string;
    [paths.USERNAME_PARAM]: string;
  }>();

  const { playerSlug } = params;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isDesktopPlus) {
      return () => {};
    }

    if (playerSlug) {
      playerStore.setPlayerSlug(playerSlug);
    } else {
      playerStore.setPlayerSlug(null);
    }

    return () => {
      playerStore.setPlayerSlug(null);
    };
  }, [layoutStore, playerSlug, playerStore, isDesktopPlus]);

  const isAthlete = useMemo(() => {
    return authStore.userMe?.athlete !== null;
  }, [authStore.userMe]);

  const handleSaveChanges = useCallback(
    async (
      formData: EditProfileFormDataType | AthleteProfileFormDataType,
    ): Promise<Maybe<IUserMe> | void> => {
      if (!authStore.userMe) {
        return null;
      }

      const userMeUsername = authStore.userMe.username;

      const { biography: biographyEditor, socialLinks, customLinks } = formData;
      let biography = '';

      if (biographyEditor.content?.value) {
        biography = biographyEditor.content.value;

        const editorState = JSON.parse(biographyEditor?.content?.value);
        const content = editorState?.root?.children;

        const newEditorState = {
          ...editorState,
          root: {
            ...editorState.root,
            children: trimEditorContent(content),
          },
        };

        biography = content[0]?.children?.length ? JSON.stringify(newEditorState) : '';
      }

      let updateData: IUpdateUserParams = {
        biography,
        links: userLinksPayloadBuilder(customLinks, socialLinks),
      };

      if (!isAthlete) {
        const { username, realname, thumbnail, avatar } = formData as EditProfileFormDataType;

        let updatedAvatar = {};
        let updatedThumbnail = {};

        if (avatar.value && avatar.value.split(',')[0].indexOf('base64') > 0) {
          updatedAvatar = { avatar: avatar.value.split(',')[1] };
        }

        if (thumbnail.value && thumbnail.value.split(',')[0].indexOf('base64') > 0) {
          updatedThumbnail = { thumbnail: thumbnail.value.split(',')[1] };
        }

        updateData = {
          ...updateData,
          userName: username,
          realName: realname,
          ...updatedAvatar,
          ...updatedThumbnail,
        };
      }

      const updatedUserMe = await authStore.updateUserMe(updateData);

      if ('userName' in updateData && updateData.userName !== userMeUsername) {
        navigate(
          getPath(paths.USER_EDIT_PROFILE, {
            [paths.USERNAME_PARAM]: authStore.userMe?.username,
          }),
        );
      }

      return updatedUserMe;
    },
    [authStore, isAthlete, navigate],
  );

  if (!authStore.userMe) {
    return null;
  }

  return (
    <div className={styles.EditProfile}>
      {isDesktopPlus && <h3 className={styles.EditProfile__Title}>Edit profile</h3>}
      <EditProfileForm
        isAthlete={isAthlete}
        userMe={authStore.userMe}
        onSubmit={handleSaveChanges}
        playerDetails={playerStore.playerDetails}
      />
    </div>
  );
});
