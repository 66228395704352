import { memo } from 'react';
import cn from 'classnames';

import { DaysStreakAchievementBadge } from 'components/achievements/days-streak-achievement-badge/days-streak-achievement-badge.component';

import styles from './achievement-card.module.less';

export interface IDayStreakAchievementProps {
  maxDays: number;
  currentDaysProgress: number;
  title: string;
}

export const DayStreakAchievementComponent = memo((props: IDayStreakAchievementProps) => {
  const { maxDays, currentDaysProgress, title } = props;

  return (
    <div className={cn(styles.AchievementCard, styles.AchievementCard__streak)}>
      <div className={styles.AchievementBackground} />
      <div className={styles.AchievementCard__Streak}>
        <DaysStreakAchievementBadge value={maxDays} showMax />
      </div>
      <div className={styles.AchievementContent}>
        <div className={styles.AchievementContent__Info}>
          <p className={styles.AchievementContent__Title}>{title}</p>
        </div>
        <div className={styles.AchievementContent__Progress}>
          <div className={styles.AchievementContent__Progress__Days}>
            <h4>{currentDaysProgress}</h4>
            <span>days</span>
          </div>
        </div>
      </div>
    </div>
  );
});
