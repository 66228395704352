import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { PlayerStore } from 'stores/player/player.store';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { PlayerProfileOverview } from 'components/player-profile/player-profile-overview/player-profile-overview.component';

export const PlayerProfileOverviewContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const splitsGamesStore = useInjection<SplitsGamesStore>(TYPES.SplitsGamesStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    if (playerStore.playerDetails?.team) {
      teamsStatsStore.retrieveRecentGames(playerStore.playerDetails.team.id);
    }
  }, [playerStore.playerDetails?.team, splitsGamesStore, teamsStatsStore]);

  useEffect(() => {
    if (playerStore.playerDetails) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileProfile,
          player: playerStore.playerDetails.fullName,
        },
      });
    }
  }, [playerStore.playerDetails]);

  if (!playerStore.playerDetails) {
    return null;
  }

  return (
    <PlayerProfileOverview
      socialLinks={playerStore.athlete?.socialLinks || []}
      customLinks={playerStore.athlete?.customLinks || []}
      player={playerStore.playerDetails}
      currentTeamId={playerStore.currentTeamId}
      aboutThePlayer={playerStore.getAboutThePlayer(isDesktopPlus)}
      recentGames={teamsStatsStore.teamsGamesDetail}
      biography={playerStore.athlete?.biography}
    />
  );
});
