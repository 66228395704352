import { memo, useMemo } from 'react';
import cn from 'classnames';

import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ICommentData } from 'components/comment/comment.component';
import { IPostCardUserInfo } from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from '../comment.module.less';

interface ICommentWithoutInteractionsProps {
  id: string;
  commentAuthor: IPostCardUserInfo;
  commentData: ICommentData;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  favoritePlayer: Maybe<IPlayerStats>;
  favoriteTeam: Maybe<ITeamsStats>;
}

export const CommentWithoutInteractions = memo((props: ICommentWithoutInteractionsProps) => {
  const { id, commentAuthor, commentData, favoritePlayer, favoriteTeam } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const overridePlayer: Maybe<IPlayerStats> = useMemo(() => {
    if (favoritePlayer) {
      return {
        ...favoritePlayer,
        fontColor: PlayerFontColor.Light,
      };
    }

    return null;
  }, [favoritePlayer]);

  const userNameClasses = useMemo(
    () =>
      cn(styles.TopSection__UserNick, {
        [styles['TopSection__UserNick--athlete']]: commentAuthor.athlete,
      }),
    [commentAuthor.athlete],
  );

  return (
    <div className={styles.Comment}>
      <div className={styles.Comment__UserAvatar}>
        <Avatar
          size={AvatarSize.M}
          username={commentAuthor.username}
          src={commentAuthor.smallAvatarUrl}
        />
      </div>
      <div className={styles.Comment__Main}>
        <div className={styles.Comment__Body}>
          <div className={styles.Comment__UserInfo}>
            <div className={styles.LeftSide}>
              <div className={styles.TopSection}>
                <div className={userNameClasses}>
                  {!commentAuthor.athlete
                    ? `@${commentAuthor.username}`
                    : commentAuthor.athlete.fullName}
                </div>
                {!!commentAuthor.name && !commentAuthor.athlete && isDesktopPlus && (
                  <div className={styles.TopSection__UserName}>{commentAuthor.name}</div>
                )}
              </div>
              <TeamPlayerLabel
                isNeedLowOpacity
                team={favoriteTeam}
                player={overridePlayer}
                onTeamClick={props.onTeamClick}
                onPlayerClick={props.onPlayerClick}
              />
            </div>
          </div>
          <div className={styles.Comment__Content}>
            {commentData.comment && (
              <RichTextPreview
                id={id}
                editorState={commentData.comment}
                editorType={RichTextContentTypes.WithReadMore}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
