import { memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';

import { ACCEPT_FILES } from 'configs/controls.config';

import { EditProfileFormDataType } from 'components/edit-profile/edit-profile-form/edit-profile-form.component';
import { AvatarSize } from 'components/ui/avatar/avatar.component';
import { AvatarInput } from 'components/ui/form-fields/avatar-input/avatar-input.component';
import { ProfileThumbnailInput } from 'components/ui/form-fields/profile-thumbnail-input/profile-thumbnail-input.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';

import defaultThumbnail from 'assets/images/svg/user-default-background.svg';

import styles from './user-personal-info.module.less';

interface IUserPersonalInfoProps {
  userMe: Maybe<IUserMe>;
}

export const UserPersonalInfo = memo((props: IUserPersonalInfoProps) => {
  const { userMe } = props;
  const { control, formState } = useFormContext<EditProfileFormDataType>();

  const { errors } = formState;

  const username = useWatch({ name: 'username' });
  const avatar = useWatch({ name: 'avatar' });
  const thumbnail = useWatch({ name: 'thumbnail' });

  return (
    <div className={styles.UserPersonalInfo}>
      <div className={styles.UserPersonalInfo__ImagesWrapper}>
        <Controller
          name="thumbnail"
          control={control}
          render={({ field: { ref, ...restField } }) => (
            <ProfileThumbnailInput
              {...restField}
              thumbnailURL={thumbnail.value ?? defaultThumbnail}
              thumbnailLogoUrl={userMe?.profile.thumbnailLogoUrl ?? ''}
              thumbnailNicknameUrl={userMe?.profile.thumbnailNicknameUrl ?? ''}
              id="thumbnail"
              name="thumbnail"
              accept={ACCEPT_FILES}
              isCropModalVisible
              forEditModal
              disableHover
              value={restField.value.value}
            />
          )}
        />
        <div className={styles.UserPersonalInfo__ImagesWrapper_Avatar}>
          <Controller
            name="avatar"
            control={control}
            render={({ field: { ref, ...restField } }) => (
              <AvatarInput
                {...restField}
                src={avatar.value}
                username={username}
                id="avatar"
                name="avatar"
                accept={ACCEPT_FILES}
                isCropModalVisible
                forEditModal
                avatarSize={AvatarSize.SIZE_144}
                value={restField.value.value}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.UserPersonalInfo__Content}>
        <p className={styles.UserPersonalInfo__Content_SubTitle}>Edit Personal Info</p>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.username?.message}
              id="username"
              label="Username"
              placeholder="Username"
            />
          )}
        />
        <Controller
          name="realname"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.realname?.message}
              id="realname"
              label="Real Name"
              placeholder="Real name"
            />
          )}
        />
      </div>
    </div>
  );
});
