import { cloneElement, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Camera, CameraResultType, CameraSource, GalleryPhotos } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { v4 } from 'uuid';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { base64ToFile } from 'helpers/base64-to-file.util';
import { getOrientationFromBase64 } from 'helpers/get-orientation-from-base64.util';
import { setImageOrientation } from 'helpers/set-image-orientation.util';
import { IFileData } from 'helpers/to-base64.util';

import { useMainProvider } from 'hooks/use-main-provider';

import { PhotoCameraBottomSheet } from 'components/bottom-sheet/photo-camera-bottom-sheet/photo-camera-bottom-sheet.component';
import { handleIosPhotosDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-photos-denied.util';
import {
  cameraPopupButton,
  cameraPopupMessage,
  cameraPopupTitle,
  photoPopupButton,
  photoPopupMessage,
  photoPopupTitle,
} from 'components/popups/media-permission-popup/config';
import { MediaPermissionPopup } from 'components/popups/media-permission-popup/media-permission-popup.component';

import cameraImage from 'assets/images/svg/camera-image.svg';
import photosImage from 'assets/images/svg/gallery-image.svg';

import { handleIosCameraDenied } from '../photo-camera-field-with-backend-processing/utils/handle-ios-camera-denied.util';

interface IPhotoCameraProps {
  enabledFunction: boolean;
  enabledMultipleSelection?: boolean;
  children: JSX.Element;
  setLoadingImages?: (images: string[]) => void;
}

export const PhotoCameraField = memo((props: IPhotoCameraProps): JSX.Element => {
  const { enabledFunction, enabledMultipleSelection, children, setLoadingImages } = props;

  const { isNativeAndroidApp } = useMainProvider();

  const [isCameraPermissionPopupOpen, setIsCameraPermissionPopupOpen] = useState(false);
  const [isPhotoPermissionPopupOpen, setIsPhotoPermissionPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileInstance, setFileInstance] = useState<File>();
  const [imageFiles, setImageFiles] = useState<IFileData[]>([]);

  const getFilesystemData = useCallback(async (path: string): Promise<string | Blob> => {
    const content = await Filesystem.readFile({ path });

    return content.data;
  }, []);

  const defineFileDataScheme = useCallback(
    (base64Data: string, type: string = 'jpeg'): IFileData => {
      const dataURL = `data:image/${type};base64,${base64Data}`;
      const hash = v4();

      return {
        value: dataURL,
        meta: {
          size: 0,
          type,
          filename: 'Default',
          hash,
        },
      };
    },
    [],
  );

  const retrieveDataFiles = useCallback(
    async ({ photos }: GalleryPhotos): Promise<IFileData[]> => {
      const uploadedFiles: IFileData[] = [];

      setIsModalOpen(false);

      if (setLoadingImages) {
        const loaders: string[] = photos.map((photo) => photo.webPath);

        setLoadingImages(loaders);
      }

      await Promise.allSettled(
        photos.map(async (photo) => {
          if (photo.path) {
            const fileSystemData = await getFilesystemData(photo.path);

            if (typeof fileSystemData === 'string') {
              const dataFile = defineFileDataScheme(fileSystemData, photo.format);
              uploadedFiles.push(dataFile);
            }
          }
        }),
      );

      return uploadedFiles;
    },
    [getFilesystemData, defineFileDataScheme, setLoadingImages],
  );

  const handleSelectFromLibrary = useCallback(async () => {
    if (enabledMultipleSelection) {
      const photos = await Camera.pickImages({});
      const files = await retrieveDataFiles(photos);

      if (setLoadingImages) {
        setLoadingImages([]);
      }

      setImageFiles(files);

      return;
    }

    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    });

    const { base64String, format } = photo;

    if (base64String) {
      const fileData = defineFileDataScheme(base64String, format);
      const fileInstanse = base64ToFile(base64String, format);

      setImageFiles([fileData]);
      setFileInstance(fileInstanse);
      setIsModalOpen(false);
    }
  }, [enabledMultipleSelection, retrieveDataFiles, defineFileDataScheme, setLoadingImages]);

  const handleTakePicture = useCallback(async () => {
    const photo = await Camera.getPhoto({
      allowEditing: false,
      correctOrientation: true,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      presentationStyle: 'fullscreen',
      source: CameraSource.Camera,
    });

    const { base64String, format } = photo;

    setIsModalOpen(false);

    if (setLoadingImages) {
      setLoadingImages([`Default.${format}`]);
    }

    if (base64String) {
      getOrientationFromBase64(base64String, format, (orientation: number) => {
        setImageOrientation(base64String, format, orientation, (base64Result: string) => {
          const fileData = defineFileDataScheme(base64Result, format);
          const fileInstanse = base64ToFile(base64Result, format);

          setImageFiles([fileData]);
          setFileInstance(fileInstanse);

          if (setLoadingImages) {
            setLoadingImages([]);
          }
        });
      });
    }
  }, [defineFileDataScheme, setLoadingImages]);

  const handleAllowCameraPermissions = useCallback(async () => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.PermissionsCameraAccessAllow,
      },
    });

    setIsCameraPermissionPopupOpen(false);

    const permission = await Camera.checkPermissions();

    if (permission.camera === 'denied' && !isNativeAndroidApp) {
      await handleIosCameraDenied();
    } else {
      await handleTakePicture();
    }
  }, [handleTakePicture, isNativeAndroidApp]);

  const handleAllowPhotoPermissions = useCallback(async () => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.PermissionsPhotoLibraryAllow,
      },
    });
    setIsPhotoPermissionPopupOpen(false);
    const permission = await Camera.checkPermissions();

    if (permission.photos === 'denied' && !isNativeAndroidApp) {
      await handleIosPhotosDenied();
    } else {
      await handleSelectFromLibrary();
    }
  }, [handleSelectFromLibrary, isNativeAndroidApp]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCheckPhotoPermissions = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      const permission = await Camera.checkPermissions();

      if (permission.photos !== 'granted' && permission.photos !== 'limited') {
        setIsPhotoPermissionPopupOpen(true);
        handleClose();
      } else {
        handleClose();
        setIsPhotoPermissionPopupOpen(false);
        await handleSelectFromLibrary();
      }
    },
    [handleSelectFromLibrary, handleClose],
  );

  const handleCheckCameraPermissions = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      const permission = await Camera.checkPermissions();

      if (permission.camera !== 'granted' && permission.camera !== 'limited') {
        setIsCameraPermissionPopupOpen(true);
        handleClose();
      } else {
        handleClose();
        setIsCameraPermissionPopupOpen(false);
        await handleTakePicture();
      }
    },
    [handleTakePicture, handleClose],
  );

  const handleOpenBottomSheet = useCallback(
    (event: SyntheticEvent) => {
      if (enabledFunction) {
        event.preventDefault();
        setIsModalOpen(true);
      }
    },
    [enabledFunction],
  );

  useEffect(() => {
    if (isCameraPermissionPopupOpen) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.PermissionsCameraAccess,
        },
      });
    }
  }, [isCameraPermissionPopupOpen]);

  useEffect(() => {
    if (isPhotoPermissionPopupOpen) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.PermissionsPhotoLibrary,
        },
      });
    }
  }, [isPhotoPermissionPopupOpen]);

  if (!enabledFunction) {
    return children;
  }

  return (
    <>
      {cloneElement(children, {
        onClick: handleOpenBottomSheet,
        imageFiles,
        fileInstance,
      })}
      <PhotoCameraBottomSheet
        visible={isModalOpen}
        onSelectFromLibrary={handleCheckPhotoPermissions}
        onTakePicture={handleCheckCameraPermissions}
        onClose={handleClose}
      />
      {/* this is camera access popup */}
      <MediaPermissionPopup
        visible={isCameraPermissionPopupOpen}
        title={cameraPopupTitle}
        message={cameraPopupMessage}
        primaryButtonText={cameraPopupButton}
        imageUrl={cameraImage}
        onApprove={handleAllowCameraPermissions}
      />
      {/* this is photos access popup */}
      <MediaPermissionPopup
        visible={isPhotoPermissionPopupOpen}
        title={photoPopupTitle}
        message={photoPopupMessage}
        primaryButtonText={photoPopupButton}
        imageUrl={photosImage}
        onApprove={handleAllowPhotoPermissions}
      />
    </>
  );
});
