import { FC, useCallback, useMemo } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import cn from 'classnames';
import { IUserAchievement } from 'query-hooks/reputation/interfaces/user-achievements.interface';
import { getAchievementFrame } from 'query-hooks/reputation/utils/get-achievement-frame.util';

import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { IUserPublic } from 'stores/user-public/interfaces/user-public.interface';

import { SelectTeamAndPlayerContainer } from 'containers/select-team-and-player/select-team-and-player.container';
import { SocialLinksContainer } from 'containers/social-links/social-links.container';

import { BASE_URL } from 'configs/environment.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './profile-info.module.less';

interface IProfileInfoProps {
  userData: IUserPublic | null;
  isAuthUserProfile: boolean;
  isMyPage: boolean;
  rating: number;
  topAchievement: Maybe<IUserAchievement>;
  onEditProfilePress: () => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
}

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

export const ProfileInfo: FC<IProfileInfoProps> = (props: IProfileInfoProps) => {
  const { userData, topAchievement, isAuthUserProfile, onOpenPreview, isMyPage, rating } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleShare = useCallback(async () => {
    if (userData?.username) {
      const href = `${BASE_URL}/${path.USERS_PART}/${userData.username}/${path.PROFILE_PART}`;

      await Clipboard.write({
        string: href,
      });
    }
  }, [userData?.username]);

  const handleOpenPreview = useCallback(() => {
    if (!userData?.avatarUrl) {
      return;
    }

    const image = getPreviewImage(
      userData.avatarUrl,
      `${userData.username.toLowerCase()}-logo`,
      userData.smallAvatarUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, userData]);

  const profileDetailsClasses = useMemo(() => {
    return cn(styles.ProfileInfo__Details, {
      [styles.ProfileInfo__Details__HasAvatarFrame]: topAchievement?.achievementLevel?.level,
    });
  }, [topAchievement]);

  const achievementFrame = useMemo(() => {
    if (!topAchievement?.achievementLevel?.level) {
      return '';
    }

    return getAchievementFrame(topAchievement.achievementLevel.level, topAchievement.status);
  }, [topAchievement]);

  if (!userData) {
    return null;
  }

  return (
    <div className={styles.ProfileInfo}>
      <div className={styles.ProfileInfo__HeaderThumbnail}>
        <img
          src={userData?.thumbnailUrl || DEFAULT_THUMBNAIL_URL}
          alt="thumbnail background"
          className={styles.ProfileInfo__Background}
        />
      </div>
      <div className={styles.ProfileInfo__Content}>
        {!userData.athlete ? (
          <div className={styles.ProfileInfo__Content_Left}>
            <div className={styles.ProfileInfo__Points}>
              <IconButton
                iconSize={IconFontSize.ExtraSmall}
                iconName={IconFontName.StarFilled}
                theme={IconButtonTheme.Secondary}
              />
              {!!rating && <span className={styles.ProfileInfo__Rating}>{rating}</span>}
            </div>
            {isDesktopPlus && <SelectTeamAndPlayerContainer />}
          </div>
        ) : (
          <div />
        )}
        <div
          role="button"
          tabIndex={0}
          className={styles.ProfileInfo__AvatarWrapper}
          onKeyDown={handleOpenPreview}
          onClick={handleOpenPreview}
        >
          <div className={styles.ProfileInfo__Avatar}>
            {achievementFrame && (
              <img
                className={styles.ProfileInfo__Avatar__Frame}
                src={achievementFrame}
                alt="avatar frame"
              />
            )}
            <Avatar
              username={userData.username}
              size={AvatarSize.SIZE_144}
              src={userData.avatarUrl}
            />
          </div>
        </div>
        <div className={styles.ProfileInfo__Content_Right}>
          {isDesktopPlus && <SocialLinksContainer />}
          <TextTooltip
            tooltipOffset={10}
            tooltipDisappearTime={1000}
            eventType={TooltipEventType.click}
            text="Link Copied"
            placement="top"
          >
            <Button
              onClick={handleShare}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
              iconName={IconFontName.Share}
            >
              Share
            </Button>
          </TextTooltip>
        </div>
        {isAuthUserProfile && (
          <div className={styles.ProfileInfo__HeaderButtons}>
            {isMyPage && (
              <Button
                onClick={props.onEditProfilePress}
                theme={ButtonTheme.Tertiary}
                size={ButtonSize.SmallSecondary}
              >
                Edit Profile
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={profileDetailsClasses}>
        <div className={styles.ProfileInfo__Names}>
          <h4>{!userData.athlete ? userData.realName : userData.athlete.fullName}</h4>
          {!userData.athlete && <span>@{userData.username}</span>}
        </div>
        {!isDesktopPlus && <SelectTeamAndPlayerContainer />}
      </div>
    </div>
  );
};
