import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useGetPlayerById } from 'query-hooks/followings/use-get-player-by-id';
import { useGetTeamById } from 'query-hooks/followings/use-get-team-by-id';

import { PostsStore } from 'stores/posts/posts.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useTeamClick } from 'hooks/use-team-click';

import { AuthorCard } from 'components/author-card/author-card.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './author-card.module.less';

export const AuthorCardContainer = observer(() => {
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);

  const navigate = useNavigate();

  const getTeamById = useGetTeamById();
  const getPlayerById = useGetPlayerById();

  const favoriteTeam = getTeamById(postsStore.post?.user.favoriteTeamId || null);

  const favoritePlayer = getPlayerById(postsStore.post?.user.favoritePlayerId || null);

  const sourceUsername = useMemo(() => {
    return postsStore.post?.source?.author?.username || postsStore.post?.user.username || '';
  }, [postsStore.post]);

  const sourceAvatar = useMemo(() => {
    return postsStore.post?.source?.author?.profileImage || postsStore.post?.user.avatarUrl || null;
  }, [postsStore.post]);

  const sourceName = useMemo(() => {
    return postsStore.post?.source?.author?.name || postsStore.post?.user.name || '';
  }, [postsStore.post]);

  const athleteName = useMemo(() => {
    if (!postsStore.post?.user.athlete) {
      return '';
    }

    return postsStore.post.user.athlete.fullName;
  }, [postsStore.post]);

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  if (!postsStore.post) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <AuthorCard
      athleteName={athleteName}
      avatarSrc={sourceAvatar}
      player={favoritePlayer}
      team={favoriteTeam}
      thumbnailURL={postsStore.post.user.smallThumbnailUrl}
      thumbnailLogoURL={postsStore.post.user.thumbnailLogoUrl}
      thumbnailNicknameURL={postsStore.post.user.thumbnailNicknameUrl}
      username={sourceUsername}
      realname={sourceName}
      onTeamClick={handleTeamClick}
      onPlayerClick={handlePlayerClick}
    />
  );
});
