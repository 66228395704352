import { memo, useMemo } from 'react';
import cn from 'classnames';
import { useGetAllFollowQuery } from 'query-hooks/followings/queries/use-get-all-follow.query';
import { useGetSortedFollowings } from 'query-hooks/followings/use-get-sorted-followings';

import { IFollow } from 'services/follow/interfaces/follow.interface';
import { IGame } from 'services/statistics/interface/game.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useResponsive } from 'hooks/use-responsive';

import { Statistics } from 'components/statistics/statistics.component';
import { StatisticsType } from 'components/statistics/statistics-type.enum';
import {
  FollowAvatar,
  FollowAvatarSize,
} from 'components/ui/follow-avatar/follow-avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './shortcut-bar.module.less';

interface IShortcutBarProps {
  isFetchingMiniSchedule: boolean;
  games: IGame[];
  firstGameIds: number[];
  firstGameTodayId?: Maybe<number>;
  onFollowClick: () => void;
  onGameClick: (gameId: number, opponents?: string) => void;
  onFollowedItemClick: (following: IFollow) => () => void;
}

export const ShortcutBar = memo((props: IShortcutBarProps) => {
  const { isFetchingMiniSchedule, games, firstGameIds, firstGameTodayId } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const sortedFollowings = useGetSortedFollowings();

  const { isFetching: isFetchingFollow } = useGetAllFollowQuery();

  const shortcutBarClasses = useMemo(
    () =>
      cn(styles.ShortcutBar, TOUCH_IGNORE_CLASS, {
        [styles['ShortcutBar--freezed']]: !sortedFollowings.length,
      }),
    [sortedFollowings],
  );

  return (
    <div className={shortcutBarClasses}>
      <div role="presentation" className={styles.ShortcutBarGames}>
        {isFetchingMiniSchedule ? (
          <Loader isShow />
        ) : (
          <Statistics
            expanded
            type={StatisticsType.ShortcutBar}
            games={games}
            firstGameIds={firstGameIds}
            firstGameTodayId={firstGameTodayId}
            onGameClick={props.onGameClick}
          />
        )}
      </div>
      {!isDesktopPlus ? (
        <div role="presentation" className={styles.ShortcutBarFavorites}>
          <button className={styles.ShortcutBarFavorites__Item} onClick={props.onFollowClick}>
            <div className={styles.ShortcutBarFavorites__Button}>
              <IconFont name={IconFontName.Add} size={IconFontSize.Big} />
            </div>
          </button>
          {isFetchingFollow && !sortedFollowings.length ? (
            <Loader isLocal isShow />
          ) : (
            sortedFollowings.map((item) => (
              <button
                key={item.id}
                className={styles.ShortcutBarFavorites__Item}
                onClick={props.onFollowedItemClick(item)}
              >
                <FollowAvatar
                  size={FollowAvatarSize.SIZE_72}
                  altText={`${item.firstName} ${item.lastName}`}
                  src={item.thumbnailUrl}
                  isActive={item.hasNewPosts}
                />
              </button>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
});

ShortcutBar.displayName = 'ShortcutBar';
