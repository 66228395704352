import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';
import { IAttachmentsCount, IPollOption } from 'stores/posts/interfaces/post.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import { SharePoll } from './share-poll/share-poll.component';

import styles from './share-modal.module.less';

export interface IAuthor {
  username: string;
  name?: string;
  smallAvatarUrl: Maybe<string>;
  athlete: Maybe<IAthlete>;
}

interface IShareContent {
  title?: Maybe<string>;
  dateCreated?: string;
  content?: Maybe<string>;
  attachments: IAttachmentsCount;
  pollOptions?: IPollOption[];
}

export interface IShareModalProps extends IModal {
  shareContent: IShareContent;
  author: IAuthor;
  id: string;
  onCopyLink: () => void;
}

export const ShareModal: FC<IShareModalProps> = (props: IShareModalProps) => {
  const { id, visible, author, shareContent, onCopyLink } = props;

  const hasIcons = useMemo(
    () => shareContent.attachments.mediaCount > 0 || shareContent.attachments.filesCount > 0,
    [shareContent.attachments],
  );

  const handleCopyLink = useCallback(onCopyLink, [onCopyLink]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const sharedContentClassNames = useMemo(
    () =>
      cn(styles.SharedContent, {
        [styles['SharedContent--two-lines']]: !hasIcons,
        [styles['SharedContent--three-lines']]: !shareContent.title,
      }),
    [hasIcons, shareContent.title],
  );

  const userNameClasses = useMemo(
    () =>
      cn(styles.ModalContent__Author__Username, {
        [styles['ModalContent__Author__Username--athlete']]: author.athlete,
      }),
    [author.athlete],
  );

  return (
    <BaseModalComponent
      headerButton={{
        icon: IconFontName.Link,
        text: 'Copy Link',
        onClick: handleCopyLink,
        tooltip: {
          eventType: TooltipEventType.click,
          placement: 'top',
          text: 'Link Copied',
          disappearTime: 500,
        },
      }}
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title="Share"
      onClose={props.onClose}
    >
      <div className={styles.ModalContentWrapper}>
        <div className={styles.ModalContent}>
          <div className={styles.ModalContent__Author}>
            <Avatar size={AvatarSize.XS} src={author.smallAvatarUrl} username={author.username} />
            <span className={userNameClasses}>
              {!author.athlete ? `@${author.username}` : author.athlete.fullName}
            </span>
            {author.name && !author.athlete && (
              <span className={styles.ModalContent__Author__Realname}>{author.name}</span>
            )}
            {isDesktopPlus && (
              <span className={styles.ModalContent__Author__Time}>{shareContent.dateCreated}</span>
            )}
          </div>
          <div className={sharedContentClassNames}>
            {shareContent.title && (
              <span className={styles.SharedContent__Title}>{shareContent.title}</span>
            )}
            <div>
              {shareContent.content && (
                <RichTextPreview
                  id={id}
                  editorState={shareContent.content}
                  editorType={RichTextContentTypes.FullHeight}
                />
              )}
            </div>
          </div>
          {shareContent.pollOptions && <SharePoll options={shareContent.pollOptions} />}
          {hasIcons && (
            <div className={styles.SharedContentAttachments}>
              {shareContent.attachments.mediaCount > 0 && (
                <div className={styles.SharedContentAttachments__Media}>
                  <IconFont name={IconFontName.MediaFiles} size={IconFontSize.Small} />
                  <span>{shareContent.attachments.mediaCount} Media</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </BaseModalComponent>
  );
};
