import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useGetPlayerById } from 'query-hooks/followings/use-get-player-by-id';
import { useGetTeamById } from 'query-hooks/followings/use-get-team-by-id';

import { IGetCommentPayload } from 'services/comments/interfaces/comments.interface';
import { ITogglePostCommentBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';

import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { IBookmarkUser } from 'stores/bookmarks/interfaces/bookmarks.interface';
import { BookmarksFilterType } from 'stores/bookmarks/types/bookmarks.type';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { CommentsStore } from 'stores/comments/comments.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';

import { Bookmarks } from 'components/bookmarks/bookmarks.component';
import { ISearchFormData } from 'components/forms/search-form/search-form.component';
import { IAuthor, ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import { ISharePublicationData } from 'components/posts/post-detailed/post-detailed.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

interface IBookmarksContainerProps {
  isForSidePanel?: boolean;
  isHeaderVisible?: boolean;
}

export const BookmarksContainer: FC<IBookmarksContainerProps> = observer((props) => {
  const { isForSidePanel = false, isHeaderVisible = true } = props;

  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const postStore = useInjection<PostsStore>(TYPES.PostsStore);
  const commentsStore = useInjection<CommentsStore>(TYPES.CommentsStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsService);
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const { shareByNativeModal } = useNativeShare();

  const [sharePublicationData, setSharePublicationData] =
    useState<Maybe<ISharePublicationData>>(null);

  const handleTogglePostBookmark = useCallback(
    async (id: string) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (!isAuth) return;

      await bookmarksStore.togglePostBookmark({ id });
      await postStore.forceFetchToRefreshFeed();
    },
    [authStore, bookmarksStore, postStore],
  );

  const handleToggleCollaborationItemBookmark = useCallback(
    async (collaborationId: number, collaborationItemId: number) => {
      if (!authStore.isAuthorised) return;

      const isSuccess = await collaborationStore.toggleBookmarkCollaborationItemById(
        collaborationId,
        collaborationItemId,
      );

      if (isSuccess) {
        await bookmarksStore.refresh();
      }
    },
    [authStore, bookmarksStore, collaborationStore],
  );

  const handleTogglePostCommentBookmark = useCallback(
    (payload: ITogglePostCommentBookmarkPayload) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      bookmarksStore.togglePostCommentBookmark(payload);
    },
    [authStore, bookmarksStore],
  );

  const handleResetFilters = useCallback(async () => {
    await bookmarksStore.resetFilters();
  }, [bookmarksStore]);

  const handleApplyButtonClick = useCallback(
    async (
      sortOrder: string,
      bookmarksTypes: BookmarksFilterType,
      selectedUsers: Array<IBookmarkUser>,
    ) => {
      await bookmarksStore.applyFilters(sortOrder, bookmarksTypes, selectedUsers);
    },
    [bookmarksStore],
  );

  const handleCloseBookmarks = useCallback(() => {
    layoutStore.setSidePanelActiveTab(null);
  }, [layoutStore]);

  const handleSetSearch = useCallback(
    (value: ISearchFormData) => {
      bookmarksStore.setSearchValue(value);
    },
    [bookmarksStore],
  );

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (isAuth) {
        await postStore.postPollVote(pollData);
        await bookmarksStore.refresh();
      }
    },
    [authStore, postStore, bookmarksStore],
  );

  const handlePollUpdate = useCallback(
    async (pollData: IPollUpdateData) => {
      if (!authStore.isAuthorised) {
        return;
      }

      await postStore.updatePoll(pollData);
      await bookmarksStore.refresh();
    },
    [authStore, postStore, bookmarksStore],
  );

  const handlePollExpired = useCallback(() => {}, []);

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (payload: ISharePayload) => {
      await shareStore.fetchSharePublicationData(payload.postId, payload.commentId);

      if (
        isNativeApp &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment)
      ) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        setSharePublicationData(payload.publication || null);
        shareStore.setIsShareModalVisible(true);
      }
    },
    [isNativeApp, shareStore, metaTagsStore.metaTags, shareByNativeModal],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  useEffect(() => {
    (async () => {
      const isAuth = authStore.isAuthorised;
      if (isAuth && authStore.session) {
        await bookmarksStore.initialise();
      }
    })();
  }, [bookmarksStore, authStore, authStore.session]);

  const isOpen = useMemo(() => {
    return !(isForSidePanel && layoutStore.sidePanelActiveTab !== 'bookmarked');
  }, [layoutStore.sidePanelActiveTab, isForSidePanel]);

  const handleLoadMore = useCallback(async () => {
    await bookmarksStore.fetchNext();
  }, [bookmarksStore]);

  const handleOpenComment = useCallback(
    async (payload: IGetCommentPayload) => {
      await commentsStore.openBookmarkedComment(payload);
    },
    [commentsStore],
  );

  const handleOpenPreview = useCallback(
    (
      author: IAuthor,
      slides: IInteractiveMediaAttachment[],
      date: string,
      index: number,
      team: Maybe<ITeamsStats>,
    ) => {
      const watermarkEntity = team ? { name: team.nickname, logoUrl: team.mediumLogoUrl } : null;

      galleryStore.setCurrentAttachmentId(index);
      galleryStore.setGalleryAttachments(slides);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore],
  );
  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  useEffect(() => {
    if (!isDesktopPlus && !bookmarksStore.isFilterOpen) {
      layoutStore.setSidePanelActiveTab(null);
    }
  }, [bookmarksStore.isFilterOpen, layoutStore, isDesktopPlus]);

  const getTeamById = useGetTeamById();
  const getPlayerById = useGetPlayerById();

  return (
    <>
      <Bookmarks
        getPlayerById={getPlayerById}
        getTeamById={getTeamById}
        isHeaderVisible={isHeaderVisible}
        feedsTheme={PostCardFeedsTheme.Collapse}
        activeTab={layoutStore.sidePanelActiveTab}
        isLoading={bookmarksStore.fetching}
        hasMoreBookmarks={!bookmarksStore.fetching && !bookmarksStore.isLastPage}
        setSidePanelActiveTab={layoutStore.setSidePanelActiveTab}
        setIsFilterOpen={bookmarksStore.setIsFilterOpen}
        isFilterOpen={bookmarksStore.isFilterOpen}
        isForSidePanel={isForSidePanel}
        userId={authStore?.userMe?.id || null}
        isAthlete={authStore.isAthlete}
        users={bookmarksStore.users}
        types={bookmarksStore.types}
        sortBy={bookmarksStore.sortBy}
        bookmarks={bookmarksStore.entries}
        searchValue={bookmarksStore.searchValue}
        isOpenModule={isOpen}
        selectedBookmarksUsers={bookmarksStore.selectedBookmarksUsers}
        onOpenComment={handleOpenComment}
        onLoadMore={handleLoadMore}
        onResetFilters={handleResetFilters}
        onSetSearchValue={handleSetSearch}
        onCloseBookmarks={handleCloseBookmarks}
        onApplyButtonClick={handleApplyButtonClick}
        onTogglePostBookmark={handleTogglePostBookmark}
        onShareClick={handleShowShareModal}
        onToggleCommentBookmark={handleTogglePostCommentBookmark}
        onToggleCollaborationItemBookmark={handleToggleCollaborationItemBookmark}
        onPollVote={handlePollVote}
        onPollUpdate={handlePollUpdate}
        onPollExpired={handlePollExpired}
        onOpenPreview={handleOpenPreview}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
      />
      {!isNativeApp &&
        sharePublicationData?.uuid &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment) && (
          <ShareModal
            id={sharePublicationData.uuid}
            author={sharePublicationData.user}
            onClose={handleResetSharing}
            shareContent={{
              pollOptions: shareStore.shareData.pollOptions,
              attachments: shareStore.shareData.attachmentCounts,
              dateCreated: sharePublicationData.formattedDates.relativeLong,
              content: shareStore.shareData.content,
              title: shareStore.shareData.title,
            }}
            onCopyLink={handleCopySharedLink}
            visible={shareStore.isShareModalVisible}
          />
        )}
    </>
  );
});
