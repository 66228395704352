import { z } from 'zod';

import { LinkAction, LinkType } from 'stores/auth/interfaces/user-public-link.interface';

import {
  MAX_LINK_DESCRIPTION_LENGTH,
  MAX_LINK_TITLE_LENGTH,
  MAX_NAME_LENGTH,
  MIN_LINK_TITLE_LENGTH,
  MIN_LINK_URL_LENGTH,
  MIN_NAME_LENGTH,
} from 'validation/validation.constants';

const linkTLDRegex =
  /^((https?:\/\/)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,})(?::\d+)?(\/[a-zA-Z0-9._~\-/]*)?(\?[a-zA-Z0-9=&._~%-]*)?(#[a-zA-Z0-9-]*)?$/;

export const linkTitleSchema = z
  .string({ required_error: 'Title is required' })
  .max(MAX_NAME_LENGTH, `Max title length is ${MAX_LINK_TITLE_LENGTH}`)
  .min(MIN_NAME_LENGTH, `Min title length is ${MIN_LINK_TITLE_LENGTH}`);

export const linkDescriptionSchema = z
  .string()
  .max(MAX_NAME_LENGTH, `Max description length is ${MAX_LINK_DESCRIPTION_LENGTH}`)
  .nullable()
  .optional();

export const linkUrlSchema = z
  .string({ required_error: 'Link is required' })
  .trim()
  .min(MIN_LINK_URL_LENGTH, `Min name length is ${MIN_LINK_URL_LENGTH}`)
  .regex(linkTLDRegex, { message: 'Valid Link Required' });

export const socialLinkSchema = z.object({
  url: z
    .string()
    .trim()
    .refine((val) => !val || z.string().trim().regex(linkTLDRegex).safeParse(val).success, {
      message: 'Valid Link Required',
    }),
  action: z.nativeEnum(LinkAction).optional(),
  id: z.number().optional(),
});

export const customLinksSchema = z.array(
  z.object({
    id: z.number(),
    url: linkUrlSchema,
    title: linkTitleSchema,
    description: linkDescriptionSchema,
    type: z.nativeEnum(LinkType),
    action: z.nativeEnum(LinkAction).optional(),
  }),
);
