import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { PollStatusEnum } from 'services/posts/enums/post.enum';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';

import { useInterval } from 'hooks/use-interval';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { HandlePollQuote } from 'components/ui/poll/handle-poll-quote/handle-poll-quote.component';
import { PollAttachments } from 'components/ui/poll/poll-attachments/poll-attachments.component';
import { PollManagement } from 'components/ui/poll/poll-management/poll-management.component';
import { PollQuote } from 'components/ui/poll/poll-quote/poll-quote.component';
import { getTimeLeft } from 'components/ui/poll/utils/get-time-left.util';
import { indexToLetter } from 'components/ui/poll/utils/index-to-letter.util';

import {
  IPollAnswer,
  IPollUpdateData,
  IPollVoteData,
  IStopPollType,
} from './interfaces/poll.interface';
import { PollItem, PollTheme } from './poll-item/poll-item.component';

import styles from './poll.module.less';

export interface IPollProps {
  theme?: PollTheme;
  votesTotal: number;
  answers: IPollAnswer[];
  answerId: Maybe<string>;
  pollId: string;
  status: PollStatusEnum;
  parentEntityId: string;
  entityId: string;
  expirationTimestamp: number;
  quoteContent?: Maybe<string>;
  isMyPoll?: boolean;
  athlete?: Maybe<IAthlete>;
  onPollAnswer?: (voteData: IPollVoteData) => void;
  onPollUpdate?: (pollData: IPollUpdateData) => void;
  onPollExpired?: (pollId: string, commentId: Maybe<string>) => void;
  onPollAttachmentClick: (uuid: string) => void;
}

export const Poll = memo((props: IPollProps) => {
  const {
    theme = PollTheme.Post,
    entityId,
    votesTotal,
    answers,
    answerId,
    pollId,
    status,
    parentEntityId,
    expirationTimestamp,
    quoteContent,
    isMyPoll,
    athlete,
    onPollAnswer,
    onPollExpired,
    onPollAttachmentClick,
    onPollUpdate,
  } = props;

  const [isPollExpired, setIsPollExpired] = useState(false);
  const [isQuoteHandlerVisible, setIsQuoteHandlerVisible] = useState(false);

  const isPollOpen = status === PollStatusEnum.Open;

  const hasAttachments = useMemo(() => {
    const attachments = answers.filter((answer) => answer.attachmentImage);

    return attachments.length > 0;
  }, [answers]);

  const handleAnswerClick = useCallback(
    (id: string) => {
      if (!isPollExpired && !answerId && !isMyPoll) {
        onPollAnswer?.({ pollId, answerId: id, entityId });
      }
    },
    [isMyPoll, answerId, pollId, isPollExpired, onPollAnswer, entityId],
  );

  useEffect(() => {
    if (isPollExpired && isPollOpen && !isMyPoll) {
      onPollExpired?.(parentEntityId, entityId);
    }
  }, [isPollExpired, parentEntityId, isPollOpen, onPollExpired, entityId, isMyPoll]);

  useEffect(() => {
    setIsPollExpired(status === PollStatusEnum.Closed);
  }, [status]);

  const handleStopPoll = useCallback(
    async (type: IStopPollType) => {
      if (!onPollUpdate) {
        return;
      }

      await onPollUpdate({
        pollId,
        stopPollType: type,
      });
    },
    [onPollUpdate, pollId],
  );

  const handlePollQuoteUpdate = useCallback(
    async (content: string) => {
      if (!onPollUpdate) {
        return;
      }

      await onPollUpdate({
        pollId,
        quoteContent: content,
      });

      setIsQuoteHandlerVisible(false);
    },
    [onPollUpdate, pollId, setIsQuoteHandlerVisible],
  );

  useInterval(
    () => {
      const currentTime = new Date().getTime();

      if (expirationTimestamp <= currentTime && isPollOpen) {
        setIsPollExpired(true);
      }
    },
    isPollOpen && !isPollExpired ? 1000 : null,
  );

  const { timeLeft, displayText } = getTimeLeft(expirationTimestamp);

  return (
    <div className={styles.Poll}>
      {quoteContent && (
        <PollQuote
          quoteContent={quoteContent}
          athlete={athlete}
          isEditAllowed={!!isMyPoll}
          onEditClick={() => setIsQuoteHandlerVisible(true)}
        />
      )}
      {hasAttachments && (
        <PollAttachments
          answers={answers}
          userAnswerId={answerId}
          handlePollAttachmentClick={onPollAttachmentClick}
        />
      )}
      {answers.map((answer, index) => {
        const numberMarker = hasAttachments ? indexToLetter(index) : undefined;

        return (
          <div key={answer.uuid} className={styles.Poll__Wrapper}>
            <PollItem
              theme={theme}
              id={answer.uuid}
              answer={answer.title}
              numberMarker={numberMarker}
              percentage={answer.percentage || 0}
              votesCount={answer.votesTotal}
              userAnswerId={answerId}
              status={status}
              expired={isPollExpired}
              onAnswerClick={handleAnswerClick}
              isMyPoll={isMyPoll}
            />
          </div>
        );
      })}
      <div className={styles.Poll__Footer}>
        <span>{votesTotal} answers</span>
        <div>
          <span>
            {isPollExpired ? 'Finished' : `less than ${timeLeft + 1} ${displayText} left`}
          </span>
          {isMyPoll && !isPollExpired && (
            <>
              <div className={styles.Poll__Footer__Divider} />
              <PollManagement
                expirationTimestamp={expirationTimestamp}
                onStopPoll={handleStopPoll}
              />
            </>
          )}
        </div>
      </div>
      {isPollExpired && isMyPoll && (
        <>
          {!quoteContent && (
            <div className={styles.Poll__AddComment}>
              <Button
                onClick={() => setIsQuoteHandlerVisible(true)}
                size={ButtonSize.Big}
                theme={ButtonTheme.Primary}
                iconName={IconFontName.Comment}
                fluid
              >
                Add Comment
              </Button>
            </div>
          )}
          {isQuoteHandlerVisible && (
            <HandlePollQuote
              onClose={() => setIsQuoteHandlerVisible(false)}
              onPollQuoteUpdate={handlePollQuoteUpdate}
              placeholder={quoteContent ? 'Edit comment' : 'Add comment'}
              title={quoteContent ? 'Edit comment' : 'Add comment'}
              initialValues={
                quoteContent
                  ? { editor: { content: { value: quoteContent, length: 0 } } }
                  : undefined
              }
            />
          )}
        </>
      )}
    </div>
  );
});
