import { memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './follow-avatar.module.less';

export enum FollowAvatarSize {
  SIZE_72 = 'SIZE_72',
  SIZE_64 = 'SIZE_64',
  SIZE_54 = 'SIZE_54',
}

interface IFollowAvatarProps {
  src: string;
  size: FollowAvatarSize;
  isActive: boolean;
  altText: string;
}

export const FollowAvatar = memo((props: IFollowAvatarProps) => {
  const { size, src, isActive, altText } = props;

  const avatarImageClassNames = useMemo(
    () =>
      cn(styles.AvatarImage, {
        [styles['AvatarImage--size-72']]: size === FollowAvatarSize.SIZE_72,
        [styles['AvatarImage--size-64']]: size === FollowAvatarSize.SIZE_64,
        [styles['AvatarImage--size-54']]: size === FollowAvatarSize.SIZE_54,
      }),
    [size],
  );
  const avatarBorderClassNames = useMemo(
    () =>
      cn(styles.AvatarBorder, {
        [styles['AvatarBorder--active']]: isActive,
      }),
    [isActive],
  );

  return (
    <div className={avatarBorderClassNames}>
      <div className={styles.AvatarBorderInner}>
        <div className={styles.AvatarBorderCore}>
          <img className={avatarImageClassNames} src={src} alt={altText} />
        </div>
      </div>
    </div>
  );
});
