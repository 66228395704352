import { useCallback, useMemo, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useGetPlayerById } from 'query-hooks/followings/use-get-player-by-id';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';
import { useGetAllTeamsQuery } from 'query-hooks/teams/queries/use-get-all-teams.query';

import { AuthStore } from 'stores/auth/auth.store';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStore } from 'stores/teams/teams.store';

import { JoinTeamContainer } from 'containers/join-team/join-team.container';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { NULL_PLAYER_OPTION_COLOR } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { preparePlayersSelectOptions } from 'helpers/prepare-players-select-options.utils';
import { prepareTeamsSelectOptions } from 'helpers/prepare-teams-select-options.utils';

import { SelectTeamAndPlayer } from 'components/forms/select-team-and-player/select-team-and-player-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IInitialTeamAndPlayer } from 'components/user-details/reputation-box-item/interfaces/initial-team-and-player.interface';

import styles from './feed-actions.module.less';

export const FeedActionsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);

  const userData = authStore.userMe;
  const { data: players = [] } = useGetAllPlayersQuery();
  const { data: teams = [] } = useGetAllTeamsQuery();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const playersSelectOptions: IPlayerOption[] = useMemo(() => {
    return preparePlayersSelectOptions(players);
  }, [players]);

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams) => {
      setIsUpdatingUser(true);
      await authStore.updateUserMe(updateUserParams);
      setIsUpdatingUser(false);
    },
    [authStore],
  );

  const favTeamId = authStore.userMe?.profile.favoriteTeamId || null;
  const favPlayerId = authStore.userMe?.profile.favoritePlayerId || null;

  const getPlayerById = useGetPlayerById();

  const favoritePlayer = getPlayerById(favPlayerId);

  const teamsThumbnails = useMemo(() => {
    return teams.map((team) => team.mediumThumbnailUrl);
  }, [teams]);

  const handleSubmitTeamAndPlayer = useCallback(
    async (data: IInitialTeamAndPlayer) => {
      const payload = {
        favoriteTeamId: data.teamId,
        favoritePlayerId: data.playerId,
      };

      if (
        data.teamId &&
        userData?.profile.thumbnailUrl &&
        !teamsThumbnails.includes(userData.profile.thumbnailUrl)
      ) {
        authStore.setShouldApplyFavoriteTeamBackground(false);

        if (data.teamId === userData?.profile?.favoriteTeamId) {
          setConfirmationModalOpen(false);
        } else {
          setConfirmationModalOpen(true);
        }
      }

      await handleUpdateUser(payload);
      await postsStore.forceFetchToRefreshFeed();
    },
    [userData, teamsThumbnails, authStore, postsStore, handleUpdateUser],
  );

  const initialTeamAndPlayer = useMemo(() => {
    const teamAndPlayer: IInitialTeamAndPlayer = {};
    if (favTeamId) {
      teamAndPlayer.teamId = favTeamId;
    }
    if (favPlayerId) {
      teamAndPlayer.playerId = favPlayerId;
    }

    return teamAndPlayer;
  }, [favTeamId, favPlayerId]);

  const memoizedInitialPlayerColor = useMemo(() => {
    if (favoritePlayer) {
      return favoritePlayer.color;
    }

    return NULL_PLAYER_OPTION_COLOR;
  }, [favoritePlayer]);

  const loading = useMemo(
    () => (authStore.fetching && isUpdatingUser) || teamsStore.fetching,
    [authStore.fetching, teamsStore.fetching, isUpdatingUser],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmationSuccess = useCallback(() => {
    authStore.setShouldApplyFavoriteTeamBackground(true);
    setConfirmationModalOpen(false);
    authStore.updateUserMe({ favoriteTeamId: favTeamId });
  }, [authStore, favTeamId]);

  const teamsSelectOptions: ITeamOption[] = useMemo(() => {
    return prepareTeamsSelectOptions(teams, 'Team', 'Team');
  }, [teams]);

  return (
    <div className={styles.HomeFeedActions}>
      {loading ? (
        <Loader isShow={loading} withBackground />
      ) : (
        <>
          {authStore.isAuthorised &&
          (!userData || !favTeamId || !favPlayerId) &&
          !authStore.isAthlete ? (
            <>
              <SelectTeamAndPlayer
                teamsOptions={teamsSelectOptions}
                playersOptions={playersSelectOptions}
                initialValues={initialTeamAndPlayer}
                initialFavPlayerColor={memoizedInitialPlayerColor}
                onSubmit={handleSubmitTeamAndPlayer}
                setDisabledScroll={layoutStore.setDisabledScroll}
              />
              <ConfirmationModal
                onClose={handleCloseConfirmationModal}
                visible={confirmationModalOpen}
                onSuccessCallback={handleConfirmationSuccess}
                title="Update Background"
                content="Would you like to change your custom background to the team's related one?"
                primaryButtonText="Yes"
                secondaryButtonText="No"
              />
            </>
          ) : null}
          <JoinTeamContainer />
        </>
      )}
    </div>
  );
});
