import { useCallback, useEffect, useState } from 'react';
import { json, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useGetPlayerById } from 'query-hooks/followings/use-get-player-by-id';
import { useGetTeamById } from 'query-hooks/followings/use-get-team-by-id';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { ICollaborationItemPayload } from 'services/collaboration/interfaces/collaboration.interface';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { SortEnum } from 'stores/posts/posts.config';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TopLevelCommentsContainer } from 'containers/top-level-comments/top-level-comments.container';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { checkIsUserStaff } from 'helpers/check-is-user-staff.util';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';

import { BarActionClick } from 'components/bars/bar-action.type';
import { CollaborationItemsList } from 'components/collaboration-section/collaboration-images-list/collaboration-images-list';
import { IAuthor, ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import {
  ISharePublicationData,
  PostDetailed,
} from 'components/posts/post-detailed/post-detailed.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { ITab } from 'components/ui/tabs/tabs.component';

import styles from './post-detailed.module.less';

export type CommentsTabsType = ITab<BarActionClick<SortEnum>>[];

export const PostDetailedContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsService);
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);

  const queryClient = useQueryClient();

  const params = useParams<{
    [paths.POST_ID_PARAM]: string;
  }>();

  const { postId } = params;

  const navigate = useNavigate();
  const location = useLocation();

  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();

  const [sharePublicationData, setSharePublicationData] =
    useState<Maybe<ISharePublicationData>>(null);

  const fetchPost = useCallback(async () => {
    if (postId) {
      await postsStore.fetchRegularPostById({
        postId,
      });
    }
  }, [postId, postsStore]);

  useEffect(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

    topElement?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      fetchPost();
    }
  }, [layoutStore.isPulledRefresher, fetchPost]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const hasUserID = queryParams.has(paths.USER_ID_PARAM);
    const hasUsername = queryParams.has(paths.USERNAME_PARAM);
    const hasSharedType = queryParams.has(paths.TYPE_PARAM);

    const userID = queryParams.get(paths.USER_ID_PARAM);
    const username = queryParams.get(paths.USERNAME_PARAM);
    const sharedType = queryParams.get(paths.TYPE_PARAM) as Maybe<SharedType>;

    if (hasUserID && hasUsername && hasSharedType) {
      authStore.sendGoogleAnalyticFollowedLink(sharedType, username, userID);
    }
  }, [authStore, location]);

  const handleUpdatePostCommentsCount = useCallback(
    (count: number) => {
      if (postsStore.post) {
        const newPost = {
          ...postsStore.post,
          commentsAmount: count,
        };

        postsStore.setPost(newPost);
      }
    },
    [postsStore],
  );

  const handleTogglePostToBookmark = useCallback(
    async (currentPostId: string) => {
      if (!authStore.isAuthorised) return;

      await postsStore.togglePostBookmark({ id: currentPostId });

      await fetchPost();
      await bookmarksStore.refresh();
    },
    [authStore, postsStore, bookmarksStore, fetchPost],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (comment?: ISharePublicationData) => {
      if (postId) {
        await shareStore.fetchSharePublicationData(postId, comment?.uuid);
      }

      if (postsStore.post && shareStore.shareData?.type === SharedType.Post) {
        postsStore.setPost({ ...postsStore.post, sharesCount: shareStore.shareData.count });
      }

      if (
        isNativeApp &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment)
      ) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        if (comment) {
          setSharePublicationData(comment);
        } else if (postsStore.post) {
          setSharePublicationData({
            user: {
              username: postsStore.post.user.username,
              name: postsStore.post.user.name,
              smallAvatarUrl: postsStore.post.user.smallAvatarUrl,
              athlete: postsStore.post.user.athlete,
            },
            formattedDates: postsStore.post.formattedDates,
          });
        }
        shareStore.setIsShareModalVisible(true);
      }
    },
    [isNativeApp, postId, shareStore, postsStore, metaTagsStore.metaTags, shareByNativeModal],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  useEffect(() => {
    if (postId) {
      postsStore.fetchRegularPostById({
        postId,
      });
    }
  }, [postId, postsStore]);

  useEffect(() => {
    return () => {
      postsStore.setPost(null);
    };
  }, [postsStore]);

  useEffect(() => {
    if (postsStore.isIdInvalid) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw json('Not Found', { status: 404 });
    }
  }, [postsStore.isIdInvalid]);

  useEffect(() => {
    if (postId) {
      postsStore.setCurrentPostId(postId);
    }
  }, [postId, postsStore]);

  const handlePostLike = useCallback(
    async (postIdParam: string): Promise<void> => {
      if (!authStore.isAuthorised) return;

      const newPost = await postsStore.postLikes({
        postId: postIdParam,
      });

      postsStore.setPost(newPost);

      queryClient.invalidateQueries({
        queryKey: reputationQueryKeys.userBalance(authStore.userMe?.username),
      });
    },
    [authStore, postsStore, queryClient],
  );

  const handleDeletePost = useCallback(
    async (id: string) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      const isDeleteSuccess = await postsStore.deletePost({ postId: id });

      await bookmarksStore.refresh();

      if (isDeleteSuccess) {
        if (location.key !== 'default') {
          navigate(-1);
        } else {
          navigate(getPath(paths.HOME_FEED));
        }
      }
    },
    [authStore, postsStore, bookmarksStore, location.key, navigate],
  );

  const handleOpenPreview = useCallback(
    (author: IAuthor, slides: IInteractiveMediaAttachment[], date: string, index: number) => {
      const watermarkEntity = teamsStatsStore.team
        ? { name: teamsStatsStore.team.nickname, logoUrl: teamsStatsStore.team.mediumLogoUrl }
        : null;

      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(index);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore, teamsStatsStore.team],
  );

  const handlePollExpired = useCallback(
    async (id: string) => {
      if (!authStore.isAuthorised) {
        return;
      }

      await postsStore.fetchRegularPostById({
        postId: id,
      });

      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.isAuthorised || !postId) {
        return;
      }

      await postsStore.postPollVote(pollData);

      await postsStore.fetchRegularPostById({
        postId,
      });

      await bookmarksStore.refresh();
    },
    [postId, authStore, bookmarksStore, postsStore],
  );

  const handlePollUpdate = useCallback(
    async (pollData: IPollUpdateData) => {
      if (!authStore.isAuthorised || !postId) {
        return;
      }

      await postsStore.updatePoll(pollData);

      await postsStore.fetchRegularPostById({
        postId,
      });
    },
    [postId, authStore, postsStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRedirectToParentRoute = useCallback(
    (id: string) => {
      if (!postsStore.isSubmittedContentReport) {
        return;
      }

      postsStore.setSubmittedContentReport(false);
      postsStore.removePostFromEntries(id);

      if (location.key === 'default') {
        navigate(paths.HOME_FEED);

        return;
      }

      navigate(-1);
    },
    [location, navigate, postsStore],
  );

  const handleReportSubmit = useCallback(
    (postIdParam: string, reasonId: number) => {
      return postsStore.sendPostReport(postIdParam, reasonId);
    },
    [postsStore],
  );

  const handleLoadMoreCollaborationImages = useCallback(() => {
    if (postsStore.post?.collaboration) {
      collaborationStore.fetchNextCollaborationItems(postsStore.post.collaboration.id);
    }
  }, [collaborationStore, postsStore.post]);

  const handleLike = useCallback(
    async (itemId: number) => {
      if (!authStore.isAuthorised || !postsStore.post?.collaboration) return;

      collaborationStore.toggleLikeCollaborationItemById(postsStore.post?.collaboration.id, itemId);
    },
    [authStore.isAuthorised, collaborationStore, postsStore.post?.collaboration],
  );

  const handleCreateCollaborationItem = useCallback(
    async (payload: ICollaborationItemPayload) => {
      await collaborationStore.handleCreateCollaborationItem(payload);
      queryClient.invalidateQueries({
        queryKey: reputationQueryKeys.userBalance(authStore.userMe?.username),
      });
    },
    [collaborationStore, queryClient, authStore],
  );

  const getTeamById = useGetTeamById();
  const getPlayerById = useGetPlayerById();

  if (!postsStore.post) {
    return (
      <div className={styles.Loader}>
        <Loader isShow withBackground />
      </div>
    );
  }

  const isCollaborationPost = Boolean(postsStore.post.collaboration);
  const isPollPost = Boolean(postsStore.post.attachments.poll);

  return (
    <div className={styles.PostDetailed}>
      <PostDetailed
        athleteSlug={authStore?.userMe?.athlete?.playerSlug}
        getTeamById={getTeamById}
        getPlayerById={getPlayerById}
        isCurrentUserStaff={checkIsUserStaff(authStore.userMe?.roles)}
        requestReasons={applicationStore.retrieveReportReasons}
        isCollaborationVideoLoading={collaborationStore.collaborationVideoLoading}
        convertVideoItemToAttachment={collaborationStore.uploadCollaborationVideo}
        postProcessingId={collaborationStore.postProcessingId}
        convertImageItemToAttachment={collaborationStore.uploadCollaborationImage}
        onCreateCollaborationItem={handleCreateCollaborationItem}
        isPostDetails={isCollaborationPost || isPollPost}
        feedsTheme={PostCardFeedsTheme.List}
        userId={authStore?.userMe?.id || null}
        isAthlete={authStore.isAthlete}
        team={teamsStatsStore.team}
        post={postsStore.post}
        reportReasons={applicationStore.reportReasons}
        onDeletePost={handleDeletePost}
        onTogglePostToBookmarks={handleTogglePostToBookmark}
        onShareClick={handleShowShareModal}
        onOpenPreview={handleOpenPreview}
        onLikesClick={handlePostLike}
        onPollExpired={handlePollExpired}
        onPollVote={handlePollVote}
        onPollUpdate={handlePollUpdate}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onSendReport={handleReportSubmit}
        onCloseReportsPopup={handleRedirectToParentRoute}
        collaborationMediaItem={collaborationStore.newCollaborationItemMedia}
        setCollaborationMediaItem={collaborationStore.setNewCollaborationItemMedia}
      />
      {!isCollaborationPost && postsStore.post.isCommentsAllowed && (
        <TopLevelCommentsContainer
          postId={postsStore.post.uuid}
          onUpdatePostCommentsCount={handleUpdatePostCommentsCount}
          onShareClick={handleShowShareModal}
        />
      )}
      {!isNativeApp &&
        sharePublicationData &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment) && (
          <ShareModal
            id={postsStore.post.uuid}
            visible={shareStore.isShareModalVisible}
            author={sharePublicationData.user}
            shareContent={{
              pollOptions: shareStore.shareData.pollOptions,
              attachments: shareStore.shareData.attachmentCounts,
              dateCreated: sharePublicationData.formattedDates.relativeLong,
              content: shareStore.shareData.content,
              title: shareStore.shareData.title,
            }}
            onCopyLink={handleCopySharedLink}
            onClose={handleResetSharing}
          />
        )}
      {postsStore.post.collaboration && !!collaborationStore.entries.length && (
        <CollaborationItemsList
          media={postsStore.post.collaboration.media}
          textAllowed={postsStore.post.collaboration.textAllowed}
          postId={postsStore.post.uuid}
          selectedItemId={postsStore.post.collaboration.selectedItemId}
          itemsTotal={postsStore.post.collaboration.itemsTotal}
          items={collaborationStore.entries}
          onLoadMore={handleLoadMoreCollaborationImages}
          hasMore={!collaborationStore.fetching && !collaborationStore.isLastPage}
          onLikesClick={handleLike}
        />
      )}
    </div>
  );
});
