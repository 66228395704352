import { useMemo } from 'react';
import { useGetFollowingPlayers } from 'query-hooks/followings/use-get-following-players';
import { useGetFollowingTeams } from 'query-hooks/followings/use-get-following-teams';

import { IFollow } from 'services/follow/interfaces/follow.interface';

export const useGetSortedFollowings = () => {
  const followingTeams = useGetFollowingTeams();
  const followingPlayers = useGetFollowingPlayers();

  return useMemo(() => {
    const sortByHasPosts = (a: IFollow, b: IFollow) => {
      if (a.hasNewPosts === b.hasNewPosts) return 0;

      return b.hasNewPosts ? 1 : -1;
    };

    return [
      ...followingPlayers.sort((a, b) => b.followDate - a.followDate).sort(sortByHasPosts),
      ...followingTeams.sort((a, b) => b.followDate - a.followDate).sort(sortByHasPosts),
    ];
  }, [followingPlayers, followingTeams]);
};
