import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useUserBalanceQuery } from 'query-hooks/reputation/queries/use-user-balance.query';

import { AuthStore } from 'stores/auth/auth.store';
import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';
import { ReputationTransactionsStore } from 'stores/reputation-transactions/reputation-transactions.store';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { TransactionBucketsStore } from 'stores/transaction-buckets/transaction-buckets.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { ICategoryData } from 'components/reputation-categories/reputation-categories.component';
import { ReputationPointsPopUp } from 'components/reputation-points-pop-up/reputation-points-pop-up.component';

import styles from './reputation-points-container.module.less';

export const ReputationPointsContainer: FC = observer(() => {
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);
  const transactionBucketsStore = useInjection<TransactionBucketsStore>(
    TYPES.TransactionBucketsStore,
  );
  const transactionsStore = useInjection<ReputationTransactionsStore>(
    TYPES.ReputationTransactionsStore,
  );
  const leaderboardStore = useInjection<LeaderboardStore>(TYPES.LeaderboardStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const navigate = useNavigate();

  const { data: totalReputationPoints } = useUserBalanceQuery(authStore.userMe?.username);

  const handleClosePopUp = useCallback(() => {
    reputationsPointsStore.setReputationPopUpData(null);
    transactionBucketsStore.reset();
  }, [reputationsPointsStore, transactionBucketsStore]);

  const handleCategoryClick = useCallback(
    (id: number) => {
      if (
        authStore.userMe?.username &&
        reputationsPointsStore.reputationPopUpData?.username &&
        authStore.userMe?.username !== reputationsPointsStore.reputationPopUpData?.username
      ) {
        return;
      }

      if (reputationsPointsStore.reputationPopUpData) {
        reputationsPointsStore.setReputationPopUpData({
          ...reputationsPointsStore.reputationPopUpData,
          subTypeId: id,
        });
      }
    },
    [reputationsPointsStore, authStore.userMe?.username],
  );

  const handleRankClick = useCallback(
    (event: SyntheticEvent, type: ReputationPointsCategories, bucketId: Maybe<string>) => {
      event.stopPropagation();
      handleClosePopUp();
      navigate(paths.LEADERBOARD);
      leaderboardStore.setSortParam(type);

      if (bucketId) {
        leaderboardStore.setBucketId(bucketId);
      }
    },
    [handleClosePopUp, navigate, leaderboardStore],
  );

  const handleBackClick = useCallback(() => {
    if (reputationsPointsStore.reputationPopUpData) {
      reputationsPointsStore.setReputationPopUpData({
        ...reputationsPointsStore.reputationPopUpData,
        subTypeId: null,
      });
    }
  }, [reputationsPointsStore]);

  const categoryData = useMemo<ICategoryData>(() => {
    const type = reputationsPointsStore.reputationPopUpData?.type || null;
    const points = totalReputationPoints || null;

    if (!type || !points) {
      return {
        value: 0,
        rank: 0,
      };
    }

    if (type === ReputationPointsCategories.TOTAL_POINTS) {
      return {
        value: points.totalPoints,
        rank: points.totalPointsRank,
      };
    }

    if (type === ReputationPointsCategories.PLAYER_POINTS) {
      return {
        value: points.playerPoints,
        rank: points.playerPointsRank,
      };
    }

    if (type === ReputationPointsCategories.PARTICIPATION_POINTS) {
      return {
        value: points.participationPoints,
        rank: points.participationPointsRank,
      };
    }

    return {
      value: points.predictionPoints,
      rank: points.predictionPointsRank,
    };
  }, [reputationsPointsStore.reputationPopUpData?.type, totalReputationPoints]);

  const handleLoadMoreCategories = useCallback(() => {
    transactionBucketsStore.fetchNextBucketsByCategories();
  }, [transactionBucketsStore]);

  const handleLoadMoreTransactions = useCallback(() => {
    transactionsStore.fetchNextTransactionsByBucket();
  }, [transactionsStore]);

  if (!reputationsPointsStore.reputationPopUpData) {
    return null;
  }

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={Boolean(reputationsPointsStore.reputationPopUpData)}
        size={ModalWindowSize.M}
        title={null}
        onClose={handleClosePopUp}
        disableModalHeader
        closeOnOverlayClick
      >
        <ReputationPointsPopUp
          onCloseClick={handleClosePopUp}
          reputationTransactions={transactionsStore.entries}
          onRankClick={handleRankClick}
          subTypeId={reputationsPointsStore.reputationPopUpData.subTypeId}
          type={reputationsPointsStore.reputationPopUpData.type}
          items={transactionBucketsStore.reputationPopUpCategories}
          categoryData={categoryData}
          onRowClick={handleCategoryClick}
          onBackClick={handleBackClick}
          loading={
            reputationsPointsStore.fetching ||
            transactionsStore.fetching ||
            transactionBucketsStore.fetching
          }
          onLoadMoreCategories={handleLoadMoreCategories}
          onLoadMoreTransactions={handleLoadMoreTransactions}
          hasMoreCategories={!transactionBucketsStore.isLastPage}
          hasMoreTransactions={!transactionsStore.isLastPage}
        />
      </BaseModalComponent>
    );
  }

  return (
    <div className={styles.UserDetails}>
      <BaseIonBottomSheet
        isOverflowVisible
        visible={Boolean(reputationsPointsStore.reputationPopUpData)}
        safeAreaBottom={0}
        breakpoints={[0.7, 1]}
        initialBreakpoint={0.7}
        onClose={handleClosePopUp}
      >
        <ReputationPointsPopUp
          onCloseClick={handleClosePopUp}
          reputationTransactions={transactionsStore.entries}
          onRankClick={handleRankClick}
          subTypeId={reputationsPointsStore.reputationPopUpData.subTypeId}
          type={reputationsPointsStore.reputationPopUpData.type}
          items={transactionBucketsStore.reputationPopUpCategories}
          categoryData={categoryData}
          onRowClick={handleCategoryClick}
          onBackClick={handleBackClick}
          loading={
            reputationsPointsStore.fetching ||
            transactionsStore.fetching ||
            transactionBucketsStore.fetching
          }
          onLoadMoreCategories={handleLoadMoreCategories}
          onLoadMoreTransactions={handleLoadMoreTransactions}
          hasMoreCategories={!transactionBucketsStore.isLastPage}
          hasMoreTransactions={!transactionsStore.isLastPage}
        />
      </BaseIonBottomSheet>
    </div>
  );
});
