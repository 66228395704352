import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { PlayerStore } from 'stores/player/player.store';

import { TYPES } from 'configs/di-types.config';

import { useTeamClick } from 'hooks/use-team-click';

import { PlayerProfileStats } from 'components/player-profile/player-profile-stats/player-profile-stats.component';
import { Loader } from 'components/ui/loader/loader.component';

import { CAREER_PER_MODE_OPTIONS, CAREER_SEASON_TYPE_OPTIONS } from '../player-profile.config';

import styles from '../player-profile.module.less';

export const PlayerProfileStatsContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  const playerStatsSeasonalRowData = useMemo(() => {
    if (playerStore.playerCareer) {
      return playerStore.playerCareer.records;
    }

    return [];
  }, [playerStore.playerCareer]);

  const handleTeamClick = useTeamClick();

  const handleSeasonTypeChange = useCallback(
    (value: string) => {
      const option = CAREER_SEASON_TYPE_OPTIONS.find((seasonType) => seasonType.value === value);

      if (option) {
        playerStore.setCareerSeasonTypeOption(option);
      }
    },
    [playerStore],
  );

  const handlePerModeChange = useCallback(
    (value: string) => {
      const option = CAREER_PER_MODE_OPTIONS.find((modeOption) => modeOption.value === value);

      if (option) {
        playerStore.setCareerPerModeOption(option);
      }
    },
    [playerStore],
  );

  useEffect(() => {
    playerStore.retrievePlayerCareer();
  }, [playerStore]);

  useEffect(() => {
    if (playerStore.playerDetails) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileStats,
          player: playerStore.playerDetails.fullName,
        },
      });
    }
  }, [playerStore.playerDetails]);

  if (
    (!playerStatsSeasonalRowData.length && playerStore.fetching) ||
    (!playerStore.playerStatsCareerAverageRowData.length && playerStore.fetching)
  ) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <PlayerProfileStats
      playerSeasonalStatsRowData={playerStatsSeasonalRowData}
      playerStatsCareerAverageRowData={playerStore.playerStatsCareerAverageRowData}
      hasTeamColor={!!playerStore.playerDetails?.team?.color}
      selectedSeasonType={playerStore.careerSeasonTypeOption}
      selectedPerMode={playerStore.careerPerModeOption}
      onSeasonTypeChange={handleSeasonTypeChange}
      onPerModeChange={handlePerModeChange}
      onTeamClick={handleTeamClick}
    />
  );
});
