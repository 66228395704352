import { memo, useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import {
  IPublicLink,
  LinkAction,
  LinkType,
} from 'stores/auth/interfaces/user-public-link.interface';

import { CustomLinksPopup } from 'components/edit-profile/custom-links-popup/custom-links-popup.component';
import { EditProfileFormDataType } from 'components/edit-profile/edit-profile-form/edit-profile-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { UserPublicLinks } from 'components/user-public-links/user-public-links';

import styles from './custom-links.module.less';

interface ICustomLinksProps {
  userMe: IUserMe;
}

const MAX_CUSTOM_LINKS = 10;

export const CustomLinks = memo((props: ICustomLinksProps) => {
  const { userMe } = props;
  const { getValues, setValue } = useFormContext<EditProfileFormDataType>();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState<Maybe<IPublicLink>>(null);

  const links = useWatch({ name: 'customLinks' }) as IPublicLink[];

  const handleCreateNewLinkClick = useCallback(() => {
    setSelectedLink(null);
    setIsLinkModalVisible(true);
  }, []);

  const handleCloseLinkModal = useCallback(() => {
    setIsLinkModalVisible(false);
    setSelectedLink(null);
  }, []);

  const handleSubmitCreateClick = useCallback(
    async (payload: IPublicLink) => {
      setValue('customLinks', [...links, payload], { shouldDirty: true });
      setIsLinkModalVisible(false);
      setSelectedLink(null);
    },
    [links, setValue],
  );

  const handleSubmitUpdateClick = useCallback(
    async (payload: IPublicLink) => {
      const values = getValues();
      const updatedLinks = values.customLinks.map((link: any) =>
        link.id === payload.id ? { ...link, ...payload } : link,
      );

      setValue('customLinks', updatedLinks, { shouldDirty: true });
      setIsLinkModalVisible(false);
      setSelectedLink(null);
    },
    [getValues, setValue],
  );

  const handleDeleteClick = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const handleOnConfirmDelete = useCallback(async () => {
    if (!selectedLink) {
      return;
    }

    const values = getValues();
    const existingLink = userMe.customLinks.find((link: any) => link.id === selectedLink.id);

    let updatedLinks;

    if (existingLink) {
      updatedLinks = values.customLinks.map((link: any) => {
        if (link.id === selectedLink.id) {
          return { ...link, action: LinkAction.Delete };
        }

        return link;
      });
    } else {
      updatedLinks = values.customLinks.filter((link: any) => link.id !== selectedLink.id);
    }

    setValue('customLinks', updatedLinks, { shouldDirty: true });
    setIsConfirmModalOpen(false);
    setIsLinkModalVisible(false);
    setSelectedLink(null);
  }, [selectedLink, setSelectedLink, setValue, getValues, userMe]);

  const isAddMoreVisible = useMemo(() => {
    const visibleLinks = links.filter((link) => link.action !== LinkAction.Delete);

    return visibleLinks.length < MAX_CUSTOM_LINKS;
  }, [links]);

  const customLinksPopupInitialValues = useMemo(() => {
    return {
      id: selectedLink ? selectedLink.id : (links[links.length - 1]?.id ?? 0) + 1,
      title: selectedLink?.title ?? '',
      url: selectedLink?.url ?? '',
      description: selectedLink?.description ?? '',
      type: LinkType.Custom,
    };
  }, [selectedLink, links]);

  return (
    <div className={styles.CustomLinks}>
      <p className={styles.CustomLinks__SubTitle}>Edit Custom Links</p>
      <div className={styles.CustomLinks__Content}>
        <UserPublicLinks
          links={links}
          grid={false}
          onEditClick={(link: IPublicLink) => setSelectedLink(link)}
        />
        {isAddMoreVisible && (
          <Button
            theme={ButtonTheme.Tertiary2}
            fluid
            iconName={IconFontName.Add}
            onClick={handleCreateNewLinkClick}
            size={ButtonSize.Big}
          >
            Add Link
          </Button>
        )}
      </div>

      {(Boolean(selectedLink) || isLinkModalVisible) && (
        <CustomLinksPopup
          link={selectedLink}
          initialValues={customLinksPopupInitialValues}
          visible
          onClose={handleCloseLinkModal}
          onSubmitClick={handleSubmitCreateClick}
          onUpdateClick={handleSubmitUpdateClick}
          onDeleteClick={handleDeleteClick}
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmationModal
          content="This action can’t be undone. Are you sure you want to delete this?"
          onSuccessCallback={handleOnConfirmDelete}
          onClose={() => setIsConfirmModalOpen(false)}
          title="Please confirm link removal"
          visible
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
    </div>
  );
});
