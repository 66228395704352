import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { IPlayerStatsExtendedResponse } from 'services/player/interfaces/player-stats.interface';
import { PlayerService } from 'services/player/player.service';
import { ITeamsStatsAllResponse } from 'services/team-stats/interfaces/teams-stats-all-response.interface';
import { TeamsStatsService } from 'services/team-stats/team-stats.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { playerStatsAllAdapter } from 'stores/teams-stats/adapters/player-stats-all.adapter';
import { teamStatsMiniAdapter } from 'stores/teams-stats/adapters/team-stats-mini.adapter';
import { IPlayerStatsExtended } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamStatsMini } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { TYPES } from 'configs/di-types.config';

import {
  GLOBAL_STATS_GLOSSARY_PLAYER_ENTITIES,
  GLOBAL_STATS_GLOSSARY_TEAM_ENTITIES,
} from 'components/stats/global-stats/global-stats-glossary.config';
import { IGlossaryEntity } from 'components/ui/glossary/interfaces/glossary-entity.interface';

import { playerGlobalStatsRowDataAdapter } from './adapters/player-global-stats-row-data-adapter.util';
import { teamGlobalStatsRowDataAdapter } from './adapters/team-global-stats-row-data-adapter.util';
import { StatsFilter, StatsMode } from './enums/global-stats.enums';
import { IGlobalStatsEmptyState } from './interfaces/global-stats-empty-state.interface';
import { IGlobalStatsPlayersFilters } from './interfaces/global-stats-players-filters.interface';
import {
  IPlayerGlobalStatsRowData,
  ITeamGlobalStatsRowData,
} from './interfaces/global-stats-row-data.interfaces';
import {
  DEFAULT_GLOBAL_STATS_PLAYER_FILTERS,
  DEFAULT_STATS_EMPTY_STATE_OBJECT,
} from './global-stats.config';

@injectable()
export class GlobalStatsStore extends ApiConnectedStore {
  private readonly teamsStatsService: TeamsStatsService;

  private readonly seasonsStore: SeasonsStore;

  private readonly playerService: PlayerService;

  public teamsGlobalStats: ITeamStatsMini[];

  public playersGlobalStats: IPlayerStatsExtended[];

  public seasonId: Maybe<string>;

  public playersFilters: IGlobalStatsPlayersFilters;

  public mode: StatsMode;

  public filter: StatsFilter;

  // TODO investigate better solution for handling loading and empty states, in scope of ENG-2769
  public shouldDisplayEmptyState: IGlobalStatsEmptyState;

  constructor(
    @inject(TYPES.TeamsStatsService) teamsStatsService: TeamsStatsService,
    @inject(TYPES.PlayerService) playerService: PlayerService,
    @inject(TYPES.SeasonsStore) seasonsStore: SeasonsStore,
  ) {
    super();

    this.playerService = playerService;

    this.teamsStatsService = teamsStatsService;

    this.seasonsStore = seasonsStore;

    this.teamsGlobalStats = [];

    this.playersGlobalStats = [];

    this.seasonId = null;

    this.playersFilters = DEFAULT_GLOBAL_STATS_PLAYER_FILTERS;

    this.mode = StatsMode.PLAYERS;

    this.filter = StatsFilter.LEADERS;

    this.shouldDisplayEmptyState = DEFAULT_STATS_EMPTY_STATE_OBJECT;

    makeObservable(this, {
      seasonId: observable,
      playersFilters: observable,
      teamsGlobalStats: observable,
      playersGlobalStats: observable,
      mode: observable,
      filter: observable,

      teamsGlobalStatsRowData: computed,
      playersGlobalStatsRowData: computed,
      glossaryEntities: computed,

      setPlayersGlobalStats: action.bound,
      setSeasonId: action.bound,
      setPlayersFilters: action.bound,
      setTeamsGlobalStats: action.bound,
      setMode: action.bound,
      setFilter: action.bound,
      setShouldDisplayEmptyState: action.bound,
      resetFilters: action.bound,
    });

    reaction(
      () => [this.seasonId, this.playersFilters, this.mode, this.filter],
      this.handleFiltersChange,
    );
    autorun(() => this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null));
  }

  private handleFiltersChange = () => {
    switch (this.mode) {
      case StatsMode.TEAMS: {
        if (this.filter === StatsFilter.ALL_STATS) {
          this.retrieveTeamsGlobalStats();
        }
        break;
      }

      case StatsMode.PLAYERS: {
        if (this.filter === StatsFilter.ALL_STATS) {
          this.retrievePlayersGlobalStats();
        }
        break;
      }

      default:
        break;
    }
  };

  public setFilter(filter: StatsFilter) {
    this.filter = filter;
  }

  public setMode(mode: StatsMode) {
    this.mode = mode;
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }

  public setPlayersFilters(filters: IGlobalStatsPlayersFilters) {
    this.playersFilters = filters;
  }

  public setTeamsGlobalStats(teamsGlobalStats: ITeamStatsMini[]) {
    this.teamsGlobalStats = teamsGlobalStats;
  }

  public setPlayersGlobalStats(playersGlobalStats: IPlayerStatsExtended[]) {
    this.playersGlobalStats = playersGlobalStats;
  }

  public setShouldDisplayEmptyState(mode: StatsMode, value: boolean) {
    this.shouldDisplayEmptyState[mode] = value;
  }

  public get teamsGlobalStatsRowData(): ITeamGlobalStatsRowData[] {
    return this.teamsGlobalStats.map(teamGlobalStatsRowDataAdapter);
  }

  public get playersGlobalStatsRowData(): IPlayerGlobalStatsRowData[] {
    return this.playersGlobalStats.map(playerGlobalStatsRowDataAdapter);
  }

  public get glossaryEntities(): IGlossaryEntity[] {
    if (this.mode === StatsMode.TEAMS) {
      return GLOBAL_STATS_GLOSSARY_TEAM_ENTITIES;
    }

    return GLOBAL_STATS_GLOSSARY_PLAYER_ENTITIES;
  }

  public async retrieveTeamsGlobalStats(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const response: IResponse<ITeamsStatsAllResponse[]> =
      await this.teamsStatsService.fetchTeamsStatsAll(this.seasonId);

    if (response.success) {
      this.setTeamsGlobalStats(response.data.map(teamStatsMiniAdapter));
      this.setShouldDisplayEmptyState(StatsMode.TEAMS, !response.data.length);
    } else {
      this.setShouldDisplayEmptyState(StatsMode.TEAMS, true);
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async retrievePlayersGlobalStats(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const response: IResponse<IPlayerStatsExtendedResponse[]> =
      await this.playerService.fetchPlayersStats(
        this.playersFilters.teamId || null,
        this.seasonId,
        this.playersFilters.position === 'ALL' ? null : this.playersFilters.position,
      );

    if (response.success) {
      this.setPlayersGlobalStats(response.data.map(playerStatsAllAdapter));
      this.setShouldDisplayEmptyState(StatsMode.PLAYERS, !response.data.length);
    } else {
      this.setShouldDisplayEmptyState(StatsMode.PLAYERS, true);
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public resetFilters() {
    this.shouldDisplayEmptyState = DEFAULT_STATS_EMPTY_STATE_OBJECT;
    this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null);
    this.setPlayersFilters(DEFAULT_GLOBAL_STATS_PLAYER_FILTERS);
  }
}
