import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { NotificationClickEvent } from 'onesignal-cordova-plugin';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { SettingsStore } from 'stores/settings/settings.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';

import { NotificationPermissionPopup } from 'components/popups/notification-permission-popup/notification-permission-popup.component';

const TIMEOUT_TO_GET_FEEDBACK = 1000;

enum NotifEventDataType {
  Game = 'game',
  Headlines = 'headlines',
}

interface INotifEventData {
  type: NotifEventDataType;
  game_id?: string;
  url?: Maybe<string>;
}

export const PushNotificationsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const settingsStore = useInjection<SettingsStore>(TYPES.SettingsStore);

  const timeoutPermissionChangeRef = useRef<NodeJS.Timeout>();
  const changedOnceRef = useRef<boolean>(true);

  const [authUserId, setAuthUserId] = useState<Maybe<string>>(null);
  const { isNativeApp } = useMainProvider();

  const navigate = useNavigate();

  const handlePushNotificationClick = useCallback(
    (event: NotificationClickEvent) => {
      // The default value for the additionalData param is an object.
      // Using 'as' will preserve the original interface schema of the onesignal-cordova-plugin dependency
      const notifData = event.notification.additionalData as INotifEventData;

      if (notifData.type === NotifEventDataType.Game && notifData.game_id) {
        navigate(`/${paths.ARENA_PART}/${notifData.game_id}`);
      }

      if (notifData.type === NotifEventDataType.Headlines) {
        navigate(paths.HOME_HEADLINES);
      }

      if (notifData.type === NotifEventDataType.Headlines && notifData.url) {
        navigate(paths.HOME_HEADLINES, {
          state: notifData.url,
        });
      }
    },
    [navigate],
  );

  const handlePushNotificationPermissionChange = useCallback(
    (event: boolean) => {
      clearTimeout(timeoutPermissionChangeRef.current);

      if (changedOnceRef.current) {
        applicationStore.setAllowedPushNotification(event);
      }

      changedOnceRef.current = false;

      // 'permissionChange' event is handled two times where the last value is incorrect
      timeoutPermissionChangeRef.current = setTimeout(() => {
        changedOnceRef.current = true;
      }, TIMEOUT_TO_GET_FEEDBACK);
    },
    [applicationStore],
  );

  useEffect(() => {
    if (authUserId && isNativeApp) {
      applicationStore.initialisePushNotification();
      applicationStore.loginPushNotificationUser(authUserId);
      applicationStore.handlePushNotificationClick(handlePushNotificationClick);
      applicationStore.handlePushNotificationPermissionChange(
        handlePushNotificationPermissionChange,
      );
      settingsStore.retriewePushNotificationSettings();
    }
  }, [
    applicationStore,
    authUserId,
    isNativeApp,
    settingsStore,
    handlePushNotificationClick,
    handlePushNotificationPermissionChange,
  ]);

  const connectPushNotification = useCallback(async () => {
    if (authUserId && !applicationStore.hasPushNotificationPermissions) {
      await applicationStore.requestPushNotificationPermission();
    }
  }, [authUserId, applicationStore]);

  const handleAllowNotifications = useCallback(async () => {
    await applicationStore.disableNotificationPopup();

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.PermissionsNotificationsAllow,
      },
    });

    applicationStore.setIsShowCustomNotificationPopup(false);
    await connectPushNotification();
  }, [applicationStore, connectPushNotification]);

  useEffect(() => {
    const userId = authStore.userMe?.id || authStore.anonymousUserMe?.id;

    if (userId) {
      setAuthUserId(userId);
    }
  }, [authStore.userMe, authStore.anonymousUserMe]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutPermissionChangeRef.current);
    };
  }, []);

  return (
    <NotificationPermissionPopup
      onAllowNotifications={handleAllowNotifications}
      visible={applicationStore.isShowCustomNotificationPopup && !!authUserId}
    />
  );
});
