import { IBucket } from 'stores/buckets/interfaces/bucket.interface';

export enum AchievementStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
}

export enum AchievementType {
  TotalBucketPoints = 1,
}

export enum AchievementLevel {
  Bronze = 1,
  Silver = 2,
  Gold = 3,
  Platinum = 4,
}

interface IAchievement {
  id: number;
  title: string;
  type: AchievementType;
}

interface IAchievementLevel {
  id: number;
  level: AchievementLevel;
  points: number;
}

export interface IUserAchievement {
  id: number;
  achievement: IAchievement;
  bucket: Maybe<IBucket>;
  achievementLevel: Maybe<IAchievementLevel>;
  badgeSrc: Maybe<string>;
  totalPoints: number;
  status: AchievementStatus;
  createdAt: string;
  updatedAt: string;
}
