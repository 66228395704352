import { FC, memo } from 'react';

import { IFollow } from 'services/follow/interfaces/follow.interface';

import { ITeam } from 'stores/teams/interfaces/team.interface';

import { BarActionLink } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { FollowAvatarSize } from 'components/ui/follow-avatar/follow-avatar.component';

import styles from './sidebar-following-section-desktop.module.less';

interface ISidebarFollowingSectionDesktopProps {
  teamId: Maybe<number>;
  activePath: string;
  followedTeams: ITeam[];
  followingPlayers: IFollow[];
  onTeamClick: (actionLink: BarActionLink) => void;
}

export const SidebarFollowingSectionDesktop: FC<ISidebarFollowingSectionDesktopProps> = memo(
  (props: ISidebarFollowingSectionDesktopProps) => {
    const { followingPlayers, teamId, followedTeams, activePath } = props;

    return (
      <div className={styles.FollowSection}>
        <SidebarCommunitiesList
          imageSize={FollowAvatarSize.SIZE_54}
          isExploreList={false}
          activePath={activePath}
          teams={followedTeams}
          onClick={props.onTeamClick}
          teamId={teamId}
          followingPlayers={followingPlayers}
        />
      </div>
    );
  },
);
