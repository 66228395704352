import { memo, useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';

import { useMainProvider } from 'hooks/use-main-provider';

import {
  CollaborationEditorForm,
  CollaborationItemCreateDataType,
} from 'components/forms/collaboration-editor/collaboration-editor-form.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './editor-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface ICollaborationEditorBottomSheetProps {
  title?: string;
  visible: boolean;
  postId: string;
  mediaType: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem: Maybe<CollaborationMediaType>;
  onCloseBottomSheet: () => void;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  onCreateCollaborationMediaItemWithText: (value: CollaborationItemCreateDataType) => void;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

export const CollaborationEditorBottomSheet = memo(
  (props: ICollaborationEditorBottomSheetProps) => {
    const { visible, title = 'Submit content', mediaType, collaborationMediaItem } = props;

    const { isNativeApp, isNativeAndroidApp } = useMainProvider();

    useEffect(() => {
      if (isNativeApp && !isNativeAndroidApp) {
        Keyboard.setAccessoryBarVisible({ isVisible: !visible });
      }
    }, [visible, isNativeApp, isNativeAndroidApp]);

    return (
      <BaseIonBottomSheet
        visible={visible}
        isAutoHeight
        safeAreaBottom={0}
        isHandleElementHidden
        initialBreakpoint={INITIAL_BREAKPOINT}
        breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
        onClose={props.onCloseBottomSheet}
      >
        {title && <div className={styles.Title}>{title}</div>}
        <CollaborationEditorForm
          isCollaborationVideoLoading={props.isCollaborationVideoLoading}
          isCollaborationImageLoading={props.isCollaborationImageLoading}
          postId={props.postId}
          visible={visible}
          mediaType={mediaType}
          collaborationMediaItem={collaborationMediaItem}
          onSubmit={props.onCreateCollaborationMediaItemWithText}
          setCollaborationMediaItem={props.setCollaborationMediaItem}
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          convertVideoItemToAttachment={props.convertVideoItemToAttachment}
        />
      </BaseIonBottomSheet>
    );
  },
);
