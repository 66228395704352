import { memo, useMemo } from 'react';
import {
  AchievementLevel,
  AchievementStatus,
  AchievementType,
} from 'query-hooks/reputation/interfaces/user-achievements.interface';
import {
  getAchievementBadgeCheckmark,
  getAchievementFrame,
} from 'query-hooks/reputation/utils/get-achievement-frame.util';

import frameLabel from 'assets/images/svg/achievements/frame-label.svg';

import styles from './achievement-badge.module.less';

interface IAchievementBadgeProps {
  type: AchievementType;
  badgeUrl: Maybe<string>;
  level?: Maybe<AchievementLevel>;
  status: AchievementStatus;
  label: string;
  showBadgeCheckmark?: boolean;
}

export const AchievementBadgeComponent = memo((props: IAchievementBadgeProps) => {
  const { type, badgeUrl, level, status, label, showBadgeCheckmark = false } = props;

  const { badgeFrame, badgeCheckmark } = useMemo(() => {
    if (!level || type !== AchievementType.TotalBucketPoints) {
      return { badgeFrame: '', badgeCheckmark: '' };
    }

    return {
      badgeFrame: getAchievementFrame(level, status),
      badgeCheckmark: getAchievementBadgeCheckmark(level, status),
    };
  }, [level, status, type]);

  if (!badgeUrl) {
    return null;
  }

  return (
    <div className={styles.AchievementBadgeComponent}>
      {type === AchievementType.TotalBucketPoints ? (
        <>
          {badgeFrame && (
            <img
              className={styles.AchievementBadgeComponent__BadgeFrame}
              src={badgeFrame}
              alt="achievement badge frame"
            />
          )}
          {showBadgeCheckmark && badgeCheckmark && (
            <img
              className={styles.AchievementBadgeComponent__BadgeCheckbox}
              src={badgeCheckmark}
              alt="achievement badge checkbox"
            />
          )}
          {badgeFrame && (
            <div className={styles.AchievementBadgeComponent__BadgeFrameLabel}>
              <img src={frameLabel} alt="achievement badge frame label" />
              <span>{label}</span>
            </div>
          )}
          <div className={styles.AchievementBadgeComponent__BucketBadge}>
            <img src={badgeUrl} alt="badge" />
          </div>
        </>
      ) : (
        <img className={styles.AchievementBadgeComponent__Badge} src={badgeUrl} alt="badge" />
      )}
    </div>
  );
});
