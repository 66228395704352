import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { NotificationPermission } from 'components/notification-permission/notification-permission.component';

import styles from './notification-permission-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface INotificationPermissionBottomSheetProps {
  visible: boolean;
  onAllowNotifications: () => void;
}

export const NotificationPermissionBottomSheet = (
  props: INotificationPermissionBottomSheetProps,
) => {
  const { visible } = props;

  return (
    <BaseIonBottomSheet
      canDismiss={false}
      visible={visible}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      safeAreaBottom={0}
      isAutoHeight
    >
      <div className={styles.NotificationPermissionBottomSheetWrapper}>
        <NotificationPermission onAllowNotifications={props.onAllowNotifications} />
      </div>
    </BaseIonBottomSheet>
  );
};
