import { memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { EditProfileFormDataType } from 'components/edit-profile/edit-profile-form/edit-profile-form.component';
import {
  EditorDataType,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';

import styles from './biography.module.less';

interface IBiographyProps {
  editorKey: string;
}

export const Biography = memo((props: IBiographyProps) => {
  const { editorKey } = props;
  const { control, formState } = useFormContext<EditProfileFormDataType>();

  const { errors } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  return (
    <div className={styles.Biography}>
      <p className={styles.Biography__SubTitle}>Edit Biography</p>
      <Controller
        name="biography"
        control={control}
        render={({ field }) => (
          <Editor
            editorType={EditorType.Biography}
            id="biogrphy"
            name="biography"
            disableAttachments
            error={errors.biography?.content?.value?.message}
            isSendHidden
            onChange={handleEditorChange(field.onChange)}
            placeholder="Write a short bio about yourself"
            value={field.value}
            editorKey={editorKey}
          />
        )}
      />
    </div>
  );
});
