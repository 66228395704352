import { useCallback } from 'react';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';

export const useGetPlayerBySlug = () => {
  const { data: players = [] } = useGetAllPlayersQuery();

  return useCallback(
    (slug?: string) => {
      return players.find((player) => player.slug === slug);
    },
    [players],
  );
};
