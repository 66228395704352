import { ContentCardsType } from 'services/content-cards/enums/content-cards-type.enum';
import {
  IGameContentDataResponse,
  IGameContentResponse,
  IPlayerContentDataResponse,
  IPlayerContentResponse,
} from 'services/content-cards/interfaces/content-cards-response.interface';
import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { getGameStatus } from 'stores/content-cards/utils/get-game-status.util';

import { formatISODate } from 'helpers/format/format-iso-date.util';
import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

export function playerContentCardsAdapter(contentData: IPlayerContentResponse[]) {
  return contentData.map(({ data }) => playerContentAdapter(data));
}

export function gameContentCardsAdapter(contentData: IGameContentResponse[]) {
  return contentData.map(({ data }) => gameContentAdapter(data));
}

export function gameContentAdapter(contentData: IGameContentDataResponse): IGameContent {
  const homeTeamPlayers = contentData.home_team_players.map(playerContentAdapter);
  const visitorsTeamPlayers = contentData.visitors_team_players.map(playerContentAdapter);

  return {
    type: ContentCardsType.Game,
    gameDate: formatISODate(contentData.created_at),
    gameId: contentData.game_id,
    gameStatus: getGameStatus(contentData.game_status, contentData.period),
    isFinishedGame: GameStatus.Finished === contentData.game_status,
    homeTeamCode: contentData.home_team_code,
    homeTeamId: contentData.home_team_id,
    homeTeamLogoUrl: contentData.home_team_logo_url,
    homeTeamScore: contentData.home_team_score,
    visitorsTeamCode: contentData.visitors_team_code,
    visitorsTeamId: contentData.visitors_team_id,
    visitorsTeamLogoUrl: contentData.visitors_team_logo_url,
    visitorsTeamScore: contentData.visitors_team_score,
    homeTeamPlayers,
    visitorsTeamPlayers,
  };
}

export function playerContentAdapter(contentData: IPlayerContentDataResponse): IPlayerContent {
  return {
    type: ContentCardsType.Player,
    gameDate: formatISODate(contentData.created_at),
    slug: contentData.slug,
    gameId: contentData.game_id,
    gameStatus: contentData.game_status,
    isLeader: contentData.is_leader,
    playerId: contentData.player_id,
    teamId: contentData.team_id,
    avatarUrl: contentData.avatar_url,
    jersey: contentData.jersey,
    position: contentData.position,
    fullName: `${contentData.first_name} ${contentData.last_name}`,
    assistants: contentData.assistants,
    blocks: contentData.blocks,
    steals: contentData.steals,
    plusMinus: contentData.plus_minus > 0 ? `+${contentData.plus_minus}` : contentData.plus_minus,
    points: contentData.points,
    rebounds: contentData.rebounds,
    isWinningTeam: contentData.is_winning_team,
    primaryColor: convertRGBToHEX(contentData.primary_color),
    textColor: convertRGBToHEX(contentData.text_color),
    secondaryTextColor: convertRGBToHEX(contentData.secondary_text_color),
  };
}
