import { useCallback } from 'react';
import { useGetFollowingTeams } from 'query-hooks/followings/use-get-following-teams';

export const useGetIsFollowTeam = () => {
  const followingTeams = useGetFollowingTeams();

  return useCallback(
    (id: number) => {
      if (!id) return false;

      const teamId = `${id}team`;
      const team = followingTeams.find((followItem) => followItem.id === teamId);

      return !!team?.isFollow;
    },
    [followingTeams],
  );
};
