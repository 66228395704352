import { useMemo } from 'react';
import { useGetFollowData } from 'query-hooks/followings/use-get-follow-data';

export const useGetFollowingPlayers = () => {
  const followData = useGetFollowData();

  return useMemo(() => {
    return followData.filter((item) => item.isFollow && item.type === 'player');
  }, [followData]);
};
