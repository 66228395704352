import { memo, useCallback } from 'react';
import { Clipboard } from '@capacitor/clipboard';

import { ISocialLink, LinkType } from 'stores/auth/interfaces/user-public-link.interface';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { BASE_URL } from 'configs/environment.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import playerProfileStyles from 'components/player-profile/player-profile.module.less';
import { getLinkValueByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './player-profile-header-info.module.less';

interface IPlayerProfileInfoHeaderProps {
  isAthleteProfile: boolean;
  isFollowFetching: boolean;
  isFollowedPlayer: boolean;
  playerDetails: Maybe<IPlayerDetails>;
  onTeamClick: (teamId: number) => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
  onFollowPlayer: () => void;
  socialLinks: ISocialLink[];
  onEditProfileRedirect: () => void;
  onLeaderboardRedirect: () => void;
}

export const PlayerProfileHeaderInfo = memo((props: IPlayerProfileInfoHeaderProps) => {
  const {
    socialLinks,
    isAthleteProfile,
    isFollowFetching,
    isFollowedPlayer,
    playerDetails,
    onTeamClick,
    onOpenPreview,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleTeamClick = useCallback(() => {
    if (playerDetails?.team?.id) {
      onTeamClick(playerDetails.team.id);
    }
  }, [onTeamClick, playerDetails?.team?.id]);

  const handleOpenPreview = useCallback(() => {
    if (!playerDetails?.mediumLogoUrl) {
      return;
    }

    const image = getPreviewImage(
      playerDetails.mediumLogoUrl,
      `${playerDetails.firstname.toLowerCase()}-${playerDetails.lastname.toLowerCase()}-logo`,
      playerDetails.smallLogoUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, playerDetails]);

  const handleShare = useCallback(async () => {
    if (playerDetails?.slug) {
      const href = `${BASE_URL}/${path.PLAYERS_PART}/${playerDetails.slug}`;

      await Clipboard.write({
        string: href,
      });
    }
  }, [playerDetails?.slug]);

  if (!playerDetails) {
    return null;
  }

  return (
    <div className={styles.PlayerProfileInfo}>
      <div className={styles.PlayerProfileInfo__Wrapper}>
        <div className={styles.PlayerProfileInfo__LeftSide}>
          {playerDetails.team && (
            <button className={styles.PlayerProfileInfo__TeamButton} onClick={handleTeamClick}>
              <Avatar
                size={AvatarSize.XS}
                username={playerDetails.team.code}
                src={playerDetails.team.smallLogoUrl}
              />
              <span className={styles.PlayerProfileInfo__TeamName}>
                {playerDetails.team.nickname}
              </span>
            </button>
          )}
          <div className={styles.PlayerProfileInfo__TeamInfo}>
            {isDesktopPlus && <div className={styles.Separator}>{ENCODED_BULLET}</div>}
            {playerDetails?.stats?.position && (
              <div className={styles.PlayerProfileInfo__Position}>
                {playerDetails?.stats?.position}
              </div>
            )}
            {playerDetails?.stats?.position && playerDetails.jersey && (
              <div className={styles.Separator}>{ENCODED_BULLET}</div>
            )}
            {playerDetails.jersey && (
              <div className={styles.PlayerProfileInfo__Jersey}>#{playerDetails.jersey}</div>
            )}
          </div>
          <TextTooltip
            tooltipOffset={10}
            tooltipDisappearTime={1000}
            eventType={TooltipEventType.click}
            text="Link Copied"
            placement="top"
          >
            <Button
              onClick={handleShare}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
              iconName={IconFontName.Share}
            >
              Share
            </Button>
          </TextTooltip>
        </div>

        <div className={styles.PlayerProfileInfo__AvatarWrapper}>
          <button className={styles.PlayerProfileInfo__Avatar} onClick={handleOpenPreview}>
            <Avatar
              src={playerDetails.mediumLogoUrl}
              username={playerDetails.firstname}
              size={AvatarSize.MEGA}
            />
          </button>
          {playerDetails.emojiUrl && (
            <div className={styles.PlayerProfileInfo__Emoji}>
              <img
                src={playerDetails.emojiUrl}
                alt={`${playerDetails.firstname}-${playerDetails.lastname}-emoji`}
              />
            </div>
          )}
        </div>

        <div className={styles.PlayerProfileInfo__Follow}>
          {isDesktopPlus && (
            <div className={playerProfileStyles.Socials}>
              <ProfileInfoSocials
                instagramUrl={getLinkValueByKey(socialLinks, LinkType.Instagram)}
                twitterUrl={getLinkValueByKey(socialLinks, LinkType.Twitter)}
                youtubeUrl={getLinkValueByKey(socialLinks, LinkType.Youtube)}
                tiktokUrl={getLinkValueByKey(socialLinks, LinkType.TikTok)}
                snapchat={getLinkValueByKey(socialLinks, LinkType.Snapchat)}
                discord={getLinkValueByKey(socialLinks, LinkType.Discord)}
              />
            </div>
          )}
          <div className={styles.PlayerProfileInfo__Actions}>
            {isAthleteProfile ? (
              <Button
                onClick={props.onEditProfileRedirect}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
              >
                Edit Profile
              </Button>
            ) : (
              <Button
                onClick={props.onFollowPlayer}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
                disabled={isFollowFetching || isFollowedPlayer}
                iconName={isFollowedPlayer ? IconFontName.Confirm : IconFontName.Add}
                iconSize={IconFontSize.ExtraSmall}
              >
                {isFollowedPlayer ? 'Following' : 'Follow'}
              </Button>
            )}
            {isAthleteProfile && !isDesktopPlus && (
              <Button
                onClick={props.onLeaderboardRedirect}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
              >
                Leaderboard
              </Button>
            )}
          </div>
        </div>
      </div>
      <h3 className={styles.PlayerProfileInfo__Name}>
        {playerDetails.firstname} {playerDetails.lastname}
      </h3>
    </div>
  );
});
