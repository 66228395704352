import { useMemo } from 'react';
import { useGetFollowingPlayers } from 'query-hooks/followings/use-get-following-players';

export const useCheckNewPosts = () => {
  const followingPlayers = useGetFollowingPlayers();

  return useMemo(() => {
    return followingPlayers.some((player) => player.hasNewPosts);
  }, [followingPlayers]);
};
