import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { WithActiveType } from 'types/with-active.type';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarMultipleAction } from 'components/bars/bar-action.type';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IBarItem } from 'components/bars/bar-item.interface';
import { SubBottomBar } from 'components/bars/bottom-bar-mobile/sub-bottom-bar/sub-bottom-bar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { BottomBarItem } from './bottom-bar-item/bottom-bar-item.component';

import styles from './bottom-bar-mobile.module.less';

interface IBottomBarMobileProps {
  activePath: string;
  onClick: (action: BarMultipleAction<BarActionsListType>) => void;
  items: IBarItem<BarMultipleAction<BarActionsListType>>[];
  subBottomBarItems: IBarItem<BarMultipleAction<BarActionsListType>>[];
  subMenuBackgroundColor: Maybe<string>;
  subMenuTextColor: Maybe<string>;
}

export const BottomBarMobile: FC<IBottomBarMobileProps> = memo((props: IBottomBarMobileProps) => {
  const {
    activePath,
    onClick,
    items,
    subBottomBarItems,
    subMenuBackgroundColor,
    subMenuTextColor,
  } = props;
  const [isOpenMore, setIsOpenMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes(paths.PLAYERS_PART) ||
      location.pathname.includes(paths.TEAMS_PART) ||
      location.pathname.includes(paths.USERS_PART)
    ) {
      setIsOpenMore(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes(paths.ARENA)) {
      setIsOpenMore(false);
    }
  }, [location.pathname]);

  const isActiveNavItem = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => {
      if (action.type !== BarAction.Link) {
        return false;
      }

      if (action.path === paths.PLAYERS_RANKINGS && activePath.includes(paths.PLAYERS_RANKINGS)) {
        return true;
      }

      return action.path.includes(activePath);
    },
    [activePath],
  );

  const preparedNavItems = useMemo<
    Array<WithActiveType<IBarItem<BarMultipleAction<BarActionsListType>>>>
  >(
    () =>
      items.map((item) => ({
        ...item,
        isActive: isActiveNavItem(item.action),
      })),
    [items, isActiveNavItem],
  );

  const handleItemClick = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => () => {
      if (action.payload === BarActionsListType.Home) {
        setIsOpenMore(false);
      }

      onClick(action);
    },
    [onClick, setIsOpenMore],
  );

  const handleMoreClick = useCallback(() => {
    setIsOpenMore(!isOpenMore);
  }, [setIsOpenMore, isOpenMore]);

  return (
    <nav className={styles.BottomBar}>
      <ul className={styles.BottomBar__ItemsList}>
        {preparedNavItems.map((item) => (
          <BottomBarItem
            key={item.name}
            isActive={item.isActive}
            icon={item.icon}
            name={item.name}
            onClick={handleItemClick(item.action)}
            showNotificationMarker={item.showNotificationMarker}
          />
        ))}
        <SubBottomBar
          isOpenMore={isOpenMore}
          subMenuBackgroundColor={subMenuBackgroundColor}
          subMenuTextColor={subMenuTextColor}
          activePath={activePath}
          onClick={props.onClick}
          items={subBottomBarItems}
          onHideClick={handleMoreClick}
        />
        <BottomBarItem
          key="more"
          isActive={false}
          icon={{ default: IconFontName.ChevronLeft, active: IconFontName.ChevronRight }}
          name="More"
          onClick={handleMoreClick}
        />
      </ul>
    </nav>
  );
});
