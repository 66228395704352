import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { userAchievementAdapter } from 'query-hooks/reputation/adapters/user-achievement-adapter.util';
import { DEFAULT_USER_ACHIEVEMENTS_PAGINATION } from 'query-hooks/reputation/contastants/entries.contstants';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

const retrieveUserAchievementsQuery = async ({
  queryKey,
  pageParam,
}: QueryFunctionContext<ReturnType<(typeof reputationQueryKeys)['userAchievements']>, number>) => {
  const reputationPointsService = container.get<ReputationPointsService>(
    TYPES.ReputationPointsService,
  );

  const [, , { username, seasonId, limit, exclude }] = queryKey;

  if (!username) {
    return null;
  }

  const response = await reputationPointsService.fetchUserAchievements(
    username,
    { page: pageParam ?? 1, limit },
    exclude,
    seasonId,
  );

  if (response.success) {
    return response.data.items.map(userAchievementAdapter);
  }

  return Promise.reject(response.errors);
};

export const useUserAchievementsInfinitiveQuery = (
  username: Maybe<string> = null,
  seasonId: Maybe<string> = null,
  limit: number = DEFAULT_USER_ACHIEVEMENTS_PAGINATION.limit,
  exclude: number[] = [],
) => {
  return useInfiniteQuery({
    queryKey: reputationQueryKeys.userAchievements(username, seasonId, limit, exclude),
    queryFn: retrieveUserAchievementsQuery,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      return (lastPage?.length ?? 0) < limit ? null : lastPageParam + 1;
    },
    select: (data) => {
      return {
        achievements: data.pages.flatMap((page) => page ?? []),
      };
    },
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
