import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { playersStatsAdapter } from 'query-hooks/players/adapters/players-stats.adapter';
import { playersQueryKeys } from 'query-hooks/players/contastants/query-keys.constants';

import { PlayerService } from 'services/player/player.service';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 60 * 60 * 2 * 1000; // 2h
const POLL_INTERVAL = 60 * 60 * 2 * 1000; // 2h

const retrieveGetAllPlayersQuery = async () => {
  const playerService = container.get<PlayerService>(TYPES.PlayerService);

  const response = await playerService.fetchPlayers();

  if (response.success) {
    return response.data ? playersStatsAdapter(response.data) : [];
  }

  return [];
};

export const useGetAllPlayersQuery = () => {
  const queryResult = useQuery({
    queryKey: playersQueryKeys.prefix,
    queryFn: retrieveGetAllPlayersQuery,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
    refetchInterval: POLL_INTERVAL,
    refetchIntervalInBackground: true,
  });

  const memoizedData = useMemo(() => {
    return queryResult.data || [];
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: memoizedData,
  };
};
