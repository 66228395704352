import { memo, useMemo } from 'react';

import styles from './circular-progress-bar.module.less';

export interface ICircularProgressBarProps {
  fill: number;
}

export const CircularProgressBar = memo((props: ICircularProgressBarProps) => {
  const { fill = 0 } = props;

  const values = useMemo(() => {
    const size = 64;
    const strokeWidth = 3;
    const half = size / 2;
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (fill / 100) * circumference;

    // I used px here because Safari doesn't support rem strokeDashoffset
    return {
      size: `${size}px`,
      general: {
        cx: `${half}px`,
        cy: `${half}px`,
        strokeWidth: `${strokeWidth}px`,
        r: `${radius}px`,
      },
      circumference: `${circumference}px`,
      strokeDashoffset: `${strokeDashoffset}px`,
    };
  }, [fill]);

  return (
    <div className={styles.CircularProgressBar}>
      <svg width={values.size} height={values.size}>
        <circle {...values.general} />
        <circle
          {...values.general}
          strokeDasharray={values.circumference}
          strokeDashoffset={values.strokeDashoffset}
        />
      </svg>
      <span>{fill}%</span>
    </div>
  );
});
