import { memo, useCallback, useMemo } from 'react';

import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { StandingsConference } from 'stores/standings/enums/standings-conference.enum';
import { ITeamStatsExtended } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './team-profile-header-info.module.less';

interface ITeamProfileHeaderInfoProps {
  isFollowFetching: boolean;
  isFollowedTeam: boolean;
  onFollowTeam: () => void;
  teamsStats: Maybe<ITeamStatsExtended>;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
}

export const TeamProfileHeaderInfo = memo((props: ITeamProfileHeaderInfoProps) => {
  const { isFollowFetching, isFollowedTeam, teamsStats, onOpenPreview } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleOpenPreview = useCallback(() => {
    if (!teamsStats?.mediumLogoUrl) {
      return;
    }

    const image = getPreviewImage(
      teamsStats.mediumLogoUrl,
      `${teamsStats.nickname.toLowerCase()}-logo`,
      teamsStats.smallLogoUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, teamsStats]);

  const conferenceRankText = useMemo(() => {
    if (teamsStats?.conference) {
      const teamConference = teamsStats.conference === StandingsConference.East ? 'EC' : 'WC';

      return ` in ${teamConference}`;
    }

    return null;
  }, [teamsStats?.conference]);

  if (!teamsStats) {
    return null;
  }

  return (
    <div className={styles.TeamProfileInfo}>
      <div className={styles.TeamProfileInfo__Wrapper}>
        <div className={styles.TeamProfileInfo__LeftSide}>
          <div className={styles.TeamProfileInfo__City}>
            <IconFont name={IconFontName.Arena} size={IconFontSize.Small} />
            <span className={styles.TeamProfileInfo__TeamCity}>{teamsStats.city}</span>
          </div>
          <div className={styles.TeamProfileInfo__TeamInfo}>
            <div className={styles.Position}>#{teamsStats.conferenceRank}</div>
            <div className={styles.Conference}>{conferenceRankText}</div>
            <div className={styles.Separator}>{ENCODED_BULLET}</div>
            <span className={styles.TeamProfileInfo__Results}>
              {teamsStats.totalWin} - {teamsStats.totalLose}
            </span>
          </div>
        </div>

        <div className={styles.TeamProfileInfo__AvatarWrapper}>
          <button className={styles.TeamProfileInfo__Avatar} onClick={handleOpenPreview}>
            <Avatar
              src={teamsStats.mediumLogoUrl}
              username={teamsStats.name}
              size={AvatarSize.MEGA}
            />
          </button>
        </div>
        <div className={styles.TeamProfileInfo__Follow}>
          {isDesktopPlus && (
            <ProfileInfoSocials
              discord={null}
              snapchat={null}
              instagramUrl={teamsStats.instagramUrl}
              twitterUrl={teamsStats.twitterUrl}
              youtubeUrl={teamsStats.youtubeUrl}
              tiktokUrl={teamsStats.tiktokUrl}
            />
          )}
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Small}
            disabled={isFollowFetching || isFollowedTeam}
            onClick={props.onFollowTeam}
            iconName={isFollowedTeam ? IconFontName.Confirm : IconFontName.Add}
            iconSize={IconFontSize.Small}
          >
            {isFollowedTeam ? 'Following' : 'Follow'}
          </Button>
        </div>
      </div>
      <h3 className={styles.TeamProfileInfo__Name}>
        {teamsStats.nickname} [{teamsStats.code}]
      </h3>
    </div>
  );
});
