import { memo } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { MediaPermissionBottomSheet } from 'components/bottom-sheet/media-permission-bottom-sheet/media-permission-bottom-sheet.component';
import { MediaPermissionModal } from 'components/modals/media-permission-modal/media-permission-modal.component';

interface INotificationPermissionPopupProps {
  visible: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  imageUrl: string;
  onApprove: () => void;
}

export const MediaPermissionPopup = memo((props: INotificationPermissionPopupProps) => {
  const { visible, title, message, imageUrl, primaryButtonText } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <MediaPermissionModal
        visible={visible}
        imageUrl={imageUrl}
        message={message}
        onApprove={props.onApprove}
        primaryButtonText={primaryButtonText}
        title={title}
      />
    );
  }

  return (
    <MediaPermissionBottomSheet
      visible={visible}
      imageUrl={imageUrl}
      message={message}
      onApprove={props.onApprove}
      primaryButtonText={primaryButtonText}
      title={title}
    />
  );
});
