import { memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { IS_ENABLE_ATHLETE_POST_CREATION } from 'configs/feature.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './create-post-button.module.less';

interface IFloatingButtonProps {
  isAthlete: boolean;
  hasSmartBanner: boolean;
  // onCreatePredictionPoll: () => void;
  onCreatePoll: () => void;
  onCreateGroupPost: () => void;
  onCreateVideo: () => void;
  onCreateUserGeneratedPost: () => void;
}

export const CreatePostButton = memo((props: IFloatingButtonProps) => {
  const {
    hasSmartBanner,
    isAthlete,
    //  onCreatePredictionPoll,
    onCreatePoll,
    onCreateGroupPost,
    onCreateVideo,
  } = props;

  const wrapperRef = useRef<Maybe<HTMLDivElement>>(null);
  const { isNativeApp } = useMainProvider();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isExpand, setIsExpand] = useState(false);

  const handleOpenCreatePost = useCallback(() => {
    setIsExpand(true);
  }, []);

  const handleCloseCreatePost = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isExpand) {
        setIsExpand(false);
      }
    },
    [isExpand],
  );

  const postCreationItems = useMemo(() => {
    if (isAthlete && IS_ENABLE_ATHLETE_POST_CREATION) {
      const items = [
        {
          id: 1,
          title: 'Create Poll',
          icon: IconFontName.Poll,
          handleClick: () => {
            onCreatePoll();
          },
        },
        {
          id: 2,
          title: 'Create Group Post',
          icon: IconFontName.Feedback2,
          handleClick: () => {
            onCreateGroupPost();
          },
        },
      ];

      if (!isDesktopPlus) {
        items.push({
          id: 3,
          title: 'Create Video',
          icon: IconFontName.Play,
          handleClick: () => {
            onCreateVideo();
          },
        });
      }

      return items;
    }

    return [
      {
        id: 1,
        title: 'Create Poll',
        icon: IconFontName.Poll,
        handleClick: () => {
          onCreatePoll();
        },
      },
      // {
      //   id: 1,
      //   title: 'Create Prediction Poll',
      //   icon: IconFontName.Chip,
      //   handleClick: () => {
      //     onCreatePredictionPoll();
      //   },
      // },
      {
        id: 2,
        title: 'Create Group Post',
        icon: IconFontName.Feedback2,
        handleClick: () => {
          onCreateGroupPost();
        },
      },
    ];
  }, [
    isAthlete,
    isDesktopPlus,
    onCreatePoll,
    // onCreatePredictionPoll,
    onCreateGroupPost,
    onCreateVideo,
  ]);

  const wrapperClasses = useMemo(
    () =>
      cn(styles.CreatePostWrapper, {
        [styles['CreatePostWrapper--has-smart-banner']]: !isNativeApp && hasSmartBanner,
      }),
    [hasSmartBanner, isNativeApp],
  );

  const openItemsContainerClasses = useMemo(
    () =>
      cn(styles.OpenItemsContainer, {
        [styles['OpenItemsContainer--open']]: isExpand,
      }),
    [isExpand],
  );

  const blurClasses = useMemo(
    () =>
      cn(styles.BlurContainer, {
        [styles['BlurContainer--open']]: isExpand,
      }),
    [isExpand],
  );

  return (
    <div
      className={blurClasses}
      role="button"
      tabIndex={0}
      onClick={handleCloseCreatePost}
      onKeyDown={handleCloseCreatePost}
    >
      <div className={wrapperClasses} ref={wrapperRef}>
        {isExpand ? (
          <div className={openItemsContainerClasses}>
            {postCreationItems.map((item) => (
              <div className={styles.CreatePostButtonItem} key={item.id}>
                <span>{item.title}</span>
                <IconButton
                  onClick={item.handleClick}
                  iconName={item.icon}
                  theme={IconButtonTheme.Primary}
                />
              </div>
            ))}
            <div
              className={cn(styles.CreatePostButtonItem, styles['CreatePostButtonItem--active'])}
            >
              <span>Create Post</span>
              <IconButton
                onClick={props.onCreateUserGeneratedPost}
                iconName={IconFontName.Post}
                theme={IconButtonTheme.Primary}
              />
            </div>
          </div>
        ) : (
          <IconButton
            onClick={handleOpenCreatePost}
            iconName={IconFontName.Add}
            theme={IconButtonTheme.Primary}
          />
        )}
      </div>
    </div>
  );
});
