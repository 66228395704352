import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useGetFollowingPlayers } from 'query-hooks/followings/use-get-following-players';
import { useGetFollowingPlayersAbbr } from 'query-hooks/followings/use-get-following-players-abbr';
import { useGetFollowingTeams } from 'query-hooks/followings/use-get-following-teams';
import { useGetFollowingTeamsAbbr } from 'query-hooks/followings/use-get-following-teams-abbr';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';

export const useGaButtonCustomTap = () => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const followingTeams = useGetFollowingTeams();
  const followingPlayers = useGetFollowingPlayers();
  const teamsFollowAbbrevs = useGetFollowingTeamsAbbr();
  const playersFollowAbbrevs = useGetFollowingPlayersAbbr();

  const googleAnalyticsButtonTap = useCallback(
    (buttonTapType: ButtonTapsEnum) => {
      const teamsFollow = teamsFollowAbbrevs.toString().slice(0, 100);
      const playersFollow = playersFollowAbbrevs.toString().slice(0, 100);

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: buttonTapType,
          num_teams_follow: authStore.isAuthorised ? followingTeams.length : '',
          num_players_follow: authStore.isAuthorised ? followingPlayers.length : '',
          teams_follow: authStore.isAuthorised ? teamsFollow : '',
          players_follow: authStore.isAuthorised ? playersFollow : '',
        },
      });
    },
    [
      authStore.isAuthorised,
      followingPlayers.length,
      followingTeams.length,
      playersFollowAbbrevs,
      teamsFollowAbbrevs,
    ],
  );

  return {
    googleAnalyticsButtonTap,
  };
};
