import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { BucketsService } from 'services/buckets/buckets.service';

import { bucketAdapter } from 'stores/buckets/adapters/bucket-adapter.util';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

const retrievePlayerBucketQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof reputationQueryKeys)['playerBucket']>>) => {
  const reputationPointsService = container.get<BucketsService>(TYPES.BucketsService);

  const [, , { playerId }] = queryKey;

  if (!playerId) {
    return null;
  }

  const response = await reputationPointsService.fetchPlayerBucket(playerId);

  if (response.success) {
    return response.data ? bucketAdapter(response.data) : null;
  }

  return null;
};

export const usePlayerBucketQuery = (playerId: Maybe<number> = null) => {
  return useQuery({
    queryKey: reputationQueryKeys.playerBucket(playerId),
    queryFn: retrievePlayerBucketQuery,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
