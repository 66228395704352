import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { IFollow } from 'services/follow/interfaces/follow.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { ITeamsBySubscription } from 'stores/teams/interfaces/teams-by-subscription.interface';

import { LEFT_SIDEBAR_ELEMENT } from 'configs/controls.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { BarActionType } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { SidebarFollowingItem } from 'components/sidebars/sidebar-following-item/sidebar-following-item.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { FollowAvatarSize } from 'components/ui/follow-avatar/follow-avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { SidebarContactUs } from '../sidebar-contact-us/sidebar-contact-us.component';

import styles from './sidebar-drawer.module.less';

interface ISidebarMobileProps {
  userRating: number;
  isAuthorized: boolean;
  isAuthorizedAnonymously: boolean;
  isPulledRefresher: boolean;
  activePath: string;
  teamId: Maybe<number>;
  teams: ITeamsBySubscription;
  teamName?: Maybe<string>;
  onClick: (action: BarActionType) => void;
  openAuthPopup: () => void;
  onCloseLeftSidePanel: () => void;
  followingPlayers: IFollow[];
  userMe: Maybe<IUserMe>;
}

export const SidebarDrawer: FC<ISidebarMobileProps> = memo((props: ISidebarMobileProps) => {
  const {
    openAuthPopup,
    onCloseLeftSidePanel,
    isAuthorized,
    isAuthorizedAnonymously,
    isPulledRefresher,
    activePath,
    teamName,
    teams,
    teamId,
    followingPlayers,
    userMe,
    userRating,
  } = props;

  const navigate = useNavigate();

  const sidebarClassNames = useMemo(
    () =>
      cn(styles.SidebarDrawer, {
        [styles['SidebarDrawer--freezed']]: isPulledRefresher,
      }),
    [isPulledRefresher],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      onCloseLeftSidePanel();
      navigate(
        getPath(paths.PLAYER_PROFILE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
        }),
      );
    },
    [navigate, onCloseLeftSidePanel],
  );

  const handleEditClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.ExploreEditFavorites,
        team: teamName || 'null',
      },
    });

    onCloseLeftSidePanel();
    navigate(paths.FOLLOWING);
  }, [teamName, navigate, onCloseLeftSidePanel]);

  const handleRedirectToSettings = useCallback(() => {
    navigate(paths.SETTINGS);
    onCloseLeftSidePanel();
  }, [navigate, onCloseLeftSidePanel]);

  const handleRedirectToProfile = useCallback(() => {
    if (userMe?.athlete) {
      navigate(
        getPath(paths.PLAYER_PROFILE_FEED, {
          [paths.PLAYER_SLUG_PARAM]: userMe.athlete.playerSlug,
        }),
      );
    } else if (userMe?.username) {
      navigate(
        getPath(paths.USER_PUBLIC_FEED, {
          [paths.USERNAME_PARAM]: userMe.username,
        }),
      );
    }

    onCloseLeftSidePanel();
  }, [navigate, onCloseLeftSidePanel, userMe]);

  const handleRedirectToAuth = useCallback(() => {
    onCloseLeftSidePanel();
    openAuthPopup();
  }, [openAuthPopup, onCloseLeftSidePanel]);

  return (
    <nav className={sidebarClassNames} id={LEFT_SIDEBAR_ELEMENT}>
      <div className={styles.SidebarDrawer__Content}>
        {!isAuthorized && (
          <div className={styles.SidebarDrawer__Profile}>
            <Button
              theme={ButtonTheme.Primary}
              size={ButtonSize.Big}
              onClick={handleRedirectToAuth}
            >
              Sign up or Login
            </Button>
            {isAuthorizedAnonymously && (
              <IconButton
                iconName={IconFontName.Settings}
                theme={IconButtonTheme.Secondary}
                onClick={handleRedirectToSettings}
              />
            )}
          </div>
        )}
        {isAuthorized && userMe && (
          <div className={styles.SidebarDrawer__Profile}>
            <div
              role="button"
              aria-label="Profile"
              tabIndex={0}
              className={styles.Profile}
              onKeyDown={handleRedirectToProfile}
              onClick={handleRedirectToProfile}
            >
              <Avatar
                size={AvatarSize.M}
                src={userMe?.profile?.avatarUrl}
                username={userMe?.username || ''}
              />
              <div className={styles.Profile__Info}>
                <div
                  className={cn(styles['Profile__Info--username'], {
                    [styles['Profile__Info--athlete']]: userMe.athlete,
                  })}
                >
                  {!userMe.athlete ? userMe.username : userMe.athlete.fullName}
                </div>
                {!userMe.athlete && (
                  <div className={styles['Profile__Info--points']}>
                    <IconButton
                      iconSize={IconFontSize.ExtraSmall}
                      iconName={IconFontName.StarFilled}
                      theme={IconButtonTheme.Secondary}
                    />
                    {userRating ? (
                      <div className={styles['Profile__Info--rating']}>{userRating}</div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <IconButton
              iconName={IconFontName.Settings}
              theme={IconButtonTheme.Secondary}
              onClick={handleRedirectToSettings}
            />
          </div>
        )}
        {!followingPlayers.length && !teams.joined.length && (
          <div className={styles.SidebarDrawer__FollowButton}>
            <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleEditClick}>
              Follow Teams & Players
            </Button>
          </div>
        )}
        {!!followingPlayers.length && (
          <>
            <div className={styles.SidebarDrawer__Navigation}>
              <h3
                className={styles.SidebarDrawer__Header}
              >{`Following Players (${followingPlayers.length})`}</h3>
              <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleEditClick}>
                Edit
              </Button>
            </div>
            <div className={styles.SidebarDrawer__PlayersList}>
              {followingPlayers.map((item) => (
                <SidebarFollowingItem
                  key={item.id}
                  imageSize={FollowAvatarSize.SIZE_64}
                  hasNewPosts={item.hasNewPosts}
                  imageSrc={item.thumbnailUrl}
                  name={item.label}
                  onClick={handlePlayerClick}
                  slug={item.slug}
                />
              ))}
            </div>
          </>
        )}
        {!!teams.joined.length && (
          <>
            <div className={styles.SidebarDrawer__Navigation}>
              <h3 className={styles.SidebarDrawer__Header}>
                {`Following Teams (${teams.joined.length})`}
              </h3>
              {!followingPlayers.length && (
                <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleEditClick}>
                  Edit
                </Button>
              )}
            </div>
            <div className={styles.SidebarDrawer__List}>
              <SidebarCommunitiesList
                isMobile
                activePath={activePath}
                teams={teams.joined}
                onClick={props.onClick}
                teamId={teamId}
                imageSize={FollowAvatarSize.SIZE_64}
              />
            </div>
          </>
        )}
        {!!teams.explore.length && (
          <>
            <h3 className={styles.SidebarDrawer__Header}>Explore</h3>
            <div className={styles.SidebarDrawer__List}>
              <SidebarCommunitiesList
                imageSize={FollowAvatarSize.SIZE_64}
                teamId={teamId}
                isMobile
                isNoJoined
                activePath={activePath}
                teams={teams.explore}
                onClick={props.onClick}
              />
            </div>
          </>
        )}
        {!isAuthorized && (
          <>
            <span className={styles.SidebarDrawer__Separator} />
            <div className={styles.SidebarDrawer__ContactUs}>
              <SidebarContactUs onClick={props.onClick} />
            </div>
          </>
        )}
      </div>
    </nav>
  );
});
