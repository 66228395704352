import { IPlayerStatsExtendedResponse } from 'services/player/interfaces/player-stats.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { PlayerFontColor } from '../enums/player-font-color.enum';
import { IPlayerStatsExtended } from '../interfaces/players-stats.interface';

export function playerStatsAllAdapter(
  playerStatsExtendedResponse: IPlayerStatsExtendedResponse,
): IPlayerStatsExtended {
  const {
    age,
    assists,
    color,
    blocks,
    firstname,
    font_theme: fontTheme,
    games_played: gamesPlayed,
    height,
    height_ft: heightFt,
    jersey,
    lastname,
    season,
    position,
    medium_logo_url: mediumLogoUrl,
    points,
    small_logo_url: smallLogoUrl,
    steals,
    tot_reb: totalRebounds,
    id,
    fgm,
    fga,
    fgp,
    tpm,
    tpa,
    tpp,
    ftm,
    fta,
    ftp,
    off_reb: offReb,
    def_reb: defReb,
    personal_fouls: personalFouls,
    turnovers,
    slug,
    weight,
    weight_lbs: weightLbs,
  } = playerStatsExtendedResponse;

  return {
    slug,
    player: {
      position: 'C',
      fullName: `${firstname} ${lastname}`,
      avatarUrl: smallLogoUrl,
      slug,
    },
    color: convertColorToRGB(color),
    colorWithOpacity: convertColorToRGBA(color, 0.3),
    firstname,
    lastname,
    id,
    mediumLogoUrl,
    smallLogoUrl,
    fontColor: fontTheme === 'dark' ? PlayerFontColor.Dark : PlayerFontColor.Light,
    season: seasonAdapter(season),
    position,
    age,
    assists: roundToSingleDecimal(assists),
    blocks: roundToSingleDecimal(blocks),
    gamesPlayed,
    heightMeters: height,
    heightFt,
    jersey,
    points: roundToSingleDecimal(points),
    steals: roundToSingleDecimal(steals),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    fieldGoalsMade: roundToSingleDecimal(fgm),
    fieldGoalsAttempted: roundToSingleDecimal(fga),
    fieldGoalsPercentage: roundToSingleDecimal(fgp),
    threePointsFieldGoalsMade: roundToSingleDecimal(tpm),
    threePointsFieldGoalsAttempted: roundToSingleDecimal(tpa),
    threePointsFieldGoalsPercentage: roundToSingleDecimal(tpp),
    freeThrowsMade: roundToSingleDecimal(ftm),
    freeThrowsAttempted: roundToSingleDecimal(fta),
    freeThrowsPercentage: roundToSingleDecimal(ftp),
    offensiveReb: roundToSingleDecimal(offReb),
    defensiveReb: roundToSingleDecimal(defReb),
    personalFouls: roundToSingleDecimal(personalFouls),
    turnovers: roundToSingleDecimal(turnovers),
    weightKg: weight,
    weightLbs,
    fullName: `${firstname} ${lastname}`,
  };
}
