import { memo, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useBlocker } from 'react-router';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { LinkAction, LinkType } from 'stores/auth/interfaces/user-public-link.interface';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { avatarAndThumbnailSchema } from 'validation/schemas/avatar-and-thumbnail.schema';
import { biographyEditorSchema } from 'validation/schemas/editor.schema';
import { customLinksSchema, socialLinkSchema } from 'validation/schemas/link.schema';
import { realNameSchema, userNameSchema } from 'validation/schemas/name.schema';

import { Biography } from 'components/edit-profile/edit-profile-form/biography/biography.component';
import { CustomLinks } from 'components/edit-profile/edit-profile-form/custom-links/custom-links.component';
import { SocialLinks } from 'components/edit-profile/edit-profile-form/social-links/social-links.component';
import { UserPersonalInfo } from 'components/edit-profile/edit-profile-form/user-personal-info/user-personal-info.component';
import { EditProfileHeader } from 'components/edit-profile/edit-profile-header/edit-profile-header.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { PlayerProfileHeader } from 'components/player-profile/player-profile-header/player-profile-header.component';
import { getLinkByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import defaultThumbnail from 'assets/images/svg/user-default-background.svg';

import styles from './edit-profile-form.module.less';

const editProfileFormSchema = z
  .object({
    thumbnail: avatarAndThumbnailSchema,
    avatar: avatarAndThumbnailSchema,
    username: userNameSchema,
    realname: realNameSchema,
    biography: biographyEditorSchema,
    socialLinks: z.object({
      instagram: socialLinkSchema,
      twitter: socialLinkSchema,
      youtube: socialLinkSchema,
      tiktok: socialLinkSchema,
      snapchat: socialLinkSchema,
      discord: socialLinkSchema,
    }),
    customLinks: customLinksSchema,
  })
  .strict();

const athleteProfileSchema = editProfileFormSchema.omit({
  username: true,
  realname: true,
  thumbnail: true,
  avatar: true,
});

export type EditProfileFormDataType = z.infer<typeof editProfileFormSchema>;
export type AthleteProfileFormDataType = z.infer<typeof athleteProfileSchema>;

interface IEditProfileForm extends IForm<EditProfileFormDataType | AthleteProfileFormDataType> {
  isAthlete: boolean;
  userMe: IUserMe;
  playerDetails: Maybe<IPlayerDetails>;
  onSubmit: (
    formData: EditProfileFormDataType | AthleteProfileFormDataType,
  ) => Promise<Maybe<IUserMe> | void>;
}

export const EditProfileForm = memo((props: IEditProfileForm) => {
  const { onSubmit, isAthlete, userMe, playerDetails } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [editorKey, setEditorKey] = useState<string>(Date.now().toString());

  const getInitialValues = useCallback(
    (user: IUserMe) => {
      let values: EditProfileFormDataType | AthleteProfileFormDataType;

      const socialUrl = {
        instagram: getLinkByKey(user.socialLinks, LinkType.Instagram),
        twitter: getLinkByKey(user.socialLinks, LinkType.Twitter),
        youtube: getLinkByKey(user.socialLinks, LinkType.Youtube),
        tiktok: getLinkByKey(user.socialLinks, LinkType.TikTok),
        snapchat: getLinkByKey(user.socialLinks, LinkType.Snapchat),
        discord: getLinkByKey(user.socialLinks, LinkType.Discord),
      };

      values = {
        biography: user.profile.biography
          ? { content: { value: user.profile.biography, length: 0 } }
          : {},
        socialLinks: {
          instagram: {
            url: socialUrl.instagram?.url ?? '',
            ...(!socialUrl.instagram
              ? { action: LinkAction.Create }
              : { id: socialUrl.instagram.id }),
          },
          twitter: {
            url: socialUrl.twitter?.url ?? '',
            ...(!socialUrl.twitter ? { action: LinkAction.Create } : { id: socialUrl.twitter.id }),
          },
          youtube: {
            url: socialUrl.youtube?.url ?? '',
            ...(!socialUrl.youtube ? { action: LinkAction.Create } : { id: socialUrl.youtube.id }),
          },
          tiktok: {
            url: socialUrl.tiktok?.url ?? '',
            ...(!socialUrl.tiktok ? { action: LinkAction.Create } : { id: socialUrl.tiktok.id }),
          },
          snapchat: {
            url: socialUrl.snapchat?.url ?? '',
            ...(!socialUrl.snapchat
              ? { action: LinkAction.Create }
              : { id: socialUrl.snapchat.id }),
          },
          discord: {
            url: socialUrl.discord?.url ?? '',
            ...(!socialUrl.discord ? { action: LinkAction.Create } : { id: socialUrl.discord.id }),
          },
        },
        customLinks: user.customLinks,
      } as AthleteProfileFormDataType;

      if (!isAthlete) {
        values = {
          ...values,
          username: user.username,
          realname: user.profile.realName ?? '',
          thumbnail: {
            value: user.profile.thumbnailUrl ?? defaultThumbnail,
          },
          avatar: {
            value: user.profile.avatarUrl ?? '',
          },
        } as EditProfileFormDataType;
      }

      return values;
    },
    [isAthlete],
  );

  const initialValues = useMemo(() => getInitialValues(userMe), [userMe, getInitialValues]);

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(isAthlete ? athleteProfileSchema : editProfileFormSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitted, isValid },
    reset,
  } = methods;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && !isSubmitted && currentLocation.pathname !== nextLocation.pathname,
  );

  const handleOnSubmit = useCallback(() => {
    const submit = async (data: EditProfileFormDataType | AthleteProfileFormDataType) => {
      const updateUserMe = await onSubmit(data);

      if (updateUserMe) {
        const initial = getInitialValues(updateUserMe);
        reset(initial);
        setEditorKey(Date.now().toString());
      }
    };

    handleSubmit(submit)();
  }, [onSubmit, handleSubmit, getInitialValues, reset]);

  const renderHeader = useCallback(() => {
    if (isDesktopPlus) {
      if (!playerDetails) {
        return null;
      }

      return (
        <PlayerProfileHeader
          isVisibleForMobile={false}
          isFollowedPlayer={false}
          isFollowFetching={false}
          avatarUrl={playerDetails.mediumLogoUrl}
          fullName={playerDetails.fullName}
          isFollowUnfollowVisible={false}
        />
      );
    }

    return (
      <EditProfileHeader
        onSaveChangesClick={handleOnSubmit}
        disabled={isSubmitting || !isDirty || !isValid}
      />
    );
  }, [playerDetails, isDesktopPlus, handleOnSubmit, isSubmitting, isDirty, isValid]);

  useLayoutEntity({ type: LayoutEntity.HeaderCenter, value: renderHeader });

  const handleShowWarningModal = useCallback(() => {
    setIsWarningModalVisible(true);
  }, []);

  const handleOnCloseWarningModal = useCallback(() => {
    setIsWarningModalVisible(false);

    reset(initialValues);
    setEditorKey(Date.now().toString());

    if (blocker && blocker.proceed) {
      blocker.proceed();
    }
  }, [blocker, reset, initialValues]);

  const handleOnConfirmWarningModal = useCallback(() => {
    setIsWarningModalVisible(false);
    handleOnSubmit();

    if (blocker && blocker.proceed) {
      blocker.proceed();
    }
  }, [handleOnSubmit, blocker]);

  const wrapperClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--keyboard']]: isKeyboardOpen,
    });
  }, [isKeyboardOpen]);

  return (
    <div className={wrapperClasses}>
      <FormProvider {...methods}>
        <Form className={styles.EditProfileForm}>
          {!isAthlete && <UserPersonalInfo userMe={userMe} />}
          <SocialLinks />
          <CustomLinks userMe={userMe} />
          <Biography editorKey={editorKey} />
        </Form>
      </FormProvider>
      {isDesktopPlus && (
        <div className={styles.EditProfileFormSubmit}>
          <Button
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Big}
            type="submit"
            disabled={!isDirty || isSubmitted}
            onClick={handleShowWarningModal}
          >
            Cancel
          </Button>
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Big}
            onClick={handleOnSubmit}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            Save
          </Button>
        </div>
      )}

      <ConfirmationModal
        content="Are you sure you want to leave without saving? All the changes will be canceled."
        onSuccessCallback={handleOnConfirmWarningModal}
        onClose={handleOnCloseWarningModal}
        title="Unsaved Changes"
        visible={isWarningModalVisible || blocker.state === 'blocked'}
        primaryButtonText="Save"
        secondaryButtonText="Don't Save"
      />
    </div>
  );
});
