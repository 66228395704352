import { FC, memo, SyntheticEvent, useCallback, useRef, useState } from 'react';

import { IFileData } from 'helpers/to-base64.util';

import { useMainProvider } from 'hooks/use-main-provider';

import { PhotoCameraField } from 'components/forms/photo-camera-field/photo-camera-field.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { FileInputWithCropping } from 'components/ui/form-fields/file-input-with-cropping/file-input-with-cropping.component';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './avatar-input.module.less';

export interface IAvatarInputProps extends Omit<IHookFormInput, 'onChange'> {
  src?: Maybe<string>;
  username: string;
  onChange: (value: IFileData) => void;
  accept: string;
  avatarSize?: AvatarSize;
  isCropModalVisible: boolean;
  forEditModal?: boolean;
  onSetCropModal?: (isOpen: boolean) => void;
}

export const AvatarInput: FC<IAvatarInputProps> = memo((props: IAvatarInputProps) => {
  const {
    src,
    username,
    isCropModalVisible,
    onChange,
    id,
    name,
    accept,
    avatarSize = AvatarSize.MEGA,
    forEditModal = false,
  } = props;

  const [localAvatar, setLocalAvatar] = useState<Maybe<string>>();
  const inputClickHandlerRef = useRef<Maybe<() => void>>();

  const { isNativeApp } = useMainProvider();

  const handleConnectRef = useCallback((handler?: () => void) => {
    if (handler) {
      inputClickHandlerRef.current = handler;
    }
  }, []);

  const handleAvatarClick = useCallback(() => {
    if (inputClickHandlerRef.current) {
      inputClickHandlerRef.current();
    }
  }, []);

  const handleAvatarIconClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      handleAvatarClick();
    },
    [handleAvatarClick],
  );

  const handleOnChange = useCallback(
    (fileData: IFileData[]) => {
      setLocalAvatar(fileData[0].value);

      if (onChange && !isCropModalVisible) {
        onChange(fileData[0]);
      }
    },
    [onChange, isCropModalVisible],
  );

  const handleOnSaveImage = useCallback(
    (fileData: IFileData) => {
      if (onChange) {
        onChange(fileData);
      }
    },
    [onChange],
  );

  return (
    <div
      className={styles.AvatarInput}
      role="button"
      tabIndex={0}
      onClick={handleAvatarClick}
      onKeyDown={handleAvatarClick}
    >
      <Avatar src={localAvatar || src} username={username} size={avatarSize} />
      {forEditModal && (
        <div className={styles.AvatarInput__ButtonWrapper}>
          <IconButton
            theme={IconButtonTheme.Navigation}
            onClick={handleAvatarIconClick}
            iconName={IconFontName.Camera}
            iconSize={IconFontSize.Big}
          />
        </div>
      )}
      <div className={styles.HoverTextBlock}>
        <div className={styles.HoverTextBlock__Text}>Change Avatar</div>
      </div>
      <PhotoCameraField enabledFunction={forEditModal && isNativeApp}>
        <FileInputWithCropping
          onBlur={props.onBlur}
          onChange={handleOnChange}
          onAddFilesSet={handleConnectRef}
          id={id}
          name={name}
          accept={accept}
          isCropCircle
          onSaveImage={handleOnSaveImage}
          isCropModalVisible={props.isCropModalVisible}
          onSetCropModal={props.onSetCropModal}
        />
      </PhotoCameraField>
    </div>
  );
});
