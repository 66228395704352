import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowPlayerMutation } from 'query-hooks/followings/mutations/use-follow-player-mutation';
import { useGetIsFollowPlayer } from 'query-hooks/followings/use-get-is-follow-player';
import { usePlayerBucketQuery } from 'query-hooks/reputation/queries/user-player-bucket.query';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { BucketsStore } from 'stores/buckets/buckets.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { PlayerStore } from 'stores/player/player.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useTeamClick } from 'hooks/use-team-click';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { PlayerProfileHeaderInfo } from 'components/player-profile/player-profile-header-info/player-profile-header-info.component';

export const PlayerProfileHeaderInfoContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const bucketsStore = useInjection<BucketsStore>(TYPES.BucketsStore);

  const handleTeamClick = useTeamClick();

  const getIsFollowPlayer = useGetIsFollowPlayer();

  const { data: playerBucket } = usePlayerBucketQuery(playerStore.playerDetails?.id);

  const navigate = useNavigate();

  const isAthleteProfile = useMemo(() => {
    return authStore.userMe?.athlete?.playerSlug === playerStore.playerDetails?.slug;
  }, [authStore.userMe, playerStore.playerDetails]);

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = playerStore.playerDetails
        ? {
            name: `${playerStore.playerDetails.firstname} ${playerStore.playerDetails.lastname}`,
            logoUrl: playerStore.playerDetails.smallLogoUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, playerStore.playerDetails],
  );

  const { followPlayerMutation, isFetchingFollowPlayer } = useFollowPlayerMutation();

  const handleFollowPlayer = useCallback(() => {
    if (playerStore.playerDetails) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileFollow,
          player: playerStore.playerDetails.fullName,
        },
      });
      followPlayerMutation(playerStore.playerDetails.id);
    }
  }, [playerStore.playerDetails, followPlayerMutation]);

  const handleEditProfileRedirect = useCallback(() => {
    if (!playerStore.playerDetails?.slug) {
      return;
    }

    navigate(
      getPath(paths.PLAYER_EDIT_PROFILE, {
        [paths.PLAYER_SLUG_PARAM]: playerStore.playerDetails.slug,
      }),
    );
  }, [playerStore.playerDetails, navigate]);

  const handleLeaderboardRedirect = useCallback(() => {
    const activeBucket = playerBucket?.id.toString() ?? bucketsStore.defaultBucket.value;
    navigate({
      pathname: paths.LEADERBOARD,
      search: `${createSearchParams({
        bucketId: activeBucket,
      })}`,
    });
  }, [navigate, playerBucket, bucketsStore]);

  if (!playerStore.playerDetails) {
    return null;
  }

  return (
    <PlayerProfileHeaderInfo
      socialLinks={playerStore.athlete?.socialLinks || []}
      isAthleteProfile={isAthleteProfile}
      isFollowFetching={isFetchingFollowPlayer}
      isFollowedPlayer={getIsFollowPlayer(playerStore.playerDetails.id)}
      playerDetails={playerStore.playerDetails}
      onTeamClick={handleTeamClick}
      onOpenPreview={handleOpenPreview}
      onFollowPlayer={handleFollowPlayer}
      onEditProfileRedirect={handleEditProfileRedirect}
      onLeaderboardRedirect={handleLeaderboardRedirect}
    />
  );
});
