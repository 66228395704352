import { IPublicLinkPayload } from 'services/auth/interfaces/public-link-payload.interface';

import {
  IPublicLink,
  LinkAction,
  LinkType,
} from 'stores/auth/interfaces/user-public-link.interface';

export const userLinksPayloadBuilder = (
  customLinks: IPublicLink[],
  socialLinks: Partial<
    Record<Exclude<LinkType, LinkType.Custom>, { url: string; action?: LinkAction; id?: number }>
  >,
): IPublicLinkPayload[] => {
  const links: IPublicLinkPayload[] = customLinks.map((link) => ({
    id: link.id,
    title: link.title,
    url: link.url,
    description: link.description,
    type: link.type,
    action: link.action,
  }));

  Object.entries(socialLinks).forEach(([type, link]) => {
    if (link.url || link.action === LinkAction.Delete) {
      links.push({
        url: link.url,
        type: type as LinkType,
        action: link.action,
        id: link.id,
      });
    }
  });

  return links;
};
