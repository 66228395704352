export const CAPACITOR_ENV: string = process.env.CAPACITOR_ENV || '';
export const API_GATEWAY_URL: string = process.env.API_GATEWAY_URL || '';
export const GIPHY_KEY: string = process.env.GIPHY_KEY || '';
export const ONESIGNAL_APP_ID: string = process.env.ONESIGNAL_APP_ID || '';
export const GOOGLE_CLIENT_ID: string = process.env.GOOGLE_CLIENT_ID || '';
export const GOOGLE_IOS_CLIENT_ID: string = process.env.GOOGLE_IOS_CLIENT_ID || '';
export const APPLE_CLIENT_ID: string = process.env.APPLE_CLIENT_ID || '';
export const APPLE_REDIRECT_URI: string = process.env.APPLE_REDIRECT_URI || '';
export const APP_PROTOCOL: string = process.env.APP_PROTOCOL || '';
export const PACKAGE_APP_NAME: string = process.env.PACKAGE_APP_NAME || '';
export const GA_TAG_ID: string = process.env.GA_TAG_ID || '';
export const APPS_FLYER_DEV_KEY: string = process.env.APPS_FLYER_DEV_KEY || '';
export const APPLE_ID: string = process.env.APPLE_ID || '';
export const BASE_URL: string = process.env.BASE_URL || '';
export const IS_ENABLE_FAN_ZONE_REDIRECT_FOR_ATHLETE: boolean =
  process.env.IS_ENABLE_FAN_ZONE_REDIRECT_FOR_ATHLETE === 'true' || false;

// socket.io path is used on the gateway
export const IO_HOST_URL: string = API_GATEWAY_URL;

export const AUTH_HOST: string = process.env.DIGITS_AUTH_HOST || '';
export const CORE_HOST: string = process.env.DIGITS_CORE_HOST || '';
export const STATISTICS_HOST: string = process.env.DIGITS_STATISTICS_HOST || '';
export const REPUTATION_HOST: string = process.env.DIGITS_REPUTATION_HOST || '';
