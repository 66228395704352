import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useUserBalanceQuery } from 'query-hooks/reputation/queries/use-user-balance.query';
import { useUserTopSeasonAchievementQuery } from 'query-hooks/reputation/queries/use-user-top-achievement.query';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { ProfileInfo } from 'components/profile-info/profile-info.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const UserPublicProfileHeaderContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const myUserData = authStore.userMe;

  const publicUserData = userPublicStore.userPublic;

  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;

  const navigate = useNavigate();

  const { data: userBalance } = useUserBalanceQuery(username);

  const { data: topAchievement = null } = useUserTopSeasonAchievementQuery(username);

  const isMyPage = useMemo(() => {
    return authStore.userMe?.username === username && !authStore.userMe?.athlete;
  }, [authStore.userMe, username]);

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = publicUserData?.smallAvatarUrl
        ? {
            name: publicUserData.username,
            logoUrl: publicUserData.smallAvatarUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, publicUserData],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.UserProfile,
      },
    });
  }, []);

  const isAuthUserProfile = useMemo(() => {
    if (!authStore.isAuthorised || !myUserData) {
      return false;
    }

    return authStore.userMe?.username === myUserData?.username;
  }, [authStore.isAuthorised, authStore.userMe?.username, myUserData]);

  const handleEditProfileRedirect = useCallback(() => {
    if (!authStore.userMe?.username) {
      return;
    }

    navigate(
      getPath(paths.USER_EDIT_PROFILE, {
        [paths.USERNAME_PARAM]: authStore.userMe?.username,
      }),
    );
  }, [authStore.userMe?.username, navigate]);

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  return (
    <div>
      <ProfileInfo
        rating={userBalance?.totalPoints || 0}
        isMyPage={isMyPage}
        userData={publicUserData}
        onEditProfilePress={handleEditProfileRedirect}
        isAuthUserProfile={isAuthUserProfile}
        onOpenPreview={handleOpenPreview}
        topAchievement={topAchievement}
      />
    </div>
  );
});
