import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PLAYER_PROFILE_HEADER_INFO_GROUPS } from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './player-profile-header.module.less';

interface IPlayerProfileHeaderProps {
  isVisibleForMobile: boolean;
  isFollowedPlayer: boolean;
  isFollowFetching: boolean;
  isFollowUnfollowVisible: boolean;
  avatarUrl: string;
  fullName: string;
  onFollowPlayer?: () => void;
  onUnfollowPlayer?: () => void;
}

export const PlayerProfileHeader = memo((props: IPlayerProfileHeaderProps) => {
  const {
    isVisibleForMobile,
    isFollowFetching,
    isFollowedPlayer,
    isFollowUnfollowVisible,
    avatarUrl,
    fullName,
    onUnfollowPlayer,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const playerProfileHeaderClasses = useMemo(
    () =>
      cn(styles.PlayerProfileHeader, {
        [styles['PlayerProfileHeader--followed']]: isFollowedPlayer,
      }),
    [isFollowedPlayer],
  );

  const handleContextItemClick = useCallback(
    async (barAction: BarActionType) => {
      if (!onUnfollowPlayer) {
        return;
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'leave') {
        onUnfollowPlayer();
      }
    },
    [onUnfollowPlayer],
  );

  if (!isDesktopPlus && !isVisibleForMobile) {
    return null;
  }

  return (
    <div className={playerProfileHeaderClasses}>
      <Avatar src={avatarUrl} username={fullName} size={AvatarSize.S} />
      <span className={styles.PlayerProfileHeader__Name}>{fullName}</span>
      {isDesktopPlus && !isFollowedPlayer && isFollowUnfollowVisible && (
        <Button
          onClick={props.onFollowPlayer}
          theme={ButtonTheme.Primary}
          size={ButtonSize.Small}
          disabled={isFollowFetching}
        >
          Follow
        </Button>
      )}
      {isDesktopPlus && isFollowedPlayer && isFollowUnfollowVisible && (
        <ContextMenuTooltip
          groups={PLAYER_PROFILE_HEADER_INFO_GROUPS}
          onItemClick={handleContextItemClick}
          toggleOnClick
          tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
        >
          <IconButton iconName={IconFontName.More} theme={IconButtonTheme.Secondary} />
        </ContextMenuTooltip>
      )}
    </div>
  );
});
