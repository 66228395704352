import { memo } from 'react';
import { ACHIEVEMENTS_AMOUNT_BEFORE_PRE_LOAD } from 'query-hooks/reputation/contastants/entries.contstants';
import { IDaysStreak } from 'query-hooks/reputation/interfaces/days-streak.interface';
import { IUserAchievement } from 'query-hooks/reputation/interfaces/user-achievements.interface';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import { AchievementCard } from 'components/achievements/achievement-card/achievement-card.component';
import { DayStreakAchievementComponent } from 'components/achievements/achievement-card/day-streak-achievement.component';

import styles from './user-achievements.module.less';

interface IUserAchievementsProps {
  daysStreak: Maybe<IDaysStreak>;
  userAchievements: IUserAchievement[];
  hasMore: boolean;
  onLoadMore: () => void;
}

export const UserAchievements = memo((props: IUserAchievementsProps) => {
  const { daysStreak, userAchievements, hasMore, onLoadMore } = props;

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

  return (
    <div className={styles.UserAchievements}>
      {daysStreak && (
        <DayStreakAchievementComponent
          title="Day Streak"
          currentDaysProgress={daysStreak.current}
          maxDays={daysStreak.max}
        />
      )}
      {userAchievements.map((userAchievement, index: number) => (
        <div
          key={userAchievement.id}
          ref={
            userAchievements.length === index + ACHIEVEMENTS_AMOUNT_BEFORE_PRE_LOAD
              ? setIntersectionObserver
              : undefined
          }
        >
          <AchievementCard
            type={userAchievement.achievement.type}
            level={userAchievement?.achievementLevel?.level ?? null}
            description={`Earn ${
              userAchievement?.achievementLevel?.points ?? ''
            } points from player this season`}
            earnedPoints={userAchievement.totalPoints}
            totalPoints={userAchievement?.achievementLevel?.points ?? 0}
            badgeUrl={userAchievement.badgeSrc}
            status={userAchievement.status}
            label={userAchievement.achievement.title}
          />
        </div>
      ))}
    </div>
  );
});
