import { FC } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './biography.module.less';

export const BiographyContainer: FC = observer(() => {
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  if (!userPublicStore.userPublic?.biography) return null;

  return (
    <div className={styles.Biography}>
      <div className={styles.Biography__Header}>Biography</div>
      <div className={styles.Biography__Content}>
        <RichTextPreview
          id={userPublicStore.userPublic.id}
          editorType={RichTextContentTypes.FullHeight}
          editorState={userPublicStore.userPublic.biography}
        />
      </div>
    </div>
  );
});
