import { FC, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import {
  FollowAvatar,
  FollowAvatarSize,
} from 'components/ui/follow-avatar/follow-avatar.component';

import styles from './sidebar-following-item.module.less';

interface ISidebarFollowingItemProps {
  imageSrc: string;
  name: string;
  slug: string;
  imageSize: FollowAvatarSize;
  isSmall?: boolean;
  hasNewPosts: boolean;
  onClick: (id: string) => void;
}

export const SidebarFollowingItem: FC<ISidebarFollowingItemProps> = memo(
  (props: PropsWithChildren<ISidebarFollowingItemProps>) => {
    const { onClick, imageSrc, name, slug, hasNewPosts, imageSize, isSmall = false } = props;

    const handleJoinButtonClick = useCallback(
      (event: SyntheticEvent) => {
        event.stopPropagation();
        onClick(slug);
      },
      [onClick, slug],
    );

    const itemClassNames = useMemo(
      () =>
        cn(styles.Item, {
          [styles['Item--small']]: isSmall,
        }),
      [isSmall],
    );

    return (
      <div className={itemClassNames}>
        <button onClick={handleJoinButtonClick}>
          <FollowAvatar size={imageSize} altText={name} src={imageSrc} isActive={hasNewPosts} />
        </button>
        {!!name && !isSmall && <div className={styles.Item__Name}>{name}</div>}
      </div>
    );
  },
);
