import { useQuery } from '@tanstack/react-query';
import { athletesQueryKeys } from 'query-hooks/athletes/contastants/query-keys.constants';

import { AuthService } from 'services/auth/auth.service';

import { userProfileAthleteAdapter } from 'stores/auth/adapters/user-profile-athlete-adapter.util';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 30 * 1000; // 30 seconds

const retrieveGetAllAthletesQuery = async () => {
  const authService = container.get<AuthService>(TYPES.AuthService);

  const response = await authService.fetchDigitsAthletes();

  if (response.success) {
    return response.data.map(userProfileAthleteAdapter);
  }

  return [];
};

export const useGetAllAthletesQuery = () => {
  return useQuery({
    queryKey: athletesQueryKeys.all(),
    queryFn: retrieveGetAllAthletesQuery,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
