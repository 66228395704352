import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ArenaRoute } from 'routes/arena/arena.route';
import { EmptyArenaRoute } from 'routes/arena/empty-arena.route';
import { ContactUsRoute } from 'routes/contact-us/contact-us.route';
import { EditProfileRoute } from 'routes/edit-profile/edit-profile.route';
import { FollowingRoute } from 'routes/followings/followings.route';
import { HomeRoute } from 'routes/home/home.route';
import { HomeFeedFanZoneRoute } from 'routes/home/home-feed/fan-zone/home-feed-fan-zone.route';
import { FeedParentRoute } from 'routes/home/home-feed/feed-parent/feed-parent.route';
import { HeadlinesFeedRoute } from 'routes/home/home-feed/headlines-feed.route';
import { HomeFeedRoute } from 'routes/home/home-feed/home-feed.route';
import { PostDetailedRoute } from 'routes/home/home-feed/home-feed-post/home-feed-post.route';
import { CollaborationItemRoute } from 'routes/home/home-feed/home-feed-post-collaboration/home-feed-post-collaboration.route';
import { HomeScoresRoute } from 'routes/home/home-scores/home-scores.route';
import { HomeStandingsRoute } from 'routes/home/home-standings/home-standings.route';
import { HomeStatsRoute } from 'routes/home/home-stats/home-stats.route';
import { HomeGlobalStatsPlayersRoute } from 'routes/home/home-stats/home-stats-players/home-global-stats-players/home-global-stats-players.route';
import { HomeGlobalStatsTeamsRoute } from 'routes/home/home-stats/home-stats-teams/home-global-stats-teams/home-global-stats-teams.route';
import { LeaderboardRoute } from 'routes/leaderboard/leaderboard.route';
import { PersonalPlayerRoute } from 'routes/personal-player-route.component';
import { PersonalUserRoute } from 'routes/personal-user-route.component';
import { PlayerPerformanceRoute } from 'routes/player-performance/player-performance.route';
import { PlayerProfileRoute } from 'routes/player-profile/player-profile.route';
import { PlayerProfileFanZoneRoute } from 'routes/player-profile/player-profile-fanzone/player-profile-fanzone.route';
import { PlayerProfileFeedRoute } from 'routes/player-profile/player-profile-feed/player-profile-feed.route';
import { PlayerProfileGamesRoute } from 'routes/player-profile/player-profile-games/player-profile-games.route';
import { PlayerProfileOverviewRoute } from 'routes/player-profile/player-profile-overview/player-profile-overview.route';
import { PlayerProfileSplitsRoute } from 'routes/player-profile/player-profile-splits/player-profile-splits.route';
import { PlayerProfileStatsRoute } from 'routes/player-profile/player-profile-stats/player-profile-stats.route';
import { PlayerRankingsRoute } from 'routes/player-rankings/player-rankings.route';
import { PlayerRankingsDetailsRoute } from 'routes/player-rankings-details/player-rankings-details.route';
import { PrivacyPolicyRoute } from 'routes/privacy-policy/privacy-policy.route';
import { RootRoute } from 'routes/root/root.route';
import { SearchRoute } from 'routes/search/search.route';
import { SettingsRoute } from 'routes/settings/settings.route';
import { TeamRoute } from 'routes/team/team.route';
import { TeamFanZoneFeedRoute } from 'routes/team/team-fan-zone-feed/team-fan-zone-feed.route';
import { TeamFeedRoute } from 'routes/team/team-feed/team-feed.route';
import { TeamProfileRoute } from 'routes/team/team-profile/team-profile.route';
import { TeamRosterRoute } from 'routes/team/team-roster/team-roster.route';
import { TeamScoresRoute } from 'routes/team/team-scores/team-scores.route';
import { TeamStatsRoute } from 'routes/team/team-stats/team-stats.route';
import { TermsOfUseRoute } from 'routes/terms-of-use/terms-of-use.route';
import { UserAgreementRoute } from 'routes/user-agreement/user-agreement.route';
import { UserPublicRoute } from 'routes/user-public/user-public.route';
import { UserAchievementsRoute } from 'routes/user-public/user-public-achievements/user-public-achievements.route';
import { UserPublicBookmarksRoute } from 'routes/user-public/user-public-bookmarks/user-public-bookmarks.route';
import { UserPublicFeedRoute } from 'routes/user-public/user-public-feed/user-public-feed.route';
import { UserPublicPredictionsRoute } from 'routes/user-public/user-public-predictions/user-public-predictions.route';
import { UserPublicProfileRoute } from 'routes/user-public/user-public-profile/user-public-profile.route';
import { UserPublicStatRoute } from 'routes/user-public/user-public-stat/user-public-stat.route';

import { ErrorLayout } from 'components/errors/error-layout.component';

import * as paths from './paths.constants';

export const router = createBrowserRouter([
  {
    path: paths.ROOT,
    element: <RootRoute />,
    errorElement: <ErrorLayout />,
    children: [
      {
        index: true,
        element: <Navigate replace to={paths.HOME} />,
      },
      {
        path: paths.HOME,
        element: <HomeRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.HOME_FEED} />,
          },
          {
            path: paths.HOME_FEED,
            element: <FeedParentRoute />,
            children: [
              {
                index: true,
                element: <HomeFeedRoute />,
              },
              {
                path: paths.HOME_FEED_FAN_ZONE,
                element: <HomeFeedFanZoneRoute />,
              },
              {
                path: paths.HOME_FEED_POST,
                element: <PostDetailedRoute />,
              },
              {
                path: paths.HOME_FEED_POST_COLLABORATION,
                element: <CollaborationItemRoute />,
              },
            ],
          },
          {
            path: paths.HOME_HEADLINES,
            element: <FeedParentRoute />,
            children: [
              {
                index: true,
                element: <HeadlinesFeedRoute />,
              },
            ],
          },
          {
            path: paths.HOME_SCORES,
            element: <HomeScoresRoute />,
          },
          {
            path: paths.HOME_STATS,
            element: <HomeStatsRoute />,
            children: [
              {
                index: true,
                element: <Navigate replace to={paths.HOME_STATS_PLAYERS_ALL} />,
              },
              {
                path: paths.HOME_STATS_TEAMS_ALL,
                element: <HomeGlobalStatsTeamsRoute />,
              },
              {
                path: paths.HOME_STATS_PLAYERS_ALL,
                element: <HomeGlobalStatsPlayersRoute />,
              },
            ],
          },
          {
            path: paths.HOME_STANDINGS,
            element: <HomeStandingsRoute />,
          },
        ],
      },
      {
        path: paths.TEAM,
        element: <TeamRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.FEED_PART} />,
          },
          {
            path: paths.TEAM_FEED_FAN_ZONE,
            element: <TeamFanZoneFeedRoute />,
          },
          {
            path: paths.TEAM_FEED,
            element: <TeamFeedRoute />,
          },
          {
            path: paths.TEAM_SCORES,
            element: <TeamScoresRoute />,
          },
          {
            path: paths.TEAM_PROFILE,
            element: <TeamProfileRoute />,
          },
          {
            path: paths.TEAM_ROSTER,
            element: <TeamRosterRoute />,
          },
          {
            path: paths.TEAM_STATS,
            element: <TeamStatsRoute />,
          },
        ],
      },
      {
        path: paths.PLAYER_PROFILE,
        element: <PlayerProfileRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.FEED_PART} />,
          },
          {
            path: paths.PLAYER_PROFILE_FEED,

            element: <PlayerProfileFeedRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_GAMES,
            element: <PlayerProfileGamesRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_STATS,
            element: <PlayerProfileStatsRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_SPLITS,
            element: <PlayerProfileSplitsRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_OVERVIEW,
            element: <PlayerProfileOverviewRoute />,
          },
          {
            path: paths.PLAYER_PROFILE_FAN_ZONE,
            element: <PlayerProfileFanZoneRoute />,
          },
          {
            path: paths.PLAYER_PERFORMANCE,
            element: <PlayerPerformanceRoute />,
          },
        ],
      },
      {
        path: paths.PLAYER_EDIT_PROFILE,
        element: (
          <PersonalPlayerRoute>
            <EditProfileRoute />
          </PersonalPlayerRoute>
        ),
      },
      {
        path: paths.SETTINGS,
        element: <SettingsRoute />,
      },
      {
        path: paths.ARENA,
        element: <EmptyArenaRoute />,
      },
      {
        path: paths.ARENA_GAME,
        element: <ArenaRoute />,
      },
      {
        path: paths.PRIVACY_POLICY,
        element: <PrivacyPolicyRoute />,
      },
      {
        path: paths.TERMS_OF_USE,
        element: <TermsOfUseRoute />,
      },
      {
        path: paths.USER_AGREEMENT,
        element: <UserAgreementRoute />,
      },
      {
        path: paths.CONTACT_US,
        element: <ContactUsRoute />,
      },
      {
        path: paths.FOLLOWING,
        element: <FollowingRoute />,
      },
      {
        path: paths.SEARCH,
        element: <SearchRoute />,
      },
      {
        path: paths.PLAYERS_RANKINGS,
        element: <PlayerRankingsRoute />,
      },
      {
        path: paths.PLAYERS_RANKINGS_DETAILS,
        element: <PlayerRankingsDetailsRoute />,
      },
      {
        path: paths.LEADERBOARD,
        element: <LeaderboardRoute />,
      },
      {
        path: paths.USER_PUBLIC,
        element: <UserPublicRoute />,
        children: [
          {
            index: true,
            element: <Navigate replace to={paths.FEED_PART} />,
          },
          {
            path: paths.USER_PUBLIC_FEED,
            element: <UserPublicFeedRoute />,
          },
          {
            path: paths.USER_PUBLIC_STATS,

            element: <UserPublicStatRoute />,
          },
          {
            path: paths.USER_PUBLIC_PROFILE,
            element: <UserPublicProfileRoute />,
          },
          {
            path: paths.USER_PUBLIC_PREDICTIONS,
            element: <UserPublicPredictionsRoute />,
          },
          {
            path: paths.USER_PUBLIC_BOOKMARKS,
            element: (
              <PersonalUserRoute>
                <UserPublicBookmarksRoute />
              </PersonalUserRoute>
            ),
          },
          {
            path: paths.USER_PUBLIC_ACHIEVEMENTS,
            element: <UserAchievementsRoute />,
          },
        ],
      },
      {
        path: paths.USER_EDIT_PROFILE,
        element: (
          <PersonalUserRoute>
            <EditProfileRoute />
          </PersonalUserRoute>
        ),
      },
    ],
  },
]);
