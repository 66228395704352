import {
  AchievementLevel,
  AchievementStatus,
} from 'query-hooks/reputation/interfaces/user-achievements.interface';

import bronzeCheckbox from 'assets/images/svg/achievements/bronze-checkbox.svg';
import goldCheckbox from 'assets/images/svg/achievements/gold-checkbox.svg';
import silverCheckbox from 'assets/images/svg/achievements/silver-checkbox.svg';
import bronzeFrame from 'assets/images/svg/bronze-frame.svg';
import goldFrame from 'assets/images/svg/gold-frame.svg';
import silverFrame from 'assets/images/svg/silver-frame.svg';

export const getAchievementFrame = (level: AchievementLevel, status: AchievementStatus): string => {
  const badgeFrameMapper: Record<number, string> = {
    [AchievementLevel.Silver]: status === AchievementStatus.Completed ? silverFrame : bronzeFrame,
    [AchievementLevel.Gold]: status === AchievementStatus.Completed ? goldFrame : silverFrame,
    [AchievementLevel.Platinum]: status === AchievementStatus.Completed ? '' : goldFrame,
  };

  return badgeFrameMapper[level] ?? '';
};

export const getAchievementBadgeCheckmark = (
  level: AchievementLevel,
  status: AchievementStatus,
): string => {
  const badgeCheckboxMapper: Record<number, string> = {
    [AchievementLevel.Silver]:
      status === AchievementStatus.Completed ? silverCheckbox : bronzeCheckbox,
    [AchievementLevel.Gold]: status === AchievementStatus.Completed ? goldCheckbox : silverCheckbox,
    [AchievementLevel.Platinum]: status === AchievementStatus.Completed ? '' : goldCheckbox,
  };

  return badgeCheckboxMapper[level] ?? '';
};
