import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

export const UserPublicProfileContainer = observer(() => {
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;

  useEffect(() => {
    userPublicStore.setUserSlug(username || null);

    return () => {
      userPublicStore.reset();
    };
  }, [username, userPublicStore]);

  useEffect(() => {
    if (userPublicStore.userPublic?.athlete) {
      document.documentElement.style.setProperty(
        '--player-primary-color',
        userPublicStore.userPublic.athlete?.primaryColor,
      );
      document.documentElement.style.setProperty(
        '--player-text-color',
        userPublicStore.userPublic?.athlete?.secondaryColor,
      );
    }

    return () => {
      document.documentElement.style.removeProperty('--player-primary-color');
      document.documentElement.style.removeProperty('--player-text-color');
    };
  }, [userPublicStore.userPublic?.athlete]);

  useMobileLayoutStructure({
    isHiddenBottomBar: false,
    isHiddenHeader: false,
    isRemovedSafeAreaTop: false,
  });

  return null;
});
