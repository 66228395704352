import { format } from 'date-fns';

import { IPlayerDetailsResponse } from 'services/player/interfaces/player.interface';
import { IPlayerRankedStatsResponse } from 'services/player/interfaces/player-stats.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';
import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';

import { IPlayerDetails } from '../interfaces/player-profile.interfaces';

import { playerDetailsTeamAdapter } from './player-details-team-adapter.util';
import { playerRankedStatsAdapter } from './player-ranked-stats-adapter.util';

export function playerDetailsAdapter(
  playerDetailsResponse: IPlayerDetailsResponse,
  playerRankedStatsResponse: Maybe<IPlayerRankedStatsResponse>,
): IPlayerDetails {
  const {
    color,
    firstname,
    font_theme: fontTheme,
    jersey,
    lastname,
    medium_logo_url: mediumLogoUrl,
    small_logo_url: smallLogoUrl,
    id,
    slug,
    age,
    birth_date: birthDate,
    birth_place: birthPlace,
    college,
    draft_pick: draftPick,
    draft_round: draftRound,
    draft_year: draftYear,
    experience_years: experienceYears,
    height,
    height_ft: heightFt,
    rookie_year: rookieYear,
    seasons,
    team,
    weight,
    weight_lbs: weightLbs,
    tiktok_url: tiktokUrl,
    twitter_url: twitterUrl,
    instagram_url: instagramUrl,
    youtube_url: youtubeUrl,
    emoji_url: emojiUrl,
  } = playerDetailsResponse;

  const stats = playerRankedStatsAdapter(playerRankedStatsResponse);

  const birthPlaceArr = birthPlace ? birthPlace.split(',') : null;

  const nationality = birthPlaceArr ? birthPlaceArr[birthPlaceArr.length - 1] : null;

  return {
    fullName: `${firstname} ${lastname}`,
    emojiUrl,
    slug,
    color: convertColorToRGB(color),
    colorWithOpacity: convertColorToRGBA(color, 0.3),
    firstname,
    lastname,
    id,
    mediumLogoUrl,
    smallLogoUrl,
    fontColor: fontTheme === 'dark' ? PlayerFontColor.Dark : PlayerFontColor.Light,
    jersey,
    age,
    nationality,
    birthDateShort: birthDate ? format(new Date(birthDate), 'PP') : null,
    birthDateLong: birthDate ? format(new Date(birthDate), 'PPP') : null,
    birthPlace,
    college,
    draft: draftAdapter(draftYear, draftRound, draftPick),
    experienceYears,
    heightFt,
    heightMeters: height,
    rookieYear,
    weightKg: weight,
    weightLbs,
    instagramUrl,
    twitterUrl,
    youtubeUrl,
    tiktokUrl,
    team: team ? playerDetailsTeamAdapter(team) : null,
    seasons: seasons.map(({ season, team: seasonTeam }) => ({
      season: seasonAdapter(season),
      team: playerDetailsTeamAdapter(seasonTeam),
    })),
    stats,
  };
}

function draftAdapter(
  draftYear: Maybe<number>,
  draftRound: Maybe<number>,
  draftPick: Maybe<number>,
) {
  if (!draftYear) {
    return null;
  }

  const resultParts: string[] = [`${draftYear}`];

  if (draftRound) {
    resultParts.push(`R${draftRound}`);
  }

  if (draftPick) {
    resultParts.push(`Pick ${draftPick}`);
  }

  return resultParts.join(' ');
}
