import { useMemo } from 'react';
import { useGetAllFollowQuery } from 'query-hooks/followings/queries/use-get-all-follow.query';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';
import { useGetAllTeamsQuery } from 'query-hooks/teams/queries/use-get-all-teams.query';

import { followBuilder } from 'services/follow/adapters/follow-builder.util';

export const useGetFollowData = () => {
  const { data: follow = [] } = useGetAllFollowQuery();

  const { data: teams = [] } = useGetAllTeamsQuery();

  const { data: players = [] } = useGetAllPlayersQuery();

  return useMemo(() => {
    return followBuilder(follow, teams, players);
  }, [follow, teams, players]);
};
