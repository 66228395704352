import { FC } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { UserPublicLinks } from 'components/user-public-links/user-public-links';

import styles from './user-public-links.module.less';

export const UserPublicLinksContainer: FC = observer(() => {
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  if (!userPublicStore.userPublic?.customLinks?.length) {
    return null;
  }

  return (
    <div className={styles.CustomLinksContainer}>
      <UserPublicLinks
        transparent={Boolean(userPublicStore.userPublic?.athlete)}
        links={userPublicStore.userPublic.customLinks}
      />
    </div>
  );
});
