import { memo } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './edit-profile-header.module.less';

interface IEditProfileHeader {
  onSaveChangesClick: () => void;
  disabled: boolean;
}

export const EditProfileHeader = memo((props: IEditProfileHeader) => {
  const { disabled } = props;

  return (
    <div className={styles.EditProfileHeader}>
      <h1>Edit Profile</h1>
      <Button
        theme={ButtonTheme.Text}
        size={ButtonSize.Small}
        className={styles.EditProfileHeader__Button}
        onClick={props.onSaveChangesClick}
        disabled={disabled}
      >
        Save Changes
      </Button>
    </div>
  );
});
