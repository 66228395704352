import { memo } from 'react';
import { AchievementLevel } from 'query-hooks/reputation/interfaces/user-achievements.interface';

import { CircularProgressBar } from 'components/ui/circular-progress-bar/circular-progress-bar.component';

import bronzeProgressBar from 'assets/images/svg/achievements/bronze-progress-bar.svg';
import goldProgressBar from 'assets/images/svg/achievements/gold-progress-bar.svg';
import platinumProgressBar from 'assets/images/svg/achievements/platinum-progress-bar.svg';
import silverProgressBar from 'assets/images/svg/achievements/silver-progress-bar.svg';

import styles from './achievement-progress-bar.module.less';

export interface IAchievementProgressBarProps {
  fill: Maybe<number>;
  theme?: Maybe<AchievementLevel>;
  isCompleted: boolean;
}

export const AchievementProgressBar = memo((props: IAchievementProgressBarProps) => {
  const { fill, theme, isCompleted } = props;

  return (
    <div className={styles.AchievementProgressBar}>
      {theme === AchievementLevel.Bronze && isCompleted && (
        <img src={bronzeProgressBar} alt="bronze progress bar" />
      )}
      {theme === AchievementLevel.Silver && isCompleted && (
        <img src={silverProgressBar} alt="silver progress bar" />
      )}
      {theme === AchievementLevel.Gold && isCompleted && (
        <img src={goldProgressBar} alt="gold progress bar" />
      )}
      {theme === AchievementLevel.Platinum && isCompleted && (
        <img src={platinumProgressBar} alt="platinum progress bar" />
      )}
      {!isCompleted && fill && <CircularProgressBar fill={fill} />}
    </div>
  );
});
