import {
  IPublicLink,
  IPublicLinkResponse,
} from 'stores/auth/interfaces/user-public-link.interface';

export function publicLinkAdapterUtil(linksResponse: IPublicLinkResponse): IPublicLink {
  const { id, title, description, url, icon_src: iconSrc, type } = linksResponse;

  return {
    id,
    type,
    title,
    description,
    url,
    iconSrc,
  };
}
