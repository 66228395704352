import { IPlayerResponse } from 'services/player/interfaces/player.interface';

import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';

export function playersStatsAdapter(playersStatsResponse: IPlayerResponse[]): IPlayerStats[] {
  return playersStatsResponse.map((player) => ({
    fullName: `${player.firstname} ${player.lastname}`,
    slug: player.slug,
    color: convertColorToRGB(player.color),
    colorWithOpacity: convertColorToRGBA(player.color, 0.3),
    firstname: player.firstname,
    lastname: player.lastname,
    id: player.id,
    mediumLogoUrl: player.medium_logo_url,
    smallLogoUrl: player.small_logo_url,
    fontColor: player.font_theme === 'dark' ? PlayerFontColor.Dark : PlayerFontColor.Light,
    age: player.age,
    heightMeters: player.height,
    heightFt: player.height_ft,
    weightKg: player.weight,
    weightLbs: player.weight_lbs,
    jersey: player.jersey,
  }));
}
