import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { teamsQueryKeys } from 'query-hooks/teams/contastants/query-keys.constants';

import { TeamsStatsService } from 'services/team-stats/team-stats.service';

import { teamsStatsAdapter } from 'stores/teams-stats/adapters/teams-stats.adapter';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 60 * 60 * 2 * 1000; // 2h
const POLL_INTERVAL = 60 * 60 * 2 * 1000; // 2h

const retrieveGetAllTeamsQuery = async () => {
  const teamsStatsService = container.get<TeamsStatsService>(TYPES.TeamsStatsService);

  const response = await teamsStatsService.fetchStatsTeams();

  if (response.success) {
    return response.data ? teamsStatsAdapter(response.data) : [];
  }

  return [];
};

export const useGetAllTeamsQuery = () => {
  const queryResult = useQuery({
    queryKey: teamsQueryKeys.prefix,
    queryFn: retrieveGetAllTeamsQuery,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
    refetchInterval: POLL_INTERVAL,
    refetchIntervalInBackground: true,
  });

  const memoizedData = useMemo(() => {
    return queryResult.data || [];
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: memoizedData,
  };
};
