import { FC } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LinkType } from 'stores/auth/interfaces/user-public-link.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { getLinkValueByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';

import styles from './social-links.module.less';

export const SocialLinksContainer: FC = observer(() => {
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const socialLinks = userPublicStore.userPublic?.socialLinks || [];

  return (
    <div className={styles.SocialLinksContainer}>
      <div className={styles.SocialLinksContainer__Socials}>
        {Boolean(socialLinks.length) && (
          <ProfileInfoSocials
            instagramUrl={getLinkValueByKey(socialLinks, LinkType.Instagram)}
            twitterUrl={getLinkValueByKey(socialLinks, LinkType.Twitter)}
            youtubeUrl={getLinkValueByKey(socialLinks, LinkType.Youtube)}
            tiktokUrl={getLinkValueByKey(socialLinks, LinkType.TikTok)}
            snapchat={getLinkValueByKey(socialLinks, LinkType.Snapchat)}
            discord={getLinkValueByKey(socialLinks, LinkType.Discord)}
          />
        )}
      </div>
    </div>
  );
});
