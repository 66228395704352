import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPublicLink, LinkAction } from 'stores/auth/interfaces/user-public-link.interface';

import { IconButton, IconButtonTheme } from '../ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from '../ui/icon-font/icon-font.component';

import styles from './user-public-links.module.less';

interface IUserPublicLinksProps {
  links: IPublicLink[];
  grid?: boolean;
  transparent?: boolean;
  onEditClick?: (link: IPublicLink) => void;
}

export const UserPublicLinks: FC<IUserPublicLinksProps> = (props) => {
  const { links, grid = true, transparent = false, onEditClick } = props;

  const linksClassNames = useMemo(
    () =>
      cn(styles.UserPublicLinks, {
        [styles['UserPublicLinks--grid']]: grid,
      }),
    [grid],
  );

  const handleEditClick = useCallback(
    (event: SyntheticEvent, link: IPublicLink) => {
      event.preventDefault();

      onEditClick?.(link);
    },
    [onEditClick],
  );

  return (
    <div className={linksClassNames}>
      {links.map((item) => {
        return (
          <a
            href={item.url}
            key={item.id}
            className={cn(styles.LinkWrapper, {
              [styles['LinkWrapper--transparent']]: transparent,
              [styles['LinkWrapper--deleted']]: item.action === LinkAction.Delete,
            })}
          >
            <div className={styles.LinkWrapper__Icon}>
              {item.iconSrc && <img src={item.iconSrc} alt={item.title} />}
            </div>
            <div className={styles.LinkWrapper__Content}>
              <div className={styles.Title}>{item.title}</div>
              <div className={styles.Description}>{item.description}</div>
            </div>
            {onEditClick ? (
              <IconButton
                iconSize={IconFontSize.Big}
                iconName={IconFontName.Edit}
                theme={IconButtonTheme.Transparent}
                onClick={(event: SyntheticEvent) => handleEditClick(event, item)}
              />
            ) : (
              <div className={styles.LinkWrapper__Button} />
            )}
          </a>
        );
      })}
    </div>
  );
};
