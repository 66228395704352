import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IAttachments } from 'stores/posts/interfaces/post.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useIframeError } from 'hooks/use-iframe-error';

import {
  IframePreview,
  IframePreviewSize,
} from 'components/iframe-preview/iframe-preview.component';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import {
  AttachmentsImageItem,
  AttachmentsImageTheme,
} from 'components/ui/attachments-image-item/attachment-image-item.component';
import { IconBadge } from 'components/ui/icon-badge/icon-badge.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { ImagesCarouselPreview } from 'components/ui/images-carousel-preview/images-carousel-preview.component';
import { LinkSet } from 'components/ui/link-set/link-set.component';
import { MediaSet } from 'components/ui/media-set/media-set.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { Poll } from 'components/ui/poll/poll.component';
import { PollTheme } from 'components/ui/poll/poll-item/poll-item.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './attachments.module.less';

interface IAttachmentsProps {
  author: IAuthor;
  attachments: IAttachments;
  isSmall: boolean;
  date: string;
  parentEntityId: string;
  isForBookmarks: boolean;
  isMyPost?: boolean;
  entityId: string;
  team: Maybe<ITeamsStats>;
  hasShowMore?: boolean;
  athlete?: Maybe<IAthlete>;
  onOpenPreview?: OpenPreviewType;
  onPollVote?: (voteData: IPollVoteData) => void;
  onPollUpdate?: (pollData: IPollUpdateData) => void;
  onPollExpired?: (parentEntityId: string, entityId: Maybe<string>) => void;
  onOpenSource?: (event: SyntheticEvent) => void;
}

export const Attachments = memo((props: IAttachmentsProps) => {
  const {
    team,
    entityId,
    isSmall,
    attachments,
    author,
    date,
    parentEntityId,
    isForBookmarks,
    isMyPost,
    hasShowMore = false,
    athlete,
    onOpenPreview,
  } = props;

  const [iframeRef, hasIframeError] = useIframeError();

  const mediaAttachments = useMemo(() => {
    const localImages = attachments.images || [];
    const localVideos = attachments.videos || [];
    const localGifs = attachments.gifs || [];
    const localIframes = attachments.iframes || [];

    return [...localImages, ...localGifs, ...localVideos, ...localIframes];
  }, [attachments]);

  const pollOptionsAttachments = useMemo(() => {
    if (!attachments.poll) {
      return [];
    }

    return attachments.poll.options
      .filter((option) => option.attachmentImage !== null)
      .map((item) => item.attachmentImage!);
  }, [attachments]);

  const hasOtherAttachments = useMemo(() => {
    const allAttachments = [mediaAttachments, attachments.links?.length, attachments.poll].filter(
      Boolean,
    );

    return allAttachments.length >= 2;
  }, [mediaAttachments, attachments.links, attachments.poll]);

  const handleSingleImageClick = useCallback(
    (index: number) => () => {
      if (mediaAttachments.length) {
        onOpenPreview?.(author, mediaAttachments, date, index, team);
      }
    },
    [onOpenPreview, mediaAttachments, author, date, team],
  );

  const handlePollAttachmentClick = useCallback(
    (imageUuid: string) => {
      if (pollOptionsAttachments.length) {
        let id = 0;

        // Since not all poll options have attached imaged, find corresponding index by image uuid
        for (let i = 0; i < pollOptionsAttachments.length; i++) {
          if (pollOptionsAttachments[i].uuid === imageUuid) {
            id = i;
            break;
          }
        }

        onOpenPreview?.(author, pollOptionsAttachments, date, id, team);
      }
    },
    [onOpenPreview, pollOptionsAttachments, author, date, team],
  );

  const classImagesCarouselNames = useMemo(() => cn(styles.Section, TOUCH_IGNORE_CLASS), []);

  if (!(mediaAttachments.length || attachments.links?.length || attachments.poll)) {
    return null;
  }

  if (isSmall) {
    return (
      <div className={styles.SmallAttachments}>
        {!!mediaAttachments.length && (
          <div className={styles.SmallAttachments__Images}>
            {mediaAttachments.length < 3 && !hasOtherAttachments ? (
              mediaAttachments.map((attach, index) => (
                <div className={styles.ImageButton} key={attach.url}>
                  {attach.type === 'image' && (
                    <button
                      className={styles.ImageButton__BadgeWrapper}
                      onClick={handleSingleImageClick(index)}
                    >
                      <AttachmentsImageItem
                        theme={AttachmentsImageTheme.Attachments}
                        imageUrl={attach.mediumPreviewUrl}
                        mimeType={attach.mimeType}
                        alt={`Slide ${index}`}
                      />
                      {hasShowMore && (
                        <div className={styles.Badge}>
                          <IconBadge
                            iconName={IconFontName.MediaFiles}
                            iconSize={IconFontSize.Big}
                            content="See All Media"
                            onClick={props.onOpenSource}
                          />
                        </div>
                      )}
                    </button>
                  )}
                  {attach.type === 'gif' && (
                    <button onClick={handleSingleImageClick(index)}>
                      <SingleGif isNeedGifIcon size={GifSize.M} id={attach.url} />
                    </button>
                  )}
                  {attach.type === 'video' && (
                    <button onClick={handleSingleImageClick(index)}>
                      <div className={styles.SmallAttachments__VideoItem}>
                        <VideoPreview
                          size={VideoPreviewSize.M}
                          type={attach.type}
                          url={attach.url}
                        />
                      </div>
                    </button>
                  )}
                  {attach.type === 'iframe' && !hasIframeError && (
                    <button onClick={handleSingleImageClick(index)}>
                      <IframePreview ref={iframeRef} url={attach.url} size={IframePreviewSize.M} />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <MediaSet
                onClick={handleSingleImageClick}
                attachments={mediaAttachments}
                isLight={isForBookmarks}
                hasOtherAttachments={hasOtherAttachments}
              />
            )}
          </div>
        )}
        {!!attachments.links?.length && (
          <LinkSet
            attachments={attachments.links}
            hasOtherAttachments={hasOtherAttachments}
            isLight={isForBookmarks}
          />
        )}
        {attachments.poll && (
          <div className={styles.SmallAttachments__Poll}>
            <Poll
              theme={PollTheme.Comment}
              votesTotal={attachments.poll.votesTotal}
              answers={attachments.poll.options}
              answerId={attachments.poll.votedOptionId}
              pollId={attachments.poll.uuid}
              status={attachments.poll.status}
              quoteContent={attachments.poll.quoteContent}
              parentEntityId={parentEntityId}
              expirationTimestamp={attachments.poll.expirationTimestamp}
              onPollAnswer={props.onPollVote}
              onPollUpdate={props.onPollUpdate}
              onPollExpired={props.onPollExpired}
              entityId={entityId}
              isMyPoll={isMyPost}
              athlete={athlete}
              onPollAttachmentClick={handlePollAttachmentClick}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.BigAttachments}>
      {!!mediaAttachments.length && (
        <div className={classImagesCarouselNames}>
          <ImagesCarouselPreview
            author={author}
            date={date}
            slides={mediaAttachments}
            onImageClick={props.onOpenPreview}
            team={team}
          />
          {hasShowMore && (
            <div className={styles.Badge}>
              <IconBadge
                iconName={IconFontName.MediaFiles}
                iconSize={IconFontSize.Big}
                content="See All Media"
                onClick={props.onOpenSource}
              />
            </div>
          )}
        </div>
      )}
      {!!attachments.links?.length && (
        <LinkSet attachments={attachments.links} hasOtherAttachments={false} />
      )}
      {attachments.poll && (
        <div className={styles.Section}>
          <Poll
            votesTotal={attachments.poll.votesTotal}
            answers={attachments.poll.options}
            answerId={attachments.poll.votedOptionId}
            pollId={attachments.poll.uuid}
            status={attachments.poll.status}
            quoteContent={attachments.poll.quoteContent}
            parentEntityId={parentEntityId}
            expirationTimestamp={attachments.poll.expirationTimestamp}
            onPollAnswer={props.onPollVote}
            onPollUpdate={props.onPollUpdate}
            onPollExpired={props.onPollExpired}
            entityId={entityId}
            isMyPoll={isMyPost}
            athlete={athlete}
            onPollAttachmentClick={handlePollAttachmentClick}
          />
        </div>
      )}
    </div>
  );
});
