import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useGetFollowingPlayers } from 'query-hooks/followings/use-get-following-players';
import { useGetFollowingPlayersAbbr } from 'query-hooks/followings/use-get-following-players-abbr';
import { useGetFollowingTeams } from 'query-hooks/followings/use-get-following-teams';
import { useGetFollowingTeamsAbbr } from 'query-hooks/followings/use-get-following-teams-abbr';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { GameStore } from 'stores/game/game.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

export const useGaScreenCustomView = () => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const followingTeams = useGetFollowingTeams();
  const teamsFollowAbbrevs = useGetFollowingTeamsAbbr();
  const playersFollowAbbrevs = useGetFollowingPlayersAbbr();
  const followingPlayers = useGetFollowingPlayers();

  const googleAnalyticsHomeFeed = useCallback(
    (screenType: ScreenNamesEnum) => {
      const teamsFollow = teamsFollowAbbrevs.toString().slice(0, 100);
      const playersFollow = playersFollowAbbrevs.toString().slice(0, 100);

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: screenType,
          num_teams_follow: authStore.isAuthorised ? followingTeams.length : 'null',
          num_players_follow: authStore.isAuthorised ? followingPlayers.length : 'null',
          teams_follow: authStore.isAuthorised ? teamsFollow : 'null',
          players_follow: authStore.isAuthorised ? playersFollow : 'null',
        },
      });
    },
    [
      authStore.isAuthorised,
      followingPlayers.length,
      followingTeams.length,
      playersFollowAbbrevs,
      teamsFollowAbbrevs,
    ],
  );

  const googleAnalyticsTeamFeed = useCallback(
    (screenType: ScreenNamesEnum) => {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: screenType,
          team: teamsStatsStore.team?.name || '',
        },
      });
    },
    [teamsStatsStore.team],
  );

  const googleAnalyticsArenaFeed = useCallback(
    (screenType: ScreenNamesEnum) => {
      if (gameStore.entry) {
        const gameTimeStart = gameStore.entry.gameDateTimeStart;
        const gameHomeName = gameStore.entry.home.teamInfo.name;
        const gameVisitorName = gameStore.entry.visitors.teamInfo.name;

        GoogleAnalyticService.event({
          eventName: 'screen_custom_view',
          eventParams: {
            screen_type: screenType,
            game: `${gameHomeName} vs ${gameVisitorName}`,
            date: gameTimeStart,
            first_game: `${gameStore.isFirstGameInSameDay}`,
          },
        });
      }
    },
    [gameStore.entry, gameStore.isFirstGameInSameDay],
  );

  return {
    googleAnalyticsHomeFeed,
    googleAnalyticsTeamFeed,
    googleAnalyticsArenaFeed,
  };
};
